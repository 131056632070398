<div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="1em">
    <div>{{ 'scheduling.specific-days' | translate }}</div>
    <mat-slide-toggle [(ngModel)]="isMonday"> {{ 'scheduling.monday' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="isTuesday">{{ 'scheduling.tuesday' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="isWednesday">{{ 'scheduling.wednesday' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="isThursday">{{ 'scheduling.thursday' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="isFriday">{{ 'scheduling.friday' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="isSaturday">{{ 'scheduling.saturday' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [(ngModel)]="isSunday">{{ 'scheduling.sunday' | translate }}</mat-slide-toggle>
    <mat-form-field>
        <mat-label>{{ 'scheduling.time-of-day' | translate }}</mat-label>
        <input matInput type="time" placeholder="hh:mm" required pattern="^([0-1][0-9]|2[0-3]):([0-5][0-9])"
            [(ngModel)]="timeOfDay" (input)="validateInput()" class="timeOfDay" />
        <mat-hint>{{ 'scheduling.time-of-day-hint' | translate }}</mat-hint>
    </mat-form-field>
    <button class="updateSelected" mat-stroked-button type="button" (click)="updateSelected()" [disabled]="!isValid">
        {{ 'scheduling.update' | translate }}
    </button>
</div>
