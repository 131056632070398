import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromAccount from 'src/app/modules/console/features/account/account';
import * as fromProfile from 'src/app/shared/custom/features/profile/profile';
import * as fromOrganisation from 'src/app/modules/console/features/organisation/organisation';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { RoleState, OrganisationRole } from 'src/app/models/organisation';
import { filter, take, map, takeUntil, mergeMap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDialogComponent
} from 'src/app/shared/custom/components/confirmation-dialog/confirmation-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'safe-user-role-management',
  templateUrl: './user-role-management.component.html',
  styleUrls: ['./user-role-management.component.scss']
})
export class UserRoleManagementComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  public roleStates$: Observable<RoleState[]>;
  public enabledRoleStates$: Observable<RoleState[]>;
  public isGettingOrganisationUser$: Observable<boolean>;
  public hasAdminAccess$: Observable<boolean>;
  public userIsMe$: Observable<boolean>;

  public updateRolesFormGroup: UntypedFormGroup = this.formBuilder.group({
    appUser: [false],
    operations: [false],
    userManager: [false],
    support: [false],
    supportManager: [false]
  });

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    const userRoles$ = this.store.pipe(select(fromProfile.roles));
    const availableRoles = [
      OrganisationRole.userManager,
      OrganisationRole.operations,
      OrganisationRole.appUser,
      OrganisationRole.supportManager,
      OrganisationRole.support
    ];
    const myAccountId$ = this.store.pipe(select(fromAccount.selectAccountId), filter(x => !!x));
    this.userIsMe$ = myAccountId$.pipe(
      mergeMap(myAccountId => {
        return this.store.pipe(
          select(fromProfile.accountId),
          map(accountId => {
            return accountId === myAccountId;
          }));
      }));
    this.isGettingOrganisationUser$ = this.store.pipe(select(fromProfile.isGettingOrganisationUser));
    this.store.pipe(select(fromProfile.isSettingUserRole),
      takeUntil(this.destroy)).subscribe(working =>
        working ? this.spinnerService.show() : this.spinnerService.hide());
    this.roleStates$ = combineLatest([userRoles$, this.userIsMe$]).pipe(
      map(([userRoles, userIsMe]) => {
        const roleStates = availableRoles.map(role => {
          const enabled = userRoles.includes(role);
          const roleName = `roles.${role}`;
          const cannotRemoveMyOwnSupportManagerRole =  userIsMe && enabled && role === OrganisationRole.supportManager;
          const cannotRemoveMyOwnUserManagerRole =  userIsMe && enabled && role === OrganisationRole.userManager;
          const canUpdate = !cannotRemoveMyOwnSupportManagerRole && !cannotRemoveMyOwnUserManagerRole;
          this.updateRolesFormGroup.controls[role].setValue(enabled);
          return ({ role, roleName, enabled, canUpdate });
        });
        return roleStates;
      }
    ));

    this.enabledRoleStates$ = this.roleStates$.pipe(map(states => states.filter(s => s.enabled)));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  toggleClicked(role: string, canUpdate: boolean) {
    if (!canUpdate) {
      return;
    }
    const enabled = !this.updateRolesFormGroup.controls[role].value;
    const roleName$ = this.translate.get(`roles.${role}`);
    const operation = enabled ? 'roles.confirm.add' : 'roles.confirm.remove';
    const displayName$ = this.store.pipe(select(fromProfile.displayName), filter(x => !!x));
    const message$: Observable<string> =
      roleName$.pipe(mergeMap(roleName =>
        displayName$.pipe(mergeMap(displayName =>
          this.translate.get(operation, { role: roleName, user: displayName })
        ))
      ));
    message$.pipe(take(1)).subscribe(data => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });
      dialogRef.afterClosed().pipe(filter(x => x), mergeMap(_ => this.route.params.pipe(map(p => p.membershipId))),
        take(1)).subscribe(membershipId => {
          return this.store.dispatch(fromProfile.setUserRoleRequest({ membershipId, role, enabled }));
        });
    });
    return false;
  }
}
