import { Component, OnDestroy, OnInit, Input, } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Size } from 'ngx-spinner/lib/ngx-spinner.enum';

@Component({
  selector: 'safe-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() color = '#512da8';
  @Input() size: Size = 'medium';
  @Input() type = 'line-scale';
  @Input() fullScreen = false;
  @Input() bdColor = 'rgba(0,0,0,0)';
  @Input() name: string = null;
  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    const options = {
      bdColor: this.bdColor,
      color: this.color,
      size: this.size,
      type: this.type,
      fullScreen: this.fullScreen
    };
    this.spinner.show(this.name || undefined, options);
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
