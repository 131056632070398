import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromApp from './app.reducer';
import { flagCssValues } from 'src/app/constants/internationalisation';
import { TenantState } from 'src/app/shared/custom/models/domains';

export const selectAppState = createFeatureSelector<fromApp.State>(fromApp.appFeatureKey);
export const selectCurrentLocale = createSelector(selectAppState, state => state.locale);
export const selectFlagCss = createSelector(selectAppState, state => flagCssValues[state.locale]);
export const selectLanguages = createSelector(selectAppState, state => state.languages);
export const selectHasLocalTenantDomains = createSelector(selectAppState, state => state.localTenantDomainsState === 'ok');
export const selectLocalTenantDomains = createSelector(selectAppState, state => state.localTenantDomains);
const selectLocalTenantDomainsState = createSelector(selectAppState, state => state.localTenantDomainsState);
const selectUrlHost = createSelector(selectAppState, state => state.urlHost);
export const selectTenantState = createSelector(
  selectLocalTenantDomains, 
  selectLocalTenantDomainsState,
  selectUrlHost,
  (localTenantDomains, localTenantDomainsState, urlHost) => {
    const localTenantDomain = localTenantDomains.find(d => d.consoleDomain === urlHost) || null;
    const cloudTenantId = localTenantDomain?.cloudTenantId || null;
    const loaded = localTenantDomainsState === 'ok';
    return <TenantState>{ localTenantDomains, localTenantDomain, cloudTenantId, urlHost, loaded };
  }
);
export const selectVersionTimestamp = createSelector(selectAppState, state => state.versionTimestamp);
export const selectVersions = createSelector(selectAppState, state => state.versions);
export const selectLatestVersion = createSelector(selectAppState, 
  state => state.versions.reduce((acc, v) => v.versionNumber > (acc?.versionNumber ?? 0) ? v : acc, null)
);
export const selectIsGettingVersions = createSelector(selectAppState, state => state.getVersionsState === 'pending');
