import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TenantState } from '../models/domains';
import * as fromApp from 'src/app/features/app/app';
import { filter, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantConfigService {
  private _tenantState: TenantState | null = null;

  constructor(
    private store: Store,
  ) { }

  public get tenantState() { return this._tenantState; }

  initialise = async () => {
    this.store.dispatch(fromApp.getLocalTenantDomainsRequest());
    this.store.dispatch(fromApp.getUrlHostRequest());
    this._tenantState = await firstValueFrom(this.store.pipe(select(fromApp.selectTenantState), filter(x => x.loaded)));
  }
}
