import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { Domain } from 'src/app/shared/custom/models/domains';
import { Firestore } from '@angular/fire/firestore';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';


@Injectable({
  providedIn: 'root'
})
export class DomainsService {
  private blacklist: Observable<string[]>;

  constructor(
    private firestore: Firestore,
    private firestoreService: FirestoreService,
  ) { }

  public domainIsTaken(domains: string[]): Observable<boolean | string> {
    const { query, where, collectionGroup, collectionData } = this.firestoreService;
    if (domains.length === 0) {
      return of(null);
    }
    function chunkArray<T>(array: T[], size): T[][] {
      if (array.length <= size) {
          return [array];
      }
      return [array.slice(0, size), ...chunkArray(array.slice(size), size)];
    }

    const chunkedDomains = chunkArray(domains, 10);
    const chunkResults = chunkedDomains.map(chunk => {
      const matchingDomains$ = collectionData(query(
        collectionGroup(this.firestore, 'internalDomains'),
        where('domain', 'in', chunk))
      );
      return matchingDomains$.pipe(map(x => {
        if (x.length === 0) {
          return false;
        }
        return x[0].get('domain').toString();
      }), take(1));
    });
    return forkJoin(chunkResults).pipe(map(results => results.find(x => typeof x === 'string') || false), take(1));
  }

  public getOrganisationDomains(organisationId: string): Observable<Domain[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const internalDomains$ = collectionData(query(
      collection(this.firestore, `organisations/${organisationId}/internalDomains`))).pipe(
        map(domains => domains.map(x => ({ id: x.id, domain: x.domain }))));
    const externalDomains$ = collectionData(query(
      collection(this.firestore, `organisations/${organisationId}/externalDomains`))).pipe(
        map(domains => domains.map(x => ({ id: x.id, domain: x.domain }))));
    const domains$ = internalDomains$.pipe(mergeMap(id => externalDomains$.pipe(map(ed => [...id, ...ed].sort()))));
    return domains$;
  }
}
