import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, switchMap, mergeMap, distinct } from 'rxjs/operators';
import { of } from 'rxjs';
import * as UsersActions from './users.actions';
import { OrganisationService } from '../../service/organisation.service';
import { DomainsService } from '../../service/domains.service';
import { UsersService } from 'src/app/shared/custom/service/users.service';
import { OrganisationUserCreation } from 'src/app/models/organisation';

@Injectable()
export class UsersEffects {

  createOrganisationUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.createOrganisationUserRequest),
      exhaustMap(command => this.usersService.createOrganisationUser(command).pipe(
        map(creation => {
          const x: OrganisationUserCreation = creation;
          return UsersActions.createOrganisationUserSuccess(x);
        }),
        catchError(error => of(UsersActions.createOrganisationUserFailure({ error })))
      ))
    );
  });

  getOrganisationDomains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getOrganisationDomainsRequest),
      exhaustMap(x => this.domainsService.getOrganisationDomains(x.organisationId).pipe(
        map(domains => UsersActions.getOrganisationDomainsResult({ domains })),
        catchError(error => of(UsersActions.getOrganisationDomainsFailure({ error })))
      ))
    );
  });

  getOrganisationUserCount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getOrganisationUserCountRequest),
      exhaustMap(x => this.usersService.getOrganisationUserCount(x.organisationId).pipe(
        map(userCount => UsersActions.getOrganisationUserCountResult({ userCount })),
        catchError(error => of(UsersActions.getOrganisationUserCountFailure({ error })))
      ))
    );
  });

  archiveMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.archiveMemberRequest),
      mergeMap(member => this.usersService.archiveOrganisationMembership(member.organisationId, member.organisationMembershipId).pipe(
        map(() => UsersActions.archiveMemberSuccess()),
        catchError(error => of(UsersActions.archiveMemberFailure({ error })))
      ))
    );
  });

  suspendMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.suspendMemberRequest),
      mergeMap(member => this.usersService.suspendOrganisationMembership(member.organisationId, member.organisationMembershipId).pipe(
        map(() => UsersActions.suspendMemberSuccess()),
        catchError(error => of(UsersActions.suspendMemberFailure({ error })))
      ))
    );
  });

  resumeMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.resumeMemberRequest),
      mergeMap(member => this.usersService.resumeOrganisationMembership(member.organisationId, member.organisationMembershipId).pipe(
        map(() => UsersActions.resumeMemberSuccess()),
        catchError(error => of(UsersActions.resumeMemberFailure({ error })))
      ))
    );
  });

  getMember$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.getMemberRequest),
      distinct(r => r.organisationMembershipId),
      mergeMap(x => this.usersService.getOrganisationUser(x.organisationMembershipId).pipe(
        map(member => UsersActions.getMemberResponse({ member })),
        catchError(error => of(UsersActions.getMemberFailure({ error }))
        ))
      ));
  });

  constructor(
    private usersService: UsersService,
    private domainsService: DomainsService,
    private organisationService: OrganisationService,
    private actions$: Actions
  ) { }
}
