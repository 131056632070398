import { Component, Input } from '@angular/core';

@Component({
  selector: 'safe-boolean-cell',
  templateUrl: './boolean-cell.component.html',
  styleUrls: ['./boolean-cell.component.scss'],
})
export class BooleanCellComponent {
  @Input() value: boolean = false;
}
