import * as root from 'src/app/reducers';
import { State  } from './account.reducer';

export * from './account.actions';
export * from './account.effects';
export * from './account.reducer';
export * from './account.selectors';

export interface AccountState extends root.AppState {
  account: State;
}
