import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SystemHealthService } from 'src/app/service/system-health.service';
import * as SystemHealthActions from './system-health.actions';

@Injectable()
export class SystemHealthEffects {

  loadSystemHealth$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.getSystemHealthRequest),
      switchMap(req => this.systemHealthService.getSystemHealthByDay(req.iso8601Date).pipe(
        map(systemHealth => SystemHealthActions.getSystemHealthSuccess({ systemHealth })),
        catchError(error => of(SystemHealthActions.getSystemHealthFailure({ error }))))
      )
    );
  });

  listCommands$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.listCommandsRequest),
      switchMap(_ => this.systemHealthService.listSystemHealthCommands().pipe(
        map(commands => SystemHealthActions.listCommandsSuccess({ commands })),
        catchError(error => of(SystemHealthActions.listCommandsFailure({ error }))))
      )
    );
  });

  listRepairScripts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.listRepairScriptsRequest),
      switchMap(_ => this.systemHealthService.listSystemHealthRepairScripts().pipe(
        map(repairScripts => {
          return SystemHealthActions.listRepairScriptsSuccess({ repairScripts });
        }),
        catchError(error => of(SystemHealthActions.listRepairScriptsFailure({ error }))))
      )
    );
  });

  listSchedules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.listSchedulesRequest),
      switchMap(_ => this.systemHealthService.listSchedules().pipe(
        map(schedules => SystemHealthActions.listSchedulesSuccess({ schedules })),
        catchError(error => of(SystemHealthActions.listSchedulesFailure({ error }))))
      )
    );
  });

  executeCommand$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.executeCommandRequest),
      mergeMap(req => this.systemHealthService.executeSystemHealthCommand(req.command).pipe(
        map(_ => SystemHealthActions.executeCommandSuccess()),
        catchError(error => of(SystemHealthActions.executeCommandFailure({ error }))))
      )
    );
  });

  executeRepairScript$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.executeRepairScriptRequest),
      mergeMap(req => this.systemHealthService.executeSystemHealthRepairScript(req.repairScript).pipe(
        map(_ => SystemHealthActions.executeRepairScriptSuccess()),
        catchError(error => of(SystemHealthActions.executeRepairScriptFailure({ error }))))
      )
    );
  });

  createSchedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.createScheduleRequest),
      mergeMap(req => this.systemHealthService.createSystemHealthSchedule(req.schedule).pipe(
        map(_ => SystemHealthActions.createScheduleSuccess()),
        catchError(error => of(SystemHealthActions.createScheduleFailure({ error }))))
      )
    );
  });

  updateSchedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.updateScheduleRequest),
      mergeMap(req => this.systemHealthService.updateSystemHealthSchedule(req.schedule).pipe(
        map(_ => SystemHealthActions.updateScheduleSuccess()),
        catchError(error => of(SystemHealthActions.updateScheduleFailure({ error }))))
      )
    );
  });

  deleteSchedule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SystemHealthActions.deleteScheduleRequest),
      mergeMap(req => this.systemHealthService.deleteSystemHealthSchedule(req.scheduleId).pipe(
        map(_ => SystemHealthActions.deleteScheduleSuccess({ scheduleId: req.scheduleId })),
        catchError(error => of(SystemHealthActions.deleteScheduleFailure({ error, scheduleId: req.scheduleId }))))
      )
    );
  });

  constructor(
    private actions$: Actions,
    private systemHealthService: SystemHealthService
  ) { }

}
