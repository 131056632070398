export const environment = {
  production: false,
  useEmulator: false,
  tenantRedirect: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDdPqqRJHi6X_-aLWuKhdCF66JmICW9zEo',
    authDomain: 'dev.safe-auth.seakglobal.com',
    databaseURL: 'https://safe-global-dev.firebaseio.com',
    projectId: 'safe-global-dev',
    storageBucket: 'safe-global-dev.appspot.com',
    messagingSenderId: '139825779754',
    appId: '1:139825779754:web:b4069998246a546410df53',
    measurementId: 'G-9K73RF137K'
  },
  domainBlacklistUrl: 'https://seakglobalpublic.blob.core.windows.net/domain-blacklist/domain-blacklist.txt',
  appEngineUrl: 'https://safe-global-dev.appspot.com/',
  dashboardTitle: 'header.title.safe-console-dev',
  snackBarDuration: { duration: 6000 },
  defaultLocale: 'en-gb',
  locales: ['en-gb', 'cy'],
  consoleUrl: 'https://dev.safe-console.seakglobal.com/',
  portalRoot: 'safe-business.seakglobal.com',
  consoleRoot: 'safe-console.seakglobal.com',
  consoleDomain: 'dev.safe-console.seakglobal.com',
  version: require('../../package.json').version,
};
