import { AuthGuard, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { ConnectComponent } from './components/connect/connect.component';
import { SuspendedComponent } from './components/suspended/suspended.component';
import { redirectPromiseGuard } from './service/redirect-promise.guard';

const redirectAuthorised = () => redirectLoggedInTo(['/console']);

const routes: Routes = [
  {
    path: 'connect',
    component: ConnectComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectAuthorised }
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'suspended',
    component: SuspendedComponent
  },
  {
    path: 'console',
    loadChildren: () => import('./modules/console/console.module').then(m => m.ConsoleModule),
    canActivate: [redirectPromiseGuard],
  },
  {
    path: '**',
    redirectTo: '/console'
  },
];

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always'
};

export const APP_ROUTING_MODULE = RouterModule.forRoot(routes, routingConfiguration);
