import {
  Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef, ViewChild, Input, AfterViewInit
} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  take, catchError, finalize, takeUntil, filter, map, tap, distinctUntilChanged, delay, startWith
} from 'rxjs/operators';
import * as fromUsers from 'src/app/modules/console/features/users/users';
import { Store, select } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as utilities from 'src/app/utilities';
import { UntypedFormBuilder } from '@angular/forms';
import { OrganisationUser } from 'src/app/models/accounts';
import { OrganisationUsersRequest, UsersService } from '../../service/users.service';
import { ElasticSearchPage } from 'src/app/models/page-result';
import { OrderFields } from '../../models/search';

export class UsersDataSource extends MatTableDataSource<OrganisationUser> {
  private usersSubject$ = new BehaviorSubject<any[]>([]);
  private loadingSubject$ = new BehaviorSubject<boolean>(false);
  public initialLoad$: Observable<boolean>;
  public loading$: Observable<boolean>;

  constructor(
    private usersRetriever: (request: OrganisationUsersRequest) => Observable<ElasticSearchPage>,
    private totalCount$: Subject<number>,
    loaded: () => void,
    request$: Observable<OrganisationUsersRequest>
  ) {
    super();
    request$.pipe(filter(x => !!x.organisationId)).subscribe(this.loadUsers.bind(this));
    this.loading$ = this.loadingSubject$.asObservable().pipe(distinctUntilChanged(), tap(loaded));
    this.initialLoad$ = this.loading$.pipe(take(3));
  }

  connect() {
    return this.usersSubject$;
  }

  disconnect(): void {
    this.usersSubject$.complete();
    this.loadingSubject$.complete();
  }

  private loadUsers(request: OrganisationUsersRequest) {
    this.loadingSubject$.next(true);
    this.usersRetriever(request).pipe(
      take(1),
      catchError(() => of(ElasticSearchPage.empty())),
      finalize(() => {
        this.loadingSubject$.next(false);
      })
    ).subscribe(page => {
      this.totalCount$.next(page.total.value);
      const users = page.hits;
      this.usersSubject$.next(users);
    });
  }
}

const orderFields = {
  name: 'nameOrder',
  email: 'emailOrder',
};

@Component({
  selector: 'safe-organisation-users-table',
  templateUrl: './organisation-users-table.component.html',
  styleUrls: ['./organisation-users-table.component.scss']
})
export class OrganisationUsersTableComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy = new Subject<void>();
  private request: OrganisationUsersRequest = {
    organisationId: '',
    order: [],
    searchTerm: '',
    skip: 0,
    take: 25
  };
  private request$ = new BehaviorSubject<OrganisationUsersRequest>(this.request);
  public totalCount$ = new BehaviorSubject<number>(0);
  public emptyTable$: Observable<string[]>;
  public hideIfPopulated$: Observable<string>;
  public displayedColumns: string[] = ['name', 'phoneNumber', 'email', 'roles'];
  public usersDataSource: UsersDataSource;
  public pageSize$: Observable<number>;
  public searchFormGroup = this.formBuilder.group({
    searchTerm: [''],
  });

  @Input() organisationId: string;
  @Input() userFilter: 'active' | 'suspended' | 'all' = 'all';
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private store: Store,
    private usersService: UsersService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit() {
    this.pageSize$ = this.request$.pipe(map(x => x.take));
    const userRetriever = {
      active: (request: OrganisationUsersRequest) => this.usersService.activeOrganisationUsers(request),
      suspended: (request: OrganisationUsersRequest) => this.usersService.suspendedOrganisationUsers(request),
      all: (request: OrganisationUsersRequest) => this.usersService.allOrganisationUsers(request)
    }[this.userFilter];
    this.usersDataSource = new UsersDataSource(
      userRetriever,
      this.totalCount$,
      () => this.ngZone.run(() => this.cdr.markForCheck()),
      this.request$.pipe(takeUntil(this.destroy)));
    this.emptyTable$ = this.totalCount$.pipe(map(n => n === 0 ? ['emptyTable'] : []));
    this.hideIfPopulated$ = this.totalCount$.pipe(map(n => n === 0 ? null : 'hide'));
  }

  ngAfterViewInit(): void {
    this.usersDataSource.sort = this.sort;
    const organisationUserCount$ = this.store.pipe(
      select(fromUsers.selectOrganisationUserCount), filter(x => !!x || x === 0), distinctUntilChanged(),
    );
    if (!this.sort) { return; }
    const sortChange$ = this.sort.sortChange.asObservable().pipe(
      startWith({ active: this.sort.active, direction: this.sort.direction }),
      distinctUntilChanged((prev, current) => {
        return prev.active === current.active && prev.direction === current.direction;
      })
    );
    const organisationId = this.organisationId;
    const state$ = combineLatest([sortChange$, organisationUserCount$]).pipe(delay(0));
    utilities.subscribe(state$, this.destroy, ([sort]) => {
      const order: OrderFields[] = sort.direction
        ? [{ [orderFields[sort.active]]: { order: sort.direction } }]
        : [{ nameOrder: { order: 'asc' } }];
      this.request = { ...this.request, organisationId, order };
      this.request$.next(this.request);
    });
    utilities.subscribe(this.usersDataSource.loading$, this.destroy, loading => {
      if (loading) {
        this.searchFormGroup.controls.searchTerm.disable();
      } else {
        this.searchFormGroup.controls.searchTerm.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.request$.complete();
    this.totalCount$.complete();
  }

  onPage(event: PageEvent) {
    this.request = {
      ...this.request,
      skip: event.pageIndex * event.pageSize,
      take: event.pageSize
    };
    this.request$.next(this.request);
  }

  public profileLink(membershipId: string) {
    [membershipId, 'profile'];
  }

  async profileLinkClicked(membershipId: string) {
    await this.ngZone.run(() =>
      this.router.navigate([membershipId, 'profile'], { relativeTo: this.route })
    );
  }

  public searchTermChanged(event: any) {
    if (!event.target.value) {
      this.runSearch();
    }
  }

  public runSearch() {
    this.request = {
      ...this.request,
      skip: 0,
      searchTerm: this.searchFormGroup.controls.searchTerm.value,
    };
    this.request$.next(this.request);
  }
}
