import { Component, OnInit, OnDestroy, NgZone, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { take, catchError, finalize, takeUntil, map, tap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Page } from 'src/app/models/page-result';
import { MatTable } from '@angular/material/table';
import { Organisation } from 'src/app/models/organisation';
import * as utilities from 'src/app/utilities';
import * as fromApp from 'src/app/features/app/app';
import * as fromAccount from 'src/app/modules/console/features/account/account';
import { select, Store } from '@ngrx/store';
import { PasswordResetEmail } from 'src/app/models/accounts';
import { AccountService, PasswordResetEmailsRequest } from 'src/app/modules/console/service/account.service';
import { environment } from 'src/environments/environment';

export class EmailsDataSource implements DataSource<PasswordResetEmail> {
  private emailsSubject$ = new BehaviorSubject<PasswordResetEmail[]>([]);
  private loadingSubject$ = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean>;

  constructor(
    private accountService: AccountService,
    loaded: () => void,
    private totalCount$: Subject<number>,
    destroy$: Observable<void>,
    request$: Observable<PasswordResetEmailsRequest>
  ) {
    request$.pipe(takeUntil(destroy$)).subscribe(this.loadEmails.bind(this));
    this.loading$ = this.loadingSubject$.asObservable().pipe(tap(loaded));
  }

  connect(_: CollectionViewer): Observable<PasswordResetEmail[]> {
    return this.emailsSubject$.asObservable();
  }

  disconnect(_: CollectionViewer): void { }

  complete() {
    this.emailsSubject$.complete();
    this.loadingSubject$.complete();
  }

  private loadEmails(request: PasswordResetEmailsRequest) {
    this.loadingSubject$.next(true);
    this.accountService.passwordResetEmails(request).pipe(
      take(1),
      catchError(() => of(Page.empty<PasswordResetEmail>())),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(page => {
      this.emailsSubject$.next(page.page);
      this.totalCount$.next(page.totalCount);
    });
  }
}

@Component({
  selector: 'safe-password-reset-emails-table',
  templateUrl: './password-reset-emails-table.component.html',
  styleUrls: ['./password-reset-emails-table.component.scss']
})
export class PasswordResetEmailsTableComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  @Input() accountId: string;
  private request: PasswordResetEmailsRequest = {
    accountId: '',
    orderBy: 'sentUtc',
    direction: 'desc',
    skip: 0,
    take: 10,
  };
  private request$ = new BehaviorSubject<PasswordResetEmailsRequest>(null);
  public totalCount$ = new BehaviorSubject<number>(0);
  public displayedColumns: string[] = ['sent', 'statusCode', 'response'];
  public emailsDataSource: EmailsDataSource;
  public pageSize$: Observable<number>;
  public currentLocale = environment.defaultLocale;

  @ViewChild(MatTable) table: MatTable<Organisation>;

  constructor(
    private accountService: AccountService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private store: Store,
  ) { }

  ngOnInit() {
    this.pageSize$ = this.request$.pipe(map(x => x.take));
    const passwordResetEmailCount$ = this.store.pipe(select(fromAccount.getPasswordResetEmailCount(this.accountId)), tap(() => {
      this.ngZone.run(() => this.cdr.markForCheck());
    }));
    this.request = { ...this.request, accountId: this.accountId };
    this.request$.next(this.request);
    this.emailsDataSource = new EmailsDataSource(
      this.accountService,
      () => this.ngZone.run(() => this.cdr.markForCheck()),
      this.totalCount$,
      this.destroy,
      this.request$.pipe(takeUntil(this.destroy)));
    const currentLocale$ = this.store.pipe(select(fromApp.selectCurrentLocale));
    utilities.subscribe(currentLocale$, this.destroy, locale => this.currentLocale = locale);
    utilities.subscribe(passwordResetEmailCount$, this.destroy, _ => this.request$.next(this.request));
    this.store.dispatch(fromAccount.getAccountByIdRequest({ accountId: this.accountId }));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.request$.complete();
    this.totalCount$.complete();
    this.emailsDataSource.complete();
  }

  onPage(event: PageEvent) {
    this.request = {
      ...this.request,
      skip: event.pageIndex * event.pageSize,
      take: event.pageSize
    };
    this.request$.next(this.request);
  }
}
