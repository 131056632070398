import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WebAppDomains } from 'src/app/models/system';
import { environment } from 'src/environments/environment';
import { customInputComponentMixinBase } from '../custom-input-component';

@Component({
  selector: 'safe-subdomain-input',
  templateUrl: './subdomain-input.component.html',
  styleUrls: ['./subdomain-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SubdomainInputComponent }],
})
export class SubdomainInputComponent
extends customInputComponentMixinBase
implements OnInit, OnDestroy, DoCheck,
  ControlValueAccessor, MatFormFieldControl<WebAppDomains> {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() { return this._required; }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): WebAppDomains | null { return this.constructedDomains; }
  set value(domains: WebAppDomains | null) {
    this.constructedDomains = domains;
    this.stateChanges.next();
  }

  @Input()
  get placeholder() { return this._placeholder; }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get empty() {
    return !(this.path) || !(this.path.trim());
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !!this.path;
  }

  static nextId = 0;
  @Input() errorStateMatcher: ErrorStateMatcher;
  private _placeholder: string;
  private constructedDomains: WebAppDomains = null;
  private onTouched: any;
  private destroy = new Subject<void>();
  stateChanges = new Subject<void>();
  private _required = false;
  private _disabled = false;
  focused = false;
  path = '';
  portalRoot = environment.portalRoot;
  consoleRoot = environment.consoleRoot;

  @HostBinding() id = `safe-subdomain-input-${SubdomainInputComponent.nextId++}`;

  @HostBinding('attr.aria-describedby') describedBy = '';

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  ngOnInit() {
    this.focusMonitor.monitor(this.elRef.nativeElement, true)
      .pipe(takeUntil(this.destroy)).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.elRef.nativeElement.querySelector('input').focus();
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(obj: any): void {
    this.value = obj.toString();
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateValue() {
    const path = this.path;
    const portalRoot = this.portalRoot;
    const consoleRoot = this.consoleRoot;
    const newPortalDomain = (path && portalRoot) ? `${path}.${portalRoot}` : null;
    const newConsoleDomain = (path && consoleRoot) ? `${path}.${consoleRoot}` : null;
    if (this.value?.portalDomain !== newPortalDomain || this.value?.consoleDomain !== newConsoleDomain) {
      this.value = {
        portalDomain: newPortalDomain,
        consoleDomain: newConsoleDomain,
      };
    }
  }

  onPathTyped(event: any) {
    this.path = event.target.value;
    this.updateValue();
  }

  onPathChanged(event: any) {
    this.path = event.target.value;
    this.updateValue();
    this.onBlur();
  }

  onContainerClick(_: MouseEvent) {
    this.elRef.nativeElement.querySelector('input').focus();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
}
