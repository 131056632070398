import { Component } from '@angular/core';
import * as fromAccount from 'src/app/modules/console/features/account/account';
import { Store } from '@ngrx/store';

@Component({
  selector: 'safe-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent {
  constructor(
    private store: Store
  ) { }

  signOut() {
    this.store.dispatch(fromAccount.signOutRequest());
  }
}
