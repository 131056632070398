import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, of } from 'rxjs';
import { map, filter, takeUntil, catchError, distinctUntilChanged } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromApp from './features/app/app';
import { Auth } from '@angular/fire/auth';
import { AuthService } from 'src/app/shared/custom/service/auth.service';
import { HttpClient } from '@angular/common/http';
import { SnackBarService } from './shared/custom/service/snack-bar.service';
import { WINDOW } from './window.providers';

@Component({
  selector: 'safe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('refreshForm') refreshForm: ElementRef;
  private _destroy$ = new Subject<void>();
  public currentFlagCss$: Observable<string>;
  mode = 'side';
  opened = false;
  title = environment.dashboardTitle;
  breakpoints = Object.keys(Breakpoints).map(key => Breakpoints[key]);
  isSignedIn$: Observable<boolean>;
  public hasMultipleLanguages = environment.locales.length > 1;
  public mapsApiLoaded$: Observable<boolean>;
  public version = environment.version.split('+')[0];

  constructor(
    @Inject(WINDOW) private window: Window,
    private snackBarService: SnackBarService,
    private bpo: BreakpointObserver,
    private auth: Auth,
    private authService: AuthService,
    private store: Store,
    private httpClient: HttpClient,
  ) { }

  public get appUrl() {
    return this.window.location.href;
  }

  private refreshConsole = () => {
    this.refreshForm.nativeElement.submit();
  }

  private handleNewVersionAvailable = () => {
    const version$ = this.store.pipe(
      select(fromApp.selectLatestVersion),
      filter(x => !!x),
      distinctUntilChanged((x, y) => x.versionNumber === y.versionNumber),
      takeUntil(this._destroy$),
    );
    const snackBarService = this.snackBarService;
    const refreshConsole = this.refreshConsole;
    const [_, n] = environment.version.split('+');
    const currentVersionNumber = Number(n);
    const newVersionAvailable$ = version$.pipe(filter(x => x.versionNumber > currentVersionNumber), takeUntil(this._destroy$));
    newVersionAvailable$.subscribe(() => {
      snackBarService.show('new-version-available.caption', 'new-version-available.action', { duration: 'permanent'}).subscribe(refreshConsole);
    });
  }

  public ngOnInit(): void {
    const { user } = this.authService;
    this.bpo.observe(this.breakpoints).pipe(takeUntil(this._destroy$)).subscribe(this.determineSidenavMode.bind(this));
    this.isSignedIn$ = user(this.auth).pipe(map(u => !!u));
    this.currentFlagCss$ = this.store.pipe(
      select(fromApp.selectFlagCss),
      filter(x => !!x && environment.locales.length > 1),
      map(x => `fi ${x}`));
    this.store.pipe(select(fromApp.selectCurrentLocale), takeUntil(this._destroy$)).subscribe(
      () => this.store.dispatch(fromApp.getLocaleRequest({ supportedLocales: environment.locales }))
    );
    this.store.dispatch(fromApp.getLocaleRequest({ supportedLocales: environment.locales }));
    this.mapsApiLoaded$ = this.httpClient.jsonp(
      `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${environment.firebaseConfig.apiKey}`, 'callback'
    ).pipe(map(() => {
      return true;
    }), catchError((err) => {
      return of(false);
    }));
    this.store.dispatch(fromApp.getVersionsRequest());
    this.handleNewVersionAvailable();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  determineSidenavMode(_: any): void {
    if (this.isExtraSmallDevice() || this.isSmallDevice()) {
      this.mode = 'over';
      this.opened = false;
      return;
    }

    this.mode = 'side';
  }

  public isExtraSmallDevice(): boolean {
    return this.bpo.isMatched(Breakpoints.XSmall);
  }

  public isSmallDevice(): boolean {
    return this.bpo.isMatched(Breakpoints.Small);
  }
}
