import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, tap, filter, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProfileActions from './profile.actions';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';
import { UsersService } from '../../service/users.service';

@Injectable()
export class ProfileEffects {
  removeUserDevice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.removeUserDeviceRequest),
      switchMap(x => this.usersService.removeUserDevice(x.organisationId, x.accountId, x.deviceId).pipe(
        map((device) => ProfileActions.removeUserDeviceSuccess({ deviceId: x.deviceId, deviceName: x.deviceName })),
        catchError(error => of(ProfileActions.removeUserDeviceFailure({ error, deviceId: x.deviceId, deviceName: x.deviceName })))
      ))
    );
  });

  getUserDevices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.getUserDevicesRequest),
      switchMap(x => this.usersService.getUserDevices(x.accountId).pipe(
        map(devices => ProfileActions.getUserDevicesSuccess({ devices })),
        catchError(error => of(ProfileActions.getUserDevicesFailure({ error })))
      ))
    );
  });

  getOrganisationUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.getOrganisationUserRequest),
      switchMap(x => this.usersService.getOrganisationUser(x.membershipId).pipe(
        map(organisationUser => ProfileActions.getOrganisationUserResponse({ organisationUser })),
        catchError(error => of(ProfileActions.getOrganisationUserFailure({ error })))
      ))
    );
  });

  setUserRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.setUserRoleRequest),
      exhaustMap(x => this.usersService.setUserRole(x.membershipId, x.role, x.enabled).pipe(
        map(result => ProfileActions.setUserRoleSuccess({ result })),
        catchError(error => of(ProfileActions.setUserRoleFailure({ error })))
      ))
    );
  });

  saveProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.saveProfileRequest),
      mergeMap(x => this.usersService.saveProfile(x.organisationUser).pipe(
        map(organisationUser => ProfileActions.saveProfileSuccess({ organisationUser })),
        tap(_ => this.snackBarService.show(
          'user-profile.save-profile-result.success',
          'user-profile.save-profile-result.ok',
          { values: { value: x.organisationUser.email } },
        )),
        catchError(error => of(ProfileActions.saveProfileFailure({ error })).pipe(
          tap(_ => this.snackBarService.show(
            'user-profile.save-profile-result.failure',
            'user-profile.save-profile-result.ok',
            { values: { value: x.organisationUser.email } },
          ))
        ))
      ))
    );
  });

  sendPasswordResetEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProfileActions.sendPasswordResetEmailRequest),
      mergeMap(x => this.usersService.sendPasswordResetEmail(x.email, x.organisationMembershipId, x.organisationId).pipe(
        filter(sentEmail => sentEmail),
        map(_ => ProfileActions.sendPasswordResetEmailSuccess({ email: x.email })),
        tap(_ => this.snackBarService.show(
          'user-profile.send-password-reset-result.success',
          'user-profile.send-password-reset-result.ok',
          { values: { value: x.email } })),
        catchError(error => of(ProfileActions.sendPasswordResetEmailFailure({ error })).pipe(
          tap(_ => this.snackBarService.show(
            'user-profile.send-password-reset-result.failure',
            'user-profile.send-password-reset-result.ok',
            { values: { value: x.email } },
          ))
        ))
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private snackBarService: SnackBarService
  ) { }
}
