import { Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { customInputComponentMixinBase } from '../custom-input-component';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ErrorStateMatcher } from '@angular/material/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { SnackBarService } from '../../service/snack-bar.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { FileValidatorFn, StorageFile } from 'src/app/models/organisation';

@Component({
  selector: 'safe-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FileUploadComponent }],
})
export class FileUploadComponent
extends customInputComponentMixinBase
implements OnInit, OnDestroy, DoCheck, ControlValueAccessor, MatFormFieldControl<StorageFile> {
  private _destroy$ = new Subject<void>();
  static nextId = 0;
  public files: File[] = [];
  private onTouched: any;
  private _storageFile: StorageFile | null = null;
  private _required = false;
  private _disabled = false;
  private _placeholder: string;
  @Input() accept: string;
  private _showPreview = false;
  @Input() validation: FileValidatorFn;
  @Input() fileDropText = 'file-upload.drop-file-here';
  @Input() storageFolder = 'file-upload';
  @HostBinding() id = `safe-file-upload-${FileUploadComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';

  @Input()
  get value(): StorageFile | null {
    return this._storageFile;
  }
  set value(value: StorageFile | null) {
    this._storageFile = value;
    if (this.pictureChanged) {
      this.pictureChanged.emit(value);
    }
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get showPreview(): boolean { return this._showPreview; }
  set showPreview(value: boolean) {
    this._showPreview = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() { return this._required; }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Output() pictureChanged = new EventEmitter<StorageFile | null>()

  @HostBinding('class.floating') get shouldLabelFloat() {
    return true;
  }

  get empty() {
    return !this.value;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private snackBarService: SnackBarService
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.focusMonitor.monitor(this.elRef.nativeElement, true).pipe(takeUntil(this._destroy$)).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  focused: boolean;
  public controlType = 'uploaded-file';
  autofilled?: boolean;

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    this.elRef.nativeElement.querySelector('input').focus();
  }

  private validateFile = (file: File) => {
    const validation = this.validation;
    const validationResult = validation ? validation(file) : null;
    if (validationResult) {
      this.snackBarService.show(validationResult.messageKey, 'file-upload.validation.ok');
    } else {
      this.files.push(file);
    }
  }

  public fileSelected = (event: any) => {
    const file: File = event.target.files[0];
    this.validateFile(file);
  }

  public dropped = (files: NgxFileDropEntry[]) => {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file(this.validateFile);
    }
  }

  private clearFiles = () => {
    while (this.files.length) {
      this.files.pop();
    }
  }

  public fileUploaded = (storageFile: StorageFile) => {
    this.clearFiles();
    this.value = storageFile;
  }

  public cancelUpload = () => {
    this.clearFiles();
  }

  public clearStorageFile = () => {
    this.value = null;
  }
}
