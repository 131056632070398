import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { OrganisationMember } from 'src/app/models/organisation';
import * as fromUsers from './users.reducer';

const members: { [membershipId: string]: MemoizedSelector<object, OrganisationMember, DefaultProjectorFn<OrganisationMember>> } = {};

export const selectUsersState = createFeatureSelector<fromUsers.State>(
  fromUsers.usersFeatureKey
);
export const organisationDomains = createSelector(selectUsersState, state => state.organisationDomains);
export const selectOrganisationUserCount = createSelector(selectUsersState, state => state.userCount);
export const isCreatingOrganisationUser = createSelector(selectUsersState, state => state.createOrganisationUserState === 'pending');
export const hasCreatedOrganisationUser = createSelector(selectUsersState, state => state.createOrganisationUserState === 'ok');
export const organisationUserCreationFailed = createSelector(selectUsersState, state => state?.createOrganisationUserState === 'error');
export const memberSuggestionResponse = createSelector(selectUsersState, state => state.memberSuggestions);
export const suspendMemberState = createSelector(selectUsersState, state => state.suspendMemberState);
export const resumeMemberState = createSelector(selectUsersState, state => state.resumeMemberState);
export const isArchivingMember = createSelector(selectUsersState, state => state?.archiveMemberState === 'pending');
export const memberArchived = createSelector(selectUsersState, state => state?.archiveMemberState === 'ok');
export const memberArchivalFailed = createSelector(selectUsersState, state => state?.archiveMemberState === 'error');
export const memberArchivalSucceeded = createSelector(selectUsersState, state => state?.archiveMemberState === 'ok');
export const memberSuspended = createSelector(selectUsersState, state => state?.suspendMemberState === 'ok');
export const memberSuspensionFailed = createSelector(selectUsersState, state => state?.suspendMemberState === 'error');
export const memberSuspensionCompleted = createSelector(selectUsersState, state => ['ok', 'error'].includes(state?.suspendMemberState));
export const memberResumed = createSelector(selectUsersState, state => state?.resumeMemberState === 'ok');
export const memberResumptionFailed = createSelector(selectUsersState, state => state?.resumeMemberState === 'error');
export const memberResumptionCompleted = createSelector(selectUsersState, state => ['ok', 'error'].includes(state?.resumeMemberState));
export const createdSingleUser = createSelector(selectUsersState, state =>
  state.userCreation?.kind === 'singleMember' ? state.userCreation : null);
export const uploadedCsv = createSelector(selectUsersState, state =>
  state.userCreation?.kind === 'csvUpload' ? state.userCreation : null);
export function getMember(membershipId: string) {
  function addSelector() {
    const selector = createSelector(selectUsersState, state => state?.members[membershipId]);
    members[membershipId] = selector;
    return selector;
  }
  return members[membershipId] || addSelector();
}
