import { Component, OnInit, EventEmitter, Output, OnDestroy, ViewChild, Inject } from '@angular/core';
import * as utilities from 'src/app/utilities';
import { MatStepper } from '@angular/material/stepper';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as fromConnect from '../../features/connect/connect';
import { Store, select } from '@ngrx/store';
import { Subject, Observable } from 'rxjs';
import { map, filter, take, takeUntil } from 'rxjs/operators';
import { COMMA, SPACE, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { ThemeEmitterComponent } from '../../shared/custom/components/theme-emitter/theme-emitter.component';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';
import { WINDOW } from 'src/app/window.providers';
import { ActivatedRoute } from '@angular/router';
import { LocalTenantDomain, TenantState } from 'src/app/shared/custom/models/domains';
import { TenantConfigService } from 'src/app/shared/custom/service/tenant-config.service';

type TabAriaLabel = 'enter-email' | 'enter-password' | 'set-up-account' | 'no-account' | 'reset-password' | 'signed-in';

@Component({
  selector: 'safe-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(WINDOW) private window: Window,
    private tenantConfigService: TenantConfigService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private store: Store,
  ) { }

  readonly separatorKeysCodes: number[] = [COMMA, SPACE, ENTER, SEMICOLON, TAB];
  private _destroy$ = new Subject<void>();
  public signInColor$: Observable<string>;
  public currentTab: TabAriaLabel = 'enter-email';
  public tenantState: TenantState;
  public domains: string[] = [];
  public passwordCreationRequired = false;
  public accountCreationRequired = false;
  public enterPassword = false;
  public signedIn$: Observable<boolean>;

  public tabs: { [key in TabAriaLabel]: number } = {
    ['enter-email']: 0,
    ['no-account']: 1,
    ['set-up-account']: 2,
    ['enter-password']: 3,
    ['reset-password']: 4,
    ['signed-in']: 5,
  };
  public passwordResetRequested = false;
  email: string;

  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;
  @ViewChild(ThemeEmitterComponent, { static: true }) theme: ThemeEmitterComponent;
  @Output() selectedTabChange: EventEmitter<MatTabChangeEvent> = new EventEmitter();
  @ViewChild(MatStepper, { static: true }) public stepper: MatStepper;

  ngOnInit() {
    this.tenantState = this.tenantConfigService.tenantState;
    this.store.dispatch(fromConnect.firebaseSignInReset());
    this.store.dispatch(fromConnect.verifyEmailReset());
    const signInFailed$ = this.store.pipe(select(fromConnect.signInFailed));
    this.signInColor$ = signInFailed$.pipe(map(x => x ? 'warn' : 'primary'));
    this.signedIn$ = this.store.pipe(select(fromConnect.signedIn), filter(x => x));
    utilities.subscribe(this.signedIn$, this._destroy$, () => this.moveToTab('signed-in'));
    const verifyEmailResult$ = this.store.pipe(select(fromConnect.selectVerifyEmailResult), filter(x => !!x), takeUntil(this._destroy$));
    verifyEmailResult$.subscribe(this.emailVerified);
    utilities.subscribe(this.route.queryParams.pipe(filter(p => !!p.email)), this._destroy$, (p) => {
      this.emailEntered(p.email, this.tenantState.localTenantDomains);
    });
    const verifyEmailFailure$ = this.store.pipe(select(fromConnect.emailVerificationFailed), filter(x => x));
    utilities.subscribe(verifyEmailFailure$, this._destroy$, () => {
      this.snackBarService.show('sign-in.email-verification-failed.message', 'sign-in.email-verification-failed.ok');
      this.emailChanged();
    });
    this.emailChanged();
  }

  private signInToOidcProvider = (email: string) => {
    const store = this.store;
    this.route.params.pipe(take(1)).subscribe(params => {
      const path = params.path || '';
      store.dispatch(fromConnect.oidcSignInRequest({ email, path }));
    });
  }

  private signInToSamlProvider = (samlProviderId: string, email: string) => {
    const store = this.store;
    this.route.params.pipe(take(1)).subscribe(params => {
      const path = params.path || '';
      store.dispatch(fromConnect.samlSignInRequest({ samlProviderId, email, path }));
    });
  }

  private processVerifiedEmail = (email: string, accountCreationRequired: boolean, passwordCreationRequired: boolean) => {
    this.email = email;
    this.store.dispatch(fromConnect.verifyEmailReset());
    if (accountCreationRequired) {
      this.accountCreationRequired = accountCreationRequired;
      this.moveToTab('no-account');
      return;
    }
    const tenantState = this.tenantState;
    if (!!tenantState.localTenantDomain?.oidcProviderId) {
      this.signInToOidcProvider(email);
    } else if (!!tenantState.localTenantDomain?.samlProviderId) {
      this.signInToSamlProvider(tenantState.localTenantDomain.samlProviderId, email);
    } else {
      this.passwordCreationRequired = passwordCreationRequired;
      this.enterPassword = !passwordCreationRequired;
      this.moveToTab(passwordCreationRequired ? 'set-up-account' : 'enter-password');
    }
  }

  private emailVerified = (result: {email: string, accountCreationRequired: boolean, passwordCreationRequired: boolean, redirectToDomain: string | null }) => {
    if (result.redirectToDomain) {
      this.redirect(result.email, result.redirectToDomain);
    } else {
      this.processVerifiedEmail(result.email, result.accountCreationRequired, result.passwordCreationRequired);
    }
  }

  cancelEnterPassword() {
    if (this.passwordCreationRequired) {
      this.moveToTab('set-up-account');
      this.enterPassword = false;
    } else {
      this.emailChanged();
    }
  }

  accountSetupEmailSent() {
    this.enterPassword = true;
    this.snackBarService.show('account-setup.completion-instructions-text', 'account-setup.ok', { values: { value: this.email } });
    this.moveToTab('enter-password');
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  moveToTab = (tabLabel: TabAriaLabel) => {
    this.currentTab = tabLabel;
  }

  createForgottenPasswordTab() {
    this.passwordResetRequested = true;
    this.moveToTab('reset-password');
  }

  cancelPasswordReset() {
    this.passwordResetRequested = false;
    this.moveToTab('enter-password');
  }

  emailChanged() {
    this.email = null;
    this.passwordResetRequested = false;
    this.passwordCreationRequired = false;
    this.accountCreationRequired = false;
    this.enterPassword = false;
    this.moveToTab('enter-email');
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTabChange.emit(event);
    this.tabs[event.tab.ariaLabelledby] = event.index;
  }

  emailEntered(email: string, localTenantDomains: LocalTenantDomain[]) {
    const tenantState = this.tenantState;
    this.store.dispatch(fromConnect.verifyEmailRequest({
      email,
      localTenantDomains,
      localDomain: tenantState.urlHost,
    }));
  }

  redirect(email: string, consoleDomain: string) {
    this.route.url.pipe(filter(_ => environment.tenantRedirect), take(1)).subscribe(url => {
      this.window.location.replace(`https://${consoleDomain}/${url}?email=${encodeURIComponent(email)}`);
    });
  }
}
