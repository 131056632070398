import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, ComponentRef } from '@angular/core';
import { SpinnerOverlayComponent } from '../shared/custom/components/spinner-overlay/spinner-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef = null;
  private componentRef: ComponentRef<SpinnerOverlayComponent>;
  private spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);

  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create();
  }

  public show() {
    if (this.componentRef) { this.overlayRef.detach(); }
    this.componentRef = this.overlayRef.attach(this.spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
  }

  public hide() {
    this.overlayRef.detach();
  }
}
