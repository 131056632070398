import { createAction, props } from '@ngrx/store';
import { PlatformVersion } from 'src/app/models/system';
import { LocalTenantDomain } from 'src/app/shared/custom/models/domains';

export interface LocaleOption {
  localeCode: string;
  cssValue: string;
  language: string;
  isSelected: boolean;
}

export const getLocaleRequest = createAction(
  '[App] Get locale request',
  props<{ supportedLocales: string[] }>()
);

export const getLocaleResult = createAction(
  '[App] Get locale result',
  props<{ locale: string, languages: LocaleOption[] }>()
);

export const getLocaleFailure = createAction(
  '[App] Get locale failure',
  props<{ error: any }>()
);

export const getLocalTenantDomainsRequest = createAction(
  '[App] Get local tenant domains request',
);

export const getLocalTenantDomainsResult = createAction(
  '[App] Get local tenant domains result',
  props<{ localTenantDomains: LocalTenantDomain[] }>()
);

export const getLocalTenantDomainsFailure = createAction(
  '[App] Get local tenant domains failure',
  props<{ error: any }>()
);

export const getUrlHostRequest = createAction(
  '[App] Get URL host request',
);

export const getUrlHostResult = createAction(
  '[App] Get URL host result',
  props<{ urlHost: string }>(),
);

export const getUrlHostFailure = createAction(
  '[App] Get URL host failure',
  props<{ error: any }>()
);

export const setLocaleRequest = createAction(
  '[App] Set locale request',
  props<{ locale: string }>()
);

export const setLocaleSuccess = createAction(
  '[App] Set locale success',
  props<{ locale: string }>()
);

export const setLocaleFailure = createAction(
  '[App] Set locale failure',
  props<{ error: any }>()
);

export const getVersionsRequest = createAction(
  '[App] Get versions request'
);

export const getVersionsResponse = createAction(
  '[App] Get versions response',
  props<{ versions: PlatformVersion[] }>()
);

export const getVersionsFailure = createAction(
  '[App] Get versions failure',
  props<{ error: any }>()
);

export const getVersionTimestampRequest = createAction(
  '[App] Get version timestamp request',
);

export const getVersionTimestampFailure = createAction(
  '[App] Get version timestamp failure',
  props<{ error: any }>()
);

export const getVersionTimestampResponse = createAction(
  '[App] Get version timestamp response',
  props<{ timestamp: Date }>()
);
