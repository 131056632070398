import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  CreateOperationsSubscription,
  CreateOrganisation, ExternalDomain, InternalDomain, OperationsSubscription,
  Organisation, OrganisationConfiguration, OrganisationRole, OrganisationSettings, 
  RoleCounts, StorageFile, sum, UserCsvUpload, UserUploadError
} from 'src/app/models/organisation';
import { Page, PageRequest } from 'src/app/models/page-result';
import {
  DomainAffiliationDto,
  OperationsSubscriptionDto,
} from '../data/organisation.dto';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { Auth, IdTokenResult } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { AuthService } from 'src/app/shared/custom/service/auth.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export type OrganisationsRequest = PageRequest & {
  direction: 'asc' | 'desc';
  orderBy: 'name' | 'size' | 'userCount';
};

export type UserUploadErrorsRequest = PageRequest & {
  direction: 'asc' | 'desc';
  organisationId: string;
  userCsvUploadId: string;
  orderBy: 'email' | 'failedUtc';
};

export type UserCsvUploadsRequest = PageRequest & {
  direction: 'asc' | 'desc';
  organisationId: string;
  orderBy: 'email' | 'uploadedUtc';
};

const requiredRoles = [OrganisationRole.operations, OrganisationRole.userManager];

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  private organisationsFunction: HttpsCallable<any, any>;
  private userUploadErrorsFunction: HttpsCallable<any, any>;
  private userCsvUploadsFunction: HttpsCallable<any, any>;
  private createOrganisationFunction: HttpsCallable<any, any>;
  private updateLicensesFunction: HttpsCallable<any, any>;
  private updateSettingsFunction: HttpsCallable<any, any>;
  private uploadLogoFunction: HttpsCallable<any, StorageFile>;
  private createOperationsSubscriptionFunction: HttpsCallable<CreateOperationsSubscription, OperationsSubscription>;

  constructor(
    private functions: Functions,
    private auth: Auth,
    private firestore: Firestore,
    private authService: AuthService,
    private functionsService: FunctionsService,
    private firestoreService: FirestoreService,
    private cookieService: CookieService,
    private http: HttpClient,
  ) {
    const { httpsCallable } = functionsService;
    this.organisationsFunction = httpsCallable(functions, 'organisationsGen2');
    this.userUploadErrorsFunction = httpsCallable(functions, 'userUploadErrorsGen2');
    this.userCsvUploadsFunction = httpsCallable(functions, 'userCsvUploadsGen2');
    this.createOrganisationFunction = httpsCallable(functions, 'createOrganisationGen2');
    this.updateLicensesFunction = httpsCallable(functions, 'updateLicensesGen2');
    this.updateSettingsFunction = httpsCallable(functions, 'updateSettingsGen2');
    this.uploadLogoFunction = httpsCallable(functions, 'uploadLogoGen2');
    this.createOperationsSubscriptionFunction = httpsCallable(functions, 'createOperationsSubscriptionGen2');
  }

  public getOrganisation(organisationId: string): Observable<Organisation> {
    const toOrganisation: (organisationDto: any, subscriptions: any[]) => Organisation = (
      organisationDto: any,
      subscriptions: any[],
    ) => {
      const organisation: Organisation = {
        organisationId: organisationDto.id,
        size: organisationDto.size,
        name: organisationDto.name,
        configuration: organisationDto.configuration ?? OrganisationConfiguration.default,
        roleCounts: organisationDto.roleCounts,
        activeUserCount: organisationDto.activeUserCount,
        totalUserCount: organisationDto.totalUserCount,
        arcIncidentCount: organisationDto.arcIncidentCount || 0,
        arcTestSignalCount: organisationDto.arcTestSignalCount || 0,
        activeCheckInCount: organisationDto.activeCheckInCount,
        totalCheckInCount: organisationDto.totalCheckInCount,
        lastCheckInUtc: organisationDto.lastCheckInUtc?.toDate() || new Date(0),
        liveLocationUserCount: organisationDto.liveLocationUserCount,
        liveLocationAssetCount: organisationDto.liveLocationAssetCount,
        liveLocationFacilityCount: organisationDto.liveLocationFacilityCount,
        sentMessageCount: organisationDto.sentMessageCount,
        liveAlertCount: organisationDto.liveAlertCount || 0,
        liveIndividualAlertCount: organisationDto.liveIndividualAlertCount || 0,
        liveAssetAlertCount: organisationDto.liveAssetAlertCount || 0,
        liveFacilityAlertCount: organisationDto.liveFacilityAlertCount || 0,
        lastAlertUpdate: organisationDto.lastAlertUpdate?.toDate() || new Date(0),
        lastLiveLocationUpdateUtc: organisationDto.lastLiveLocationUpdateUtc?.toDate() || new Date(0),
        membersUpdatedUtc: organisationDto.membersUpdatedUtc?.toDate() || new Date(0),
        lastDeviceUpdate: organisationDto.lastDeviceUpdate?.toDate() || new Date(0),
        ruleCount: organisationDto.ruleCount || 0,
        userCsvUploadCount: organisationDto.userCsvUploadCount || 0,
        licenseRoleLimits: sum(subscriptions.map(l => l.roleCounts)),
        headOfficeId: organisationDto.headOfficeId,
        startUtc: organisationDto.startUtc.toDate(),
        arcIntegration: organisationDto.arcIntegration,
        logo: organisationDto.logo || null,
        settings: organisationDto.settings,
      };
      return organisation;
    };
    const { collection, docData, doc, collectionData } = this.firestoreService;
    const organisationDoc$ = docData(doc(this.firestore, `organisations/${organisationId}`));
    const subscriptionsCollection$ = collectionData(collection(this.firestore, `organisations/${organisationId}/subscriptions`));
    return combineLatest([organisationDoc$, subscriptionsCollection$]).pipe(
      map(([organisation, licenses]) => toOrganisation(organisation, licenses))
    );
  }

  public getOrganisationCount(): Observable<number> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, 'system/0')).pipe(map(s => s.organisationCount));
  }

  public createOrganisation(create: CreateOrganisation): Observable<string> {
    return from(this.createOrganisationFunction({ create })).pipe(map(x => x.data));
  }

  public updateLicenses(organisationId: string, licenses: RoleCounts): Observable<RoleCounts> {
    return from(this.updateLicensesFunction({ organisationId, licenses })).pipe(map(x => x.data));
  }

  public updateSettings(organisationId: string, settings: OrganisationSettings): Observable<OrganisationSettings> {
    return from(this.updateSettingsFunction({ organisationId, settings })).pipe(map(x => x.data));
  }

  public uploadLogo(organisationId: string, logo: StorageFile): Observable<StorageFile> {
    return from(this.uploadLogoFunction({ organisationId, logo })).pipe(map(x => x.data));
  }
 
  public createOperationsSubscription(create: CreateOperationsSubscription): Observable<OperationsSubscription> {
    return from(this.createOperationsSubscriptionFunction(create)).pipe(map(x => x.data));
  }
  
  public addInternalDomains(organisationId: string, domains: string[]): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const createInternalDomains = httpsCallable<any, boolean>(this.functions, 'createInternalDomainsGen2');
    return from(createInternalDomains({ organisationId, domains })).pipe(map(x => x.data));
  }

  public addExternalDomains(organisationId: string, domains: string[]): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const createExternalDomains = httpsCallable<any, boolean>(this.functions, 'createExternalDomainsGen2');
    return from(createExternalDomains({ organisationId, domains })).pipe(map(x => x.data));
  }

  public removeInternalDomain(organisationId: string, domainId: string): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const removeInternalDomain = httpsCallable<any, boolean>(this.functions, 'removeInternalDomainGen2');
    return from(removeInternalDomain({ organisationId, domainId })).pipe(map(x => x.data));
  }

  public removeExternalDomain(organisationId: string, domainId: string): Observable<any> {
    const { httpsCallable } = this.functionsService;
    const removeExternalDomain = httpsCallable<any, boolean>(this.functions, 'removeExternalDomainGen2');
    return from(removeExternalDomain({ organisationId, domainId })).pipe(map(x => x.data));
  }

  public organisations(page: OrganisationsRequest): Observable<Page<Organisation>> {
    return from(this.organisationsFunction(page)).pipe(map(x => x.data));
  }

  public userUploadErrors(page: UserUploadErrorsRequest): Observable<Page<UserUploadError>> {
    return from(this.userUploadErrorsFunction(page)).pipe(map(x => x.data));
  }

  public userCsvUploads(page: UserCsvUploadsRequest): Observable<Page<UserCsvUpload>> {
    return from(this.userCsvUploadsFunction(page)).pipe(map(x => x.data));
  }

  private hasConsoleAccessRole(roles: string[]) {
    return !!roles && roles.includes && (requiredRoles.filter(x => roles.includes(x)).length > 0);
  }


  public hasConsoleAccess(organisationId: string) {
    const { user } = this.authService;
    return user(this.auth).pipe(switchMap(u => {
      return from(u.getIdTokenResult()).pipe(map(
        t => this.hasConsoleAccessRole(t.claims[`roles_${organisationId}`] as string[])));
    }));
  }

  private hasRole(role: OrganisationRole, roles: string[]) {
    return !!roles && roles.includes && roles.includes(role);
  }

  private hasAccess(role: OrganisationRole, organisationId: string) {
    const { user } = this.authService;
    return user(this.auth).pipe(switchMap(u => {
      return from(u.getIdTokenResult()).pipe(map(
        t => this.hasRole(role, t.claims[`roles_${organisationId}`] as string[])));
    }), take(1));
  }

  private getDomains(
    organisationId: string,
    domainPath: string,
    toDomain: (affiliation: DomainAffiliationDto) => any
  ) {
    const { collectionData, orderBy, query, collection } = this.firestoreService;
    const affiliations$ = collectionData(query(
      collection(this.firestore, `organisations/${organisationId}/${domainPath}`),
      orderBy('domain')));
    return affiliations$.pipe(map(x => x.map(toDomain)));
  }

  public getInternalDomains(organisationId: string): Observable<InternalDomain[]> {
    const toInternalDomain = (domain: any) => ({
      id: domain.id,
      domain: domain.domain,
      localTenantId: domain.localTenantId || null,
      oidcProviderId: domain.oidcProviderId || null,
      samlProviderId: domain.samlProviderId || null,
      activeUserCount: domain.activeUserCount,
      totalUserCount: domain.totalUserCount,
    });
    return this.getDomains(organisationId, 'internalDomains', toInternalDomain);
  }

  public getExternalDomains(organisationId: string): Observable<ExternalDomain[]> {
    const toExternalDomain = (affiliation: DomainAffiliationDto) => ({
      id: affiliation.id,
      domain: affiliation.domain,
      activeUserCount: affiliation.activeUserCount,
      totalUserCount: affiliation.totalUserCount,
    });
    return this.getDomains(organisationId, 'externalDomains', toExternalDomain);
  }

  public getOperationsSubscriptions(organisationId: string): Observable<OperationsSubscription[]> {
    const { collectionData, orderBy, query, collection } = this.firestoreService;
    const toOperationsSubscription = (dto: OperationsSubscriptionDto) => ({
      subscriptionId: dto.id,
      frequency: dto.frequency,
      cancelled: dto.cancelled?.toDate() || null,
      start: dto.start.toDate(),
    });
    const subscriptions$ = collectionData(query(
      collection(this.firestore, `organisations/${organisationId}/operationsSubscriptions`),
      orderBy('start', 'desc')));
    return subscriptions$.pipe(map(dtos => dtos.map(toOperationsSubscription)));
  }

  public getUserCsvUpload(organisationId: string, userCsvUploadId: string): Observable<UserCsvUpload> {
    const { docData, doc } = this.firestoreService;
    const toUpload = (dto: any) => ({
      userCsvUploadId: dto.id,
      uploadedUtc: dto.uploadedUtc.toDate(),
      uploader: dto.uploader,
      storageFile: dto.storageFile,
      userUploadErrorCount: dto.userUploadErrorCount || 0
    });
    return docData(doc(this.firestore, `organisations/${organisationId}/userCsvUploads/${userCsvUploadId}`)).pipe(
      map(toUpload)
    );
  }

  public hasSupportManagerAccess(organisationId: string) {
    return this.hasAccess(OrganisationRole.supportManager, organisationId);
  }

  public hasSupportAccess(organisationId: string) {
    return this.hasAccess(OrganisationRole.support, organisationId);
  }

  public downloadOrganisationMembersReport(organisationId: string): Observable<Blob> {
    const { idToken } = this.authService;
    return idToken(this.auth).pipe(switchMap(token =>
      this.http.post(`${environment.appEngineUrl}organisation/members`, {
        organisationId,
      }, {
        responseType: 'blob',
        headers: {
          contentType: 'application/json',
          authorization: `Bearer ${token}`,
        },
      }
    )));
  }

  archiveOrganisation(organisationId: string): Observable<string> {
    const { idToken } = this.authService;
    return idToken(this.auth).pipe(switchMap(token =>
      this.http.post(`${environment.appEngineUrl}delete/organisation`, {
        organisationId,
      }, {
        responseType: 'text',
        headers: { contentType: 'application/json', authorization: `Bearer ${token}` },
      }
    )));
  }

  public chooseCurrentOrganisation(): Observable<string> {
    const { docData, doc } = this.firestoreService;
    const cookieService = this.cookieService;
    const hasConsoleAccessRole = this.hasConsoleAccessRole;
    const user = this.auth.currentUser;
    function getClaims(tokenResult: IdTokenResult) {
      const accountId: string = tokenResult.claims.accountId.toString();
      const organisationIds = Object.keys(tokenResult.claims)
        .filter(claimsKey => claimsKey.startsWith('roles_'))
        .filter(claimsKey => hasConsoleAccessRole(tokenResult.claims[claimsKey] as any))
        .map(claimsKey => claimsKey.split('_')[1]);
      return { accountId, organisationIds };
    }

    const { getIdTokenResult } = this.authService;
    return from(getIdTokenResult(user)).pipe(map(getClaims), switchMap(({ accountId, organisationIds }) => {
      if (organisationIds.length === 0) { return of(''); }
      return docData(doc(this.firestore, `accounts/${accountId}`)).pipe(switchMap(account => {
        if (!account.activeMembershipId) { return of(''); }
        return docData(doc(this.firestore, `organisationMemberships/${account.activeMembershipId}`)).pipe(map(membership => {
          const organisationId = organisationIds.includes(membership.organisationId)
            ? membership.organisationId
            : cookieService.get('currentOrganisationId');
          return organisationIds.find(o => o === organisationId) || organisationIds[0];
        }));
      }), take(1));
    }));
  }

  public userAlreadyExists(email: string, organisationId: string): Observable<boolean> {
    const { collectionData, query, collection, where } = this.firestoreService;
    const existingUsers$ = collectionData(query(
      collection(this.firestore, 'organisationMemberships'),
      where('organisationId', '==', organisationId),
      where('email_lower', '==', email.toLowerCase())
    ));
    return existingUsers$.pipe(map(users => users.length > 0));
  }

  public smsCodeAlreadyExists(smsCode: string, organisationId: string): Observable<boolean> {
    const { collectionData, query, collection, where } = this.firestoreService;
    const existingSmsCode$ = collectionData(query(
      collection(this.firestore, `organisations/${organisationId}/responses`),
      where('smsCode', '==', smsCode)));
    return existingSmsCode$.pipe(map(smsCodes => smsCodes.length > 0));
  }
}
