import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { Auth } from '@angular/fire/auth';
import { AuthService } from 'src/app/shared/custom/service/auth.service';

@Component({
  selector: 'safe-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  public signInState$: Observable<{ isSignedIn: boolean }>;
  public title = environment.dashboardTitle;

  constructor(
    private auth: Auth,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    const { user } = this.authService;
    const auth = this.auth;
    this.signInState$ = user(auth).pipe(map(u => ({ isSignedIn: !!u })), takeUntil(this._destroy$));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
