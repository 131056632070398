import { 
  AfterViewInit, ChangeDetectorRef, Component, 
  NgZone, OnDestroy, OnInit, ViewChild 
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromApp from 'src/app/features/app/app';
import { 
  BehaviorSubject, Observable, Subject, catchError, 
  combineLatest, delay, distinctUntilChanged, filter, 
  finalize, map, of, pairwise, startWith, take, takeUntil, tap 
} from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { SystemService } from 'src/app/modules/console/service/system.service';
import { VersionsRequest } from 'src/app/service/system.service';
import { ElasticSearchPage } from 'src/app/models/page-result';
import { PlatformVersion, ReleasePlatform } from 'src/app/models/system';
import { OrderFields } from 'src/app/shared/custom/models/search';

class VersionHit {
  fields: {
    versionId: string[];
    platform: string[];
    versionName: string[];
    versionNumber: number[];
    addedUtc: string[];
    features: string[];
    fixes: string[];
    enhancements: string[];
  };
  highlight: {
    versionName: string[];
    versionNumberText: string[];
    features: string[];
    fixes: string[];
    enhancements: string[];
  }
}

export class VersionsDataSource extends MatTableDataSource<VersionHit> {
  private versionsSubject$ = new BehaviorSubject<any[]>([]);
  private loadingSubject$ = new BehaviorSubject<boolean>(false);
  public loadingState$: Observable<{ loading: boolean; initialLoad: boolean; }>;
  constructor(
    private systemService: SystemService,
    private totalCount$: Subject<number>,
    loaded: () => void,
    request$: Observable<VersionsRequest>
  ) {
    super();
    request$.subscribe(this.loadVersions.bind(this));
    this.loadingState$ = this.loadingSubject$.asObservable().pipe(
      distinctUntilChanged(), 
      tap(loaded), 
      map((loading, index) => ({ loading, initialLoad: loading && index < 2 })),
    );
  }

  connect() {
    return this.versionsSubject$;
  }

  disconnect(): void {
    this.versionsSubject$.complete();
    this.loadingSubject$.complete();
  }

  private loadVersions(request: VersionsRequest) {
    this.loadingSubject$.next(true);
    this.systemService.searchForReleases(request).pipe(
      take(1),
      catchError(() => of(ElasticSearchPage.empty())),
      finalize(() => {
        this.loadingSubject$.next(false);
      })
    ).subscribe(page => {
      this.totalCount$.next(page.total.value);
      const versions = page.hits;
      this.versionsSubject$.next(versions);
    });
  }
}

const orderFields = {
  versionName: 'versionName',
  versionNumber: 'versionNumber',
  releaseDate: 'addedUtc',
};

@Component({
  selector: 'safe-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.scss']
})
export class VersionHistoryComponent implements OnInit, OnDestroy, AfterViewInit {
  private _destroy$ = new Subject<void>();
  private request: VersionsRequest = {
    order: [{ versionNumber: { order: 'desc' } }],
    search: {
      searchTerm: '',
      platforms: [ReleasePlatform.console],
      dateRange: null,
    },
    skip: 0,
    take: 25
  };
  private versions$ = new BehaviorSubject<PlatformVersion[]>([]);
  public versionsDataSource: VersionsDataSource;
  public displayedColumns: string[] = ['versionName', 'versionNumber', 'releaseDate', 'features', 'fixes', 'enhancements', 'select'];
  private request$ = new BehaviorSubject<VersionsRequest>(this.request);
  private _selectedVersionId$ = new BehaviorSubject<string>(null);
  public get selectedVersionId() { return this._selectedVersionId$.value; }
  public set selectedVersionId(value: string) { this._selectedVersionId$.next(value); }
  public totalCount$ = new BehaviorSubject<number>(0);
  public footer$: Observable<string[]>;
  public today = new Date();
  public indicator$: Observable<any>;
  public versionCount$: Observable<number>;
  public emptyTable$: Observable<string[]>;
  public currentLocale$: Observable<string>;
  public hideIfPopulated$: Observable<string>;
  public pageSize$: Observable<number>;
  private currentVersionName: string;
  private currentVersionNumber: string;
  public searchFormGroup = this.formBuilder.group({
    searchTerm: [''],
    dateRange: this.formBuilder.group({
      restrictDates: [false],
      from: [moment(new Date()).add({ days: -7 })],
      to: [moment(new Date())],
    }),
  });
  @ViewChild(MatSort) sort: MatSort;
  public get dateRange() { return this.searchFormGroup.controls.dateRange; }

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private systemService: SystemService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.pageSize$ = this.request$.pipe(map(x => x.take));
    const versionTimestamp$ = this.store.pipe(select(fromApp.selectVersionTimestamp), distinctUntilChanged(), takeUntil(this._destroy$));
    this.versionsDataSource = new VersionsDataSource(
      this.systemService,
      this.totalCount$,
      () => this.ngZone.run(() => this.cdr.markForCheck()),
      combineLatest([this.request$, versionTimestamp$]).pipe(map(([r]) => r), takeUntil(this._destroy$))
    );
    this.footer$ = combineLatest([this.versionsDataSource.loadingState$, this.totalCount$]).pipe(map(
      ([loadingState, totalCount]) => loadingState.initialLoad ? ['initialLoad'] : totalCount === 0 ? ['emptyTable'] : []
    ), takeUntil(this._destroy$));
    this.hideIfPopulated$ = this.totalCount$.pipe(map(n => n === 0 ? null : 'hide'), takeUntil(this._destroy$));
    this.indicator$ = this.versionsDataSource.loadingState$.pipe(map(s => ({ indicator: true, hide: !s.loading })), takeUntil(this._destroy$));
    this.currentLocale$ = this.store.pipe(select(fromApp.selectCurrentLocale), takeUntil(this._destroy$));
    const searchTermChanges$ = this.searchFormGroup.controls.searchTerm.valueChanges;
    searchTermChanges$.pipe(pairwise(), filter(([x, y]) => !!x && !y), takeUntil(this._destroy$)).subscribe(this.runSearch);
    this.request$.subscribe(() => this.searchFormGroup.disable());
    this.versionsDataSource.loadingState$.pipe(filter(x => !x.loading), takeUntil(this._destroy$)).subscribe(() => this.searchFormGroup.enable());
    [this.currentVersionName, this.currentVersionNumber] = environment.version.split('+');
    this.store.dispatch(fromApp.getVersionTimestampRequest());
  }

  ngAfterViewInit(): void {
    this.versionsDataSource.sort = this.sort;
    const versionTimestamp$ = this.store.pipe(
      select(fromApp.selectVersionTimestamp), distinctUntilChanged(),
    );
    const sortChange$ = this.sort.sortChange.asObservable().pipe(
      startWith({ active: this.sort.active, direction: this.sort.direction }),
      distinctUntilChanged((prev, current) => {
        return prev.active === current.active && prev.direction === current.direction;
      })
    );
    const state$ = combineLatest([sortChange$, versionTimestamp$]).pipe(delay(0), takeUntil(this._destroy$));
    state$.subscribe(([sort]) => {
      const order: OrderFields[] = sort.direction
        ? [{ [orderFields[sort.active]]: { order: sort.direction } }]
        : [{ versionNumber: { order: 'desc' } }];
      this.request = { ...this.request, order };
      this.request$.next(this.request);
    });
    this.versionsDataSource.loadingState$.subscribe(state => {
      if (state.loading) {
        this.searchFormGroup.controls.searchTerm.disable();
      } else {
        this.searchFormGroup.controls.searchTerm.enable();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._selectedVersionId$.complete();
    this.totalCount$.complete();
    this.versions$.complete();
  }

  onPage(event: PageEvent) {
    this.request = {
      ...this.request,
      skip: event.pageIndex * event.pageSize,
      take: event.pageSize
    };
    this.request$.next(this.request);
  }

  rowState = (row: VersionHit) => {
    const versionId = row.fields.versionId[0];
    const versionNumber = row.fields.versionNumber[0];
    return { version: true, selected: versionId === this.selectedVersionId, current: versionNumber.toString() === this.currentVersionNumber };
  }

  detailsClass(row: VersionHit) {
    const versionId = row.fields.versionId[0];
    return { details: true, collapsed: versionId !== this.selectedVersionId };
  }

  selectionToggled(_: Event, row: VersionHit) {
    const versionId = row.fields.versionId[0];
    const rowWasSelected = this.selectedVersionId === versionId;
    const rowIsSelected = !rowWasSelected;
    this.selectedVersionId = rowIsSelected ? versionId : null;
    this.cdr.detectChanges();
  }

  public get searchIsActive() {
    return !!this.searchFormGroup.controls.searchTerm.value || this.searchFormGroup.controls.dateRange.value.restrictDates;
  }

  public runSearch = () => {
    const dateRange = this.searchFormGroup.controls.dateRange.value;
    this.request = {
      ...this.request,
      skip: 0,
      search: {
        searchTerm: this.searchFormGroup.controls.searchTerm.value,
        platforms: [ReleasePlatform.console],
        dateRange: dateRange.restrictDates ? {
          startDate: dateRange.from.toDate(),
          endDate: dateRange.to.toDate(),
        } : null,
      }
    };
    this.request$.next(this.request);
  }
}
