import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElasticSearchPage, Page, PageRequest } from 'src/app/models/page-result';
import { Backup, BackupSchedule, CreateBackupSchedule } from 'src/app/models/schedule';
import { 
  AddPlatformVersions, AppRegistration, CreateAppRegistration, Module, PlatformVersion, ReleasePlatform, ReleaseValidation, UpdateAppRegistration 
} from 'src/app/models/system';
import { AppRegistrationDto, ModuleDto, PlatformVersionDto } from '../data/system.dto';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { Firestore } from '@angular/fire/firestore';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import {
  AddArcIntegration, AlarmReceivingCentre, ArcAccountAllocation,
  ArcAccountAssignment, ArcIncident, ArcIntegration, ArcTestSignal, CreateArc, RemoveArcIntegration, UpdateArc
} from 'src/app/models/arc';
import { Organisation, OrganisationConfiguration, StorageFile } from 'src/app/models/organisation';
import { ArcAccountAllocationDto, ArcAccountAssignmentDto, ArcIntegrationDto } from '../data/arc.dto';
import { VersionsRequest } from 'src/app/service/system.service';

export type ArcIncidentsRequest = PageRequest & {
  direction: 'asc' | 'desc';
  arcId: string;
  orderBy: 'serverUpdatedUtc' | 'user.email' | 'user.name';
};

export type ArcTestSignalsRequest = PageRequest & {
  direction: 'asc' | 'desc';
  arcId: string;
  orderBy: 'serverUpdatedUtc' | 'user.email' | 'user.name';
};

export type BackupRequest = PageRequest & {
  direction: 'asc' | 'desc';
  scheduleId: string;
  orderBy: 'startedUtc';
};

export type ArcAccountAssignmentRequest = PageRequest & {
  direction: 'asc' | 'desc';
  arcId: string;
  organisationId: string;
  orderBy: 'assignment.assignedUtc' | 'assignment.user.email' | 'assignment.user.name';
};

function toArc(dto: any): AlarmReceivingCentre {
  return {
    arcId: dto.id,
    arcName: dto.arcName,
    contact: dto.contact,
    ipAddress: dto.ipAddress,
    port: dto.port,
    integrationCount: dto.integrationCount,
    incidentCount: dto.incidentCount,
    testSignalCount: dto.testSignalCount,
    startUtc: dto.startUtc.toDate(),
    serverUpdatedUtc: dto.serverUpdatedUtc?.toDate()
  };
}

function toArcIntegration(dto: ArcIntegrationDto): ArcIntegration {
  return {
    organisationId: dto.id,
    organisationName: dto.name,
    createdById: dto.createdById,
    allocationCount: dto.allocationCount,
    assignmentCount: dto.assignmentCount,
    startUtc: dto.startUtc.toDate(),
    serverUpdatedUtc: dto.serverUpdatedUtc.toDate(),
  };
}

function toAppRegistration(dto: AppRegistrationDto): AppRegistration {
  return {
    appRegistrationId: dto.id,
    displayName: dto.displayName,
    oidcClientId: dto.oidcClientId,
    androidRedirectUri: dto.androidRedirectUri,
    iosRedirectUri: dto.iosRedirectUri,
    credentialId: dto.credentialId,
  };
}

function toArcAccountAssignment(dto: ArcAccountAssignmentDto): ArcAccountAssignment {
  if (!dto) { return null; }
  return {
    device: dto.device,
    user: dto.user,
    assignedUtc: dto.assignedUtc.toDate(),
  }
}

function toArcAccountAllocation(dto: ArcAccountAllocationDto): ArcAccountAllocation {
  return {
    allocationId: dto.id,
    arcAccountIdentifier: dto.arcAccountIdentifier,
    assignment: toArcAccountAssignment(dto.assignment),
    allocatedUtc: dto.allocatedUtc.toDate(),
  };
}

function toPlatformVersion(dto: PlatformVersionDto): PlatformVersion {
  return {
    versionId: dto.id,
    platform: dto.platform,
    versionName: dto.versionName,
    versionNumber: dto.versionNumber,
    downloadUrl: dto.downloadUrl || null,
    features: dto.features,
    fixes: dto.fixes,
    enhancements: dto.enhancements,
    addedUtc: dto.addedUtc.toDate(),
  };
}

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  private activateModuleFunction: HttpsCallable<any, any>;
  private uploadArcAccountAllocationsFunction: HttpsCallable<any, boolean>;
  private assignedArcAccountAllocationsFunction: HttpsCallable<ArcAccountAssignmentRequest, Page<ArcAccountAllocation>>;
  private alarmReceivingCentreIncidentsFunction: HttpsCallable<ArcIncidentsRequest, Page<ArcIncident>>;
  private alarmReceivingCentreTestSignalsFunction: HttpsCallable<ArcIncidentsRequest, Page<ArcTestSignal>>;
  private suspendModuleFunction: HttpsCallable<any, any>;
  private createArcFunction: HttpsCallable<any, any>;
  private updateArcFunction: HttpsCallable<any, any>;
  private deleteArcFunction: HttpsCallable<any, any>;
  private addArcIntegrationFunction: HttpsCallable<any, any>;
  private addPlatformVersionsFunction: HttpsCallable<AddPlatformVersions, PlatformVersion[]>;
  private getReleasesFunction: HttpsCallable<VersionsRequest, ElasticSearchPage>;
  private validateReleaseFunction: HttpsCallable<any, ReleaseValidation>;
  private removeArcIntegrationFunction: HttpsCallable<any, any>;
  private createAppRegistrationFunction: HttpsCallable<CreateAppRegistration, { appRegistrationId: string; }>;
  private updateAppRegistrationFunction: HttpsCallable<UpdateAppRegistration, boolean>;
  private deleteAppRegistrationFunction: HttpsCallable<{ appRegistrationId: string; }, boolean>;
  private createBackupScheduleFunction: HttpsCallable<any, any>;
  private deleteBackupScheduleFunction: HttpsCallable<any, any>;
  private runBackupScheduleFunction: HttpsCallable<any, any>;
  private backupsFunction: HttpsCallable<any, any>;

  public getModules(): Observable<Module[]> {
    const { collectionData, query, collection } = this.firestoreService;
    const toModule = (moduleDto: ModuleDto) => {
      return ({
        moduleId: moduleDto.id,
        name: moduleDto.name,
        code: moduleDto.code,
        active: moduleDto.active,
        startUtc: moduleDto.startUtc.toDate(),
        serverUpdatedUtc: moduleDto.serverUpdatedUtc.toDate()
      } as Module);
    };
    const modules$ = collectionData(query(
      collection(this.firestore, 'modules'))
    );
    return modules$.pipe(map(modules => modules.map(toModule)));
  }

  arcIncidents(request: ArcIncidentsRequest): Observable<Page<ArcIncident>> {
    return from(this.alarmReceivingCentreIncidentsFunction(request)).pipe(map(x => x.data));
  }

  arcTestSignals(request: ArcTestSignalsRequest): Observable<Page<ArcTestSignal>> {
    return from(this.alarmReceivingCentreTestSignalsFunction(request)).pipe(map(x => x.data));
  }

  public getBackupSchedules() {
    const { collectionData, query, collection, orderBy } = this.firestoreService;
    function toSchedule(dto: any): BackupSchedule {
      return {
        scheduleId: dto.id,
        description: dto.description,
        cronSchedule: dto.cronSchedule,
        backupCount: dto.backupCount,
        startUtc: dto.startUtc.toDate(),
        serverUpdatedUtc: dto.serverUpdatedUtc?.toDate()
      };
    }
    return collectionData(query(
      collection(this.firestore, 'backupSchedules'),
      orderBy('startUtc', 'desc'))
    ).pipe(map(x => x.map(toSchedule)));
  }

  public getArcs() {
    const { collectionData, query, collection, orderBy } = this.firestoreService;
    return collectionData(query(
      collection(this.firestore, 'alarmReceivingCentres'),
      orderBy('arcName', 'asc'))
    ).pipe(map(x => x.map(toArc)));
  }

  public getArc(arcId: string) {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `alarmReceivingCentres/${arcId}`)).pipe(map(toArc));
  }

  public getOrganisationsWithoutArcIntegration() {
    const { collectionData, query, where, collection, orderBy } = this.firestoreService;
    const toOrganisation: (organisationDto: any) => Organisation = (
      organisationDto: any,
    ) => {
      const organisation: Organisation = {
        organisationId: organisationDto.id,
        size: organisationDto.size,
        name: organisationDto.name,
        configuration: organisationDto.configuration ?? OrganisationConfiguration.default,
        roleCounts: organisationDto.roleCounts,
        activeUserCount: organisationDto.activeUserCount,
        totalUserCount: organisationDto.totalUserCount,
        arcIncidentCount: organisationDto.arcIncidentCount || 0,
        arcTestSignalCount: organisationDto.arcTestSignalCount || 0,
        activeCheckInCount: organisationDto.activeCheckInCount,
        totalCheckInCount: organisationDto.totalCheckInCount,
        lastCheckInUtc: organisationDto.lastCheckInUtc?.toDate() || new Date(0),
        liveLocationUserCount: organisationDto.liveLocationUserCount,
        liveLocationAssetCount: organisationDto.liveLocationAssetCount,
        liveLocationFacilityCount: organisationDto.liveLocationFacilityCount,
        sentMessageCount: organisationDto.sentMessageCount,
        liveAlertCount: organisationDto.liveAlertCount || 0,
        liveIndividualAlertCount: organisationDto.liveIndividualAlertCount || 0,
        liveAssetAlertCount: organisationDto.liveAssetAlertCount || 0,
        liveFacilityAlertCount: organisationDto.liveFacilityAlertCount || 0,
        lastAlertUpdate: organisationDto.lastAlertUpdate?.toDate() || new Date(0),
        lastLiveLocationUpdateUtc: organisationDto.lastLiveLocationUpdateUtc?.toDate() || new Date(0),
        membersUpdatedUtc: organisationDto.membersUpdatedUtc?.toDate() || new Date(0),
        lastDeviceUpdate: organisationDto.lastDeviceUpdate?.toDate() || new Date(0),
        ruleCount: organisationDto.ruleCount || 0,
        userCsvUploadCount: organisationDto.userCsvUploadCount || 0,
        licenseRoleLimits: { appUser: 0, userManager: 0, operations: 0 },
        headOfficeId: organisationDto.headOfficeId,
        startUtc: organisationDto.startUtc.toDate(),
        arcIntegration: organisationDto.arcIntegration,
        logo: organisationDto.logo || null,
        settings: organisationDto.settings,
      };
      return organisation;
    };
    return collectionData(query(
      collection(this.firestore, 'organisations'),
      where('arcIntegration', '==', null),
      orderBy('name', 'asc'),
    )).pipe(map(x => x.map(toOrganisation)));
  }

  public getArcIntegrations(arcId: string) {
    const { collectionData, query, collection, orderBy } = this.firestoreService;
    return collectionData(query(
      collection(this.firestore, `alarmReceivingCentres/${arcId}/integrations`),
      orderBy('name', 'asc'),
    )).pipe(map(x => x.map(toArcIntegration)));
  }

  public getAppRegistrations() {
    const { collectionData, query, collection, orderBy } = this.firestoreService;
    return collectionData(query(
      collection(this.firestore, 'multiTenantAppRegistrations'),
      orderBy('displayName', 'asc'),
    )).pipe(map(x => x.map(toAppRegistration)));
  }

  public getAppRegistration(appRegistrationId: string) {
    const { docData, doc } = this.firestoreService;
    return docData(
      doc(this.firestore, `multiTenantAppRegistrations/${appRegistrationId}`),
    ).pipe(map(toAppRegistration));
  }

  public getArcIntegration(arcId: string, organisationId: string) {
    const { docData, doc } = this.firestoreService;
    return docData(
      doc(this.firestore, `alarmReceivingCentres/${arcId}/integrations/${organisationId}`),
    ).pipe(map(toArcIntegration));
  }

  public getUnassignedArcAccounts(arcId: string, organisationId: string) {
    const { collectionData, query, collection, orderBy, where } = this.firestoreService;
    return collectionData(query(
      collection(this.firestore, `/alarmReceivingCentres/${arcId}/integrations/${organisationId}/accountAllocations`),
      where('assignment', '==', null),
      orderBy('arcAccountIdentifier', 'asc'),
    )).pipe(map(x => x.map(toArcAccountAllocation)));
  }

  constructor(
    functions: Functions,
    private firestore: Firestore,
    functionsService: FunctionsService,
    private firestoreService: FirestoreService,
  ) {
    const { httpsCallable } = functionsService;
    this.activateModuleFunction = httpsCallable(functions, 'activateModuleGen2');
    this.suspendModuleFunction = httpsCallable(functions, 'suspendModuleGen2');
    this.createArcFunction = httpsCallable(functions, 'createArcGen2');
    this.updateArcFunction = httpsCallable(functions, 'updateArcGen2');
    this.deleteArcFunction = httpsCallable(functions, 'deleteArcGen2');
    this.addArcIntegrationFunction = httpsCallable(functions, 'addArcIntegrationGen2');
    this.addPlatformVersionsFunction = httpsCallable(functions, 'addPlatformVersionsGen2');
    this.validateReleaseFunction = httpsCallable(functions, 'validateReleaseGen2');
    this.getReleasesFunction = httpsCallable(functions, 'getReleasesGen2');
    this.removeArcIntegrationFunction = httpsCallable(functions, 'removeArcIntegrationGen2');
    this.createBackupScheduleFunction = httpsCallable(functions, 'createBackupScheduleGen2');
    this.deleteBackupScheduleFunction = httpsCallable(functions, 'deleteBackupScheduleGen2');
    this.runBackupScheduleFunction = httpsCallable(functions, 'runBackupScheduleGen2');
    this.backupsFunction = httpsCallable(functions, 'backupsGen2');
    this.uploadArcAccountAllocationsFunction = httpsCallable(functions, 'uploadArcAccountAllocationsGen2');
    this.assignedArcAccountAllocationsFunction = httpsCallable(functions, 'assignedArcAccountAllocationsGen2');
    this.alarmReceivingCentreIncidentsFunction = httpsCallable(functions, 'alarmReceivingCentreIncidentsGen2');
    this.alarmReceivingCentreTestSignalsFunction = httpsCallable(functions, 'alarmReceivingCentreTestSignalsGen2');
    this.createAppRegistrationFunction = httpsCallable(functions, 'createAppRegistrationGen2');
    this.updateAppRegistrationFunction = httpsCallable(functions, 'updateAppRegistrationGen2');
    this.deleteAppRegistrationFunction = httpsCallable(functions, 'deleteAppRegistrationGen2');
  }

  public assignedArcAccountAllocations(request: ArcAccountAssignmentRequest) {
    return from(this.assignedArcAccountAllocationsFunction(request)).pipe(map(x => x.data));
  }

  public uploadArcAccountAllocations(arcId: string, organisationId: string, csvUpload: StorageFile) {
    return from(this.uploadArcAccountAllocationsFunction({ arcId, organisationId, csvUpload })).pipe(map(x => x.data));
  }

  public activateModule(moduleId: string): Observable<boolean> {
    return from(this.activateModuleFunction({ moduleId })).pipe(map(x => x.data));
  }

  public suspendModule(moduleId: string): Observable<boolean> {
    return from(this.suspendModuleFunction({ moduleId })).pipe(map(x => x.data));
  }

  public backups(request: BackupRequest): Observable<Page<Backup>> {
    return from(this.backupsFunction(request)).pipe(map(x => x.data));
  }

  public createArc(create: CreateArc): Observable<string> {
    return from(this.createArcFunction(create)).pipe(map(x => x.data));
  }

  public updateArc(update: UpdateArc): Observable<string> {
    return from(this.updateArcFunction(update)).pipe(map(x => x.data));
  }

  public deleteArc(arcId: string): Observable<string> {
    return from(this.deleteArcFunction({ arcId })).pipe(map(x => x.data));
  }

  public createAppRegistration(create: CreateAppRegistration): Observable<{ appRegistrationId: string; }> {
    return from(this.createAppRegistrationFunction(create)).pipe(map(x => x.data));
  }

  public updateAppRegistration(update: UpdateAppRegistration): Observable<boolean> {
    return from(this.updateAppRegistrationFunction(update)).pipe(map(x => x.data));
  }

  public deleteAppRegistration(appRegistrationId: string): Observable<boolean> {
    return from(this.deleteAppRegistrationFunction({ appRegistrationId })).pipe(map(x => x.data));
  }

  public addArcIntegration(add: AddArcIntegration): Observable<{ name: string; }> {
    return from(this.addArcIntegrationFunction(add)).pipe(map(x => x.data));
  }

  public addPlatformVersions(add: AddPlatformVersions): Observable<PlatformVersion[]> {
    return from(this.addPlatformVersionsFunction(add)).pipe(map(x => x.data));
  }

  public validateRelease(platforms: ReleasePlatform[], versionName: string, versionNumber: number): Observable<ReleaseValidation> {
    return from(this.validateReleaseFunction({ platforms, versionName, versionNumber })).pipe(map(x => x.data));
  }

  public getReleases(): Observable<PlatformVersion[]> {
    const { collectionData, query, collection, orderBy } = this.firestoreService;
    return collectionData(query(
      collection(this.firestore, 'releases'),
      orderBy('versionName', 'desc'),
    )).pipe(map(x => x.map(toPlatformVersion)));
  }

  public removeArcIntegration(remove: RemoveArcIntegration): Observable<{ name: string; }> {
    return from(this.removeArcIntegrationFunction(remove)).pipe(map(x => x.data));
  }

  public createBackupSchedule(create: CreateBackupSchedule): Observable<string> {
    return from(this.createBackupScheduleFunction(create)).pipe(map(x => x.data));
  }

  public deleteBackupSchedule(scheduleId: string): Observable<string> {
    return from(this.deleteBackupScheduleFunction({ scheduleId })).pipe(map(x => x.data));
  }

  public runBackupSchedule(scheduleId: string): Observable<string> {
    return from(this.runBackupScheduleFunction({ scheduleId })).pipe(map(x => x.data));
  }

  public getVersionTimestamp(): Observable<Date> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, 'system/0')).pipe(map(s => s.releasesUpdatedUtc?.toDate() || new Date(0)));
  }

  public searchForReleases = (request: VersionsRequest) => {
    return from(this.getReleasesFunction(request)).pipe(map(x => x.data));
  }
}

