import { AbstractControl, Validator } from '@angular/forms';
import * as phoneNumbers from 'libphonenumber-js';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PhoneNumberValidator implements Validator {

  validate(ctrl: AbstractControl) {
    const value = ctrl.value;
    if (!value) {
      return null;
    }

    const parsedNumber = phoneNumbers.parseNumber(value) as phoneNumbers.ParsedNumber;
    if (!phoneNumbers.isPossibleNumber(parsedNumber)) {
      return { invalidNumber: value };
    }

    return null;
  }
}
