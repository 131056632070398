import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import * as fromUsers from 'src/app/modules/console/features/users/users';
import { SpinnerOverlayService } from 'src/app/service/spinner-overlay.service';
import * as utilities from 'src/app/utilities';
import { SnackBarService } from '../../service/snack-bar.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'safe-user-suspension',
  templateUrl: './user-suspension.component.html',
  styleUrls: ['./user-suspension.component.scss']
})
export class UserSuspensionComponent implements OnInit, OnDestroy {

  @Input() organisationId: string;
  @Input() organisationMembershipId: string;
  @Input() displayName: string;

  constructor(
    private store: Store,
    private translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: SpinnerOverlayService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private snackBarService: SnackBarService,
  ) { }

  public isSuspended$: Observable<boolean>;
  public isWaiting$: Observable<boolean>;
  private suspendConfirmation$: Observable<string>;
  private resumeConfirmation$: Observable<string>;
  private destroy = new Subject<void>();

  ngOnInit(): void {
    this.suspendConfirmation$ = this.translate.get('user-profile.suspension.suspend-user-confirmation', { name: this.displayName });
    this.resumeConfirmation$ = this.translate.get('user-profile.suspension.resume-user-confirmation', { name: this.displayName });
    this.store.dispatch(fromUsers.getMemberRequest({ organisationMembershipId: this.organisationMembershipId }));
    utilities.subscribe(this.suspensionSucceededObservable(), this.destroy, () => this.snackBarService.show(
      'personnel.suspend-result.success', 'personnel.suspend-result.ok', { values: { value: this.displayName } }));
    utilities.subscribe(this.suspensionFailedObservable(), this.destroy, () => this.snackBarService.show(
      'personnel.suspend-result.failure', 'personnel.suspend-result.ok', { values: { value: this.displayName } }));
    utilities.subscribe(this.resumptionSucceededObservable(), this.destroy, () => this.snackBarService.show(
      'personnel.resume-result.success', 'personnel.resume-result.ok', { values: { value: this.displayName } }));
    utilities.subscribe(this.resumptionFailedObservable(), this.destroy, () => this.snackBarService.show(
      'personnel.resume-result.failure', 'personnel.resume-result.ok', { values: { value: this.displayName } }));
    utilities.subscribe(this.suspensionCompleteObservable(), this.destroy, () => this.store.dispatch(fromUsers.suspendMemberReset()));
    utilities.subscribe(this.resumptionCompleteObservable(), this.destroy, () => this.store.dispatch(fromUsers.resumeMemberReset()));
    this.isSuspended$ = this.getSuspendedObservable();
    this.isWaiting$ = this.getWaitingObservable();
    this.showOverlayIfWaiting();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  toggleUserSuspension() {
    this.isSuspended$
      .pipe(take(1))
      .subscribe(isSuspended => {
        if (isSuspended) {
          this.confirmWithMessage(this.resumeConfirmation$, this.resumeMembership);
        } else {
          this.confirmWithMessage(this.suspendConfirmation$, this.suspendMembership);
        }
      });

    return false;
  }

  private showOverlayIfWaiting() {
    this.isWaiting$.pipe(takeUntil(this.destroy)).subscribe(working => working ? this.overlayService.show() : this.overlayService.hide());
  }

  private getWaitingObservable() {
    const waitingForSuspension$ = this.store.pipe(
      select(fromUsers.suspendMemberState),
      map(s => s === 'pending'));

    const waitingForResumption$ = this.store.pipe(
      select(fromUsers.resumeMemberState),
      map(s => s === 'pending'));

    return combineLatest([waitingForSuspension$, waitingForResumption$]).pipe(
      map(([isSuspending, isResuming]) => isSuspending || isResuming)
    );
  }

  private getSuspendedObservable(): Observable<boolean> {
    return this.store.pipe(
      select(fromUsers.getMember(this.organisationMembershipId)),
      filter(m => !!m),
      map(m => !!m.suspension));
  }

  private resumeMembership = () => {
    const resumeMemberRequest = fromUsers.resumeMemberRequest({
      organisationId: this.organisationId,
      organisationMembershipId: this.organisationMembershipId
    });
    return this.store.dispatch(resumeMemberRequest);
  }

  private suspendMembership = () => {
    const suspendMemberRequest = fromUsers.suspendMemberRequest({
      organisationId: this.organisationId,
      organisationMembershipId: this.organisationMembershipId
    });
    this.store.dispatch(suspendMemberRequest);
  }

  private confirmWithMessage = (message: Observable<string>, onConfirm: () => void) => {
    message.pipe(take(1)).subscribe(data => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });
      dialogRef.afterClosed()
        .pipe(filter(x => x), take(1))
        .subscribe(onConfirm);
    });
  }

  private suspensionSucceededObservable = () => this.store.pipe(
    select(fromUsers.memberSuspended),
    filter(s => s),
    tap(() => this.ngZone.run(() => this.cdr.markForCheck()))
  )

  private suspensionFailedObservable = () => this.store.pipe(
    select(fromUsers.memberSuspensionFailed),
    filter(s => s),
    tap(() => this.ngZone.run(() => this.cdr.markForCheck()))
  )

  private suspensionCompleteObservable = () => this.store.pipe(
    select(fromUsers.memberSuspensionCompleted),
    filter(s => s),
    tap(() => this.ngZone.run(() => this.cdr.markForCheck()))
  )

  private resumptionSucceededObservable = () => this.store.pipe(
    select(fromUsers.memberResumed),
    filter(s => s),
    tap(() => this.ngZone.run(() => this.cdr.markForCheck()))
  )

  private resumptionFailedObservable = () => this.store.pipe(
    select(fromUsers.memberResumptionFailed),
    filter(s => s),
    tap(() => this.ngZone.run(() => this.cdr.markForCheck()))
  )

  private resumptionCompleteObservable = () => this.store.pipe(
    select(fromUsers.memberResumptionCompleted),
    filter(s => s),
    tap(() => this.ngZone.run(() => this.cdr.markForCheck()))
  )
}
