import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CronSchedule, UnitOfTime } from 'src/app/models/schedule';

@Component({
  selector: 'safe-multiple-times-schedule',
  templateUrl: './multiple-times-schedule.component.html',
  styleUrls: ['./multiple-times-schedule.component.scss']
})
export class MultipleTimesScheduleComponent {
  @Output() selected = new EventEmitter<CronSchedule>();
  public amountOfTime: string;
  public unitOfTime: UnitOfTime = 'minutes';
  public scope: 'all day' | 'restricted' = 'all day';
  public from: string;
  public to: string;
  public isValid = false;
  private schedule: string;

  public validateInput() {
    const isIntervalValid = !!this.amountOfTime && !!this.unitOfTime;
    const isScopeValid = this.scope === 'all day' || this.scope === 'restricted' && !!this.from && !!this.to && (this.to > this.from);
    this.isValid = isIntervalValid && isScopeValid;

    if (!this.isValid) {
      this.schedule = null;
      return;
    }

    this.schedule = this.scope === 'all day' ?
      `every ${this.amountOfTime} ${this.unitOfTime} synchronized` :
      `every ${this.amountOfTime} ${this.unitOfTime} from ${this.from} to ${this.to}`;
  }

  public resetAmountOfTime() {
    this.amountOfTime = null;
  }

  public updateSelected() {
    function getTimeOfDay(time: string) {
      const [hours, minutes] = time.split(':');
      return { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) };
    }
    this.selected.emit({
      description: {
        type: 'interval',
        interval: parseInt(this.amountOfTime, 10),
        unitOfTime: this.unitOfTime,
        scope: this.scope === 'restricted' ? { from: getTimeOfDay(this.from), to: getTimeOfDay(this.to) } : null
      },
      value: this.schedule
    });
  }

}
