<div class="mat-line">
  <safe-highlight-text class="text highlight" *ngIf="content else noHighlight" [text]="content"></safe-highlight-text>
  <ng-template #noHighlight>
    <div class="text">{{ text }}</div>
  </ng-template>
  <div (click)="$event.stopPropagation()">
    <button mat-icon-button class="copy-button" [cdkCopyToClipboard]="text" (cdkCopyToClipboardCopied)="copied($event)" [matTooltip]="'copy-to-clipboard.tooltip' | translate">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
</div>
