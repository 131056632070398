import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as fromConnect from 'src/app/features/connect/connect';
import * as validation from 'src/app/constants/validation';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { TenantState } from 'src/app/shared/custom/models/domains';

@Component({
  selector: 'safe-enter-password',
  templateUrl: './enter-password.component.html',
  styleUrls: ['./enter-password.component.scss']
})
export class EnterPasswordComponent implements OnInit {
  passwordRestriction: any;
  public appName$: Observable<{value: string}>;
  public isWorking$: Observable<boolean>;
  public hide = true;

  @Output() passwordResetRequested = new EventEmitter();

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    translateService: TranslateService,
  ) {
    this.appName$ = translateService.get(environment.dashboardTitle).pipe(map((x: string) => ({ value: x })));
    this.passwordRestriction = {
      minLength: { value: validation.passwordLength.min },
      maxLength: { value: validation.passwordLength.max }
    };
  }

  public enterPasswordFormGroup: FormGroup = this.formBuilder.group({
    password: ['', [Validators.required]]
  });

  @Input() email: string;
  @Input() signInColor: string;
  @Input() tenantState: TenantState;
  @Output() previous = new EventEmitter();

  ngOnInit(): void {
    this.isWorking$ = this.store.pipe(select(fromConnect.selectIsWorking));
  }

  enterPassword = () => {
    const { store, email, enterPasswordFormGroup, tenantState } = this;
    this.route.params.pipe(take(1)).subscribe(params => {
      const path = params.path || '';
      store.dispatch(fromConnect.firebaseSignInRequest({
        email,
        password: enterPasswordFormGroup.value.password,
        cloudTenantId: tenantState.cloudTenantId,
        path,
      }));
    });
  }
}
