import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CronSchedule } from 'src/app/models/schedule';
import { customInputComponentMixinBase } from 'src/app/shared/custom/components/custom-input-component';

@Component({
  selector: 'safe-schedule-input',
  templateUrl: './schedule-input.component.html',
  styleUrls: ['./schedule-input.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: ScheduleInputComponent }]})
export class ScheduleInputComponent
extends customInputComponentMixinBase
implements OnDestroy, DoCheck,
  ControlValueAccessor, MatFormFieldControl<CronSchedule> {
  static nextId = 0;
  private destroy = new Subject<void>();
  private _disabled = false;
  private _required = false;
  private _value: CronSchedule = null;
  private _placeholder = '';
  focused = false;
  private onTouched: any;

  @HostBinding() id = `safe-schedule-input-${ScheduleInputComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Input() errorStateMatcher: ErrorStateMatcher;

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  stateChanges: Subject<void>;
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() { return this._required; }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): CronSchedule { return this._value; }
  set value(action: CronSchedule) {
    this._value = action;
    this.stateChanges.next();
  }

  @Input()
  get placeholder() { return this._placeholder; }
  set placeholder(value) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get empty() {
    return !(this._value);
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !!this._value;
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(obj: any): void {
    const val = obj as CronSchedule;
    this.value = val;
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onContainerClick(_: MouseEvent) { }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
}
