import { Pipe, PipeTransform } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import MessageFormat from 'messageformat';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import {
  CronSchedule, DailyDescription, DaysDescription, IntervalDescription
} from 'src/app/models/schedule';

@Pipe({
  name: 'scheduleText'
})
export class ScheduleTextPipe implements PipeTransform {

  constructor(
    private translate: TranslateService,
  ) { }

  transform(cronSchedule: CronSchedule): Observable<string> {
    return this.translate.onLangChange.asObservable().pipe(
      startWith({ lang: this.translate.currentLang, translations: null }), switchMap((change: LangChangeEvent) => {
      const currentLang = change.lang;
      const messageFormat = new MessageFormat(currentLang);
      const numberFormat = Intl.NumberFormat('en', { minimumIntegerDigits: 2 });
      const dateFormat = Intl.DateTimeFormat(currentLang, { hour: 'numeric', minute: 'numeric' });
      function formatTime(hours: number, minutes: number) {
        return dateFormat.format(
          new Date(`01/01/1900 ${numberFormat.format(hours)}:${numberFormat.format(minutes)}`)
        );
      }
      if (this.isInterval(cronSchedule)) {
        const description = this.intervalDescription(cronSchedule);
        if (!description.scope) {
          const key = {
            hours: 'scheduling.cron-schedule.interval-hours',
            minutes: 'scheduling.cron-schedule.interval-minutes',
          };
          return this.translate.get(key[description.unitOfTime]).pipe(map(x => {
            return messageFormat.compile(x)({ [description.unitOfTime]: description.interval });
          }));
        } else {
          const key = {
            hours: 'scheduling.cron-schedule.scoped-interval-hours',
            minutes: 'scheduling.cron-schedule.scoped-interval-minutes',
          };
          return this.translate.get(key[description.unitOfTime], {
            from: formatTime(description.scope.from.hours, description.scope.from.minutes),
            to: formatTime(description.scope.to.hours, description.scope.to.minutes),
          }).pipe(map(x => {
            return messageFormat.compile(x)({ [description.unitOfTime]: description.interval });
          }));
        }
      } else if (this.isMultipleDays(cronSchedule)) {
        const description = this.multipleDaysDescription(cronSchedule);
        return combineLatest(description.days.map(d => this.translate.get(`scheduling.cron-schedule.${d}`)))
          .pipe(switchMap((days: string[]) => this.translate.get('scheduling.cron-schedule.multiple-days',
           { days: days.join(', '), time: formatTime(description.time.hours, description.time.minutes) })));
      } else if (this.isDaily(cronSchedule)) {
        const description = this.dailyDescription(cronSchedule);
        return this.translate.get('scheduling.cron-schedule.daily', {
          time: formatTime(description.time.hours, description.time.minutes)
        });
      } else {
        return of('').pipe(take(1));
      }
    }));
  }

  isInterval(cronSchedule: CronSchedule): boolean {
    const description: any = cronSchedule?.description;
    return !!description?.unitOfTime;
  }

  isMultipleDays(cronSchedule: CronSchedule): boolean {
    const description: any = cronSchedule?.description;
    return !!description?.days;
  }

  isDaily(cronSchedule: CronSchedule): boolean {
    const description: any = cronSchedule?.description;
    return !!description?.time && !description?.days;
  }

  intervalDescription(cronSchedule: CronSchedule) {
    return cronSchedule.description as IntervalDescription;
  }

  multipleDaysDescription(cronSchedule: CronSchedule) {
    return cronSchedule.description as DaysDescription;
  }

  dailyDescription(cronSchedule: CronSchedule) {
    return cronSchedule.description as DailyDescription;
  }
}
