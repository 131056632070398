import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CronSchedule, DayOfWeek } from 'src/app/models/schedule';

@Component({
  selector: 'safe-specific-days-schedule',
  templateUrl: './specific-days-schedule.component.html',
  styleUrls: ['./specific-days-schedule.component.scss']
})
export class SpecificDaysScheduleComponent {
  @Output() selected = new EventEmitter<CronSchedule>();
  public isValid = false;
  private _isMonday = false;
  private _isTuesday = false;
  private _isWednesday = false;
  private _isThursday = false;
  private _isFriday = false;
  private _isSaturday = false;
  private _isSunday = false;
  private hoursMinutesRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;

  public get isMonday(): boolean {
    return this._isMonday;
  }
  public set isMonday(value: boolean) {
    this._isMonday = value;
    this.validateInput();
  }

  public get isTuesday(): boolean {
    return this._isTuesday;
  }
  public set isTuesday(value: boolean) {
    this._isTuesday = value;
    this.validateInput();
  }

  public get isWednesday(): boolean {
    return this._isWednesday;
  }
  public set isWednesday(value: boolean) {
    this._isWednesday = value;
    this.validateInput();
  }

  public get isThursday(): boolean {
    return this._isThursday;
  }
  public set isThursday(value: boolean) {
    this._isThursday = value;
    this.validateInput();
  }

  public get isFriday(): boolean {
    return this._isFriday;
  }
  public set isFriday(value: boolean) {
    this._isFriday = value;
    this.validateInput();
  }

  public get isSaturday(): boolean {
    return this._isSaturday;
  }
  public set isSaturday(value: boolean) {
    this._isSaturday = value;
    this.validateInput();
  }

  public get isSunday(): boolean {
    return this._isSunday;
  }
  public set isSunday(value: boolean) {
    this._isSunday = value;
    this.validateInput();
  }

  public timeOfDay: string;

  public updateSelected() {
    const days = this.getDays();
    const [hours, minutes] = this.timeOfDay.split(':');
    this.selected.emit({
      value: `every ${days} ${this.timeOfDay}`,
      description: {
        type: 'days',
        days: [
          ...['mon' as DayOfWeek].filter(_ => this.isMonday),
          ...['tue' as DayOfWeek].filter(_ => this.isTuesday),
          ...['wed' as DayOfWeek].filter(_ => this.isWednesday),
          ...['thur' as DayOfWeek].filter(_ => this.isThursday),
          ...['fri' as DayOfWeek].filter(_ => this.isFriday),
          ...['sat' as DayOfWeek].filter(_ => this.isSaturday),
          ...['sun' as DayOfWeek].filter(_ => this.isSunday)
        ],
        time: {
          hours: parseInt(hours, 10),
          minutes: parseInt(minutes, 10),
        }
      }
    });
  }

  public validateInput = () => {
    const isDayEntered = this.isMonday || this.isTuesday || this.isWednesday ||
      this.isThursday || this.isFriday || this.isSaturday || this.isSunday;
    const isTimeEntered = !!this.timeOfDay && this.hoursMinutesRegex.test(this.timeOfDay);
    this.isValid = isDayEntered && isTimeEntered;
  }

  private getDays = () => {
    return [
      this.isMonday ? 'monday' : '',
      this.isTuesday ? 'tuesday' : '',
      this.isWednesday ? 'wednesday' : '',
      this.isThursday ? 'thursday' : '',
      this.isFriday ? 'friday' : '',
      this.isSaturday ? 'saturday' : '',
      this.isSunday ? 'sunday' : ''
    ].filter(d => d).join(',');
  }
}
