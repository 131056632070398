<div class="password-reset-emails" fxLayout="column">
  <ng-container *ngIf="emailsDataSource.loading$ | async; then loading else table"></ng-container>
  <ng-template #loading>
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 0">
      <safe-spinner></safe-spinner>
    </div>
  </ng-template>
  <ng-template #table>
    <table mat-table class="password-reset-emails-table mat-elevation-z8" [dataSource]="emailsDataSource"
      [hidden]="emailsDataSource?.loading$ | async">
      <ng-container matColumnDef="sent">
        <th mat-header-cell *matHeaderCellDef>{{ 'password-reset-emails-table.headers.sent' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.sentUtc | amLocale:currentLocale | amDateFormat: 'LLLL' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="statusCode">
        <th mat-header-cell *matHeaderCellDef>{{ 'password-reset-emails-table.headers.status-code' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.statusCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="response">
        <th mat-header-cell *matHeaderCellDef>{{ 'password-reset-emails-table.headers.response' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!!element.responseBody else empty">{{ element.responseBody }}</span>
          <ng-template #empty>{{ 'password-reset-emails-table.empty' | translate }}</ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator showFirstLastButtons="true" (page)="onPage($event)" [length]="totalCount$ | async"
      [pageSize]="pageSize$ | async" [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </ng-template>
</div>
