import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfile from './profile.reducer';
import { OrganisationRole } from 'src/app/models/organisation';
import { EmailActionCode } from '../../service/users.service';

const actionCodes = {
  [OrganisationRole.userManager]: 'web',
  [OrganisationRole.operations]: 'web',
  [OrganisationRole.support]: 'web',
  [OrganisationRole.supportManager]: 'web',
  [OrganisationRole.appUser]: 'mobile'
};

export const selectProfileState = createFeatureSelector<fromProfile.State>(
  fromProfile.profileFeatureKey
);

export const displayName = createSelector(selectProfileState, state =>
  state.profile ? `${state.profile.givenName} ${state.profile.familyName}` : null);
export const givenName = createSelector(selectProfileState, state => state.profile ? state.profile.givenName : null);
export const familyName = createSelector(selectProfileState, state => state.profile ? state.profile.familyName : null);
export const phoneNumber = createSelector(selectProfileState, state => state.profile ? state.profile.phoneNumber : null);
export const email = createSelector(selectProfileState, state => state.profile ? state.profile.email : null);
export const membershipId = createSelector(selectProfileState, state =>
  state.profile ? state.profile.organisationMembershipId : null
);
export const passwordResetEmailCount = createSelector(selectProfileState, state =>
  state.profile ? state.profile.passwordResetEmailCount : 0
);
export const pushNotificationEventCount = createSelector(selectProfileState, state =>
  state.profile ? state.profile.pushNotificationEventCount : 0
);
export const organisationId = createSelector(selectProfileState, state =>
  state.profile ? state.profile.organisationId : null
);
export const userDevices = createSelector(selectProfileState, state => state.userDevices);
export const isGettingDevices = createSelector(selectProfileState, state => state.getDevicesState === 'pending');
export const removingUserDevices = createSelector(selectProfileState, state => state.removingUserDevices);
export const removedUserDevice = createSelector(selectProfileState, state => state.removedUserDevice);
export const failedToRemoveUserDevice = createSelector(selectProfileState, state => state.failedToRemoveUserDevice);
export const accountId = createSelector(
  selectProfileState, state => state.profile ? state.profile.accountId : null
);
export const roles = createSelector(selectProfileState, state => state.profile ? state.profile.roles : []);
export const apps = createSelector(selectProfileState, state =>
  state.profile ? [...new Set(state.profile.roles.map(r => actionCodes[r] as EmailActionCode))].sort() : []);
export const isSettingUserRole = createSelector(selectProfileState, state => state.setUserRoleState === 'pending');
export const isGettingOrganisationUser = createSelector(selectProfileState, state => state.getOrganisationUserState === 'pending');
export const isSendingPasswordResetEmail = createSelector(
  selectProfileState, state => state.sendPasswordResetEmailState === 'pending');
export const isSavingProfile = createSelector(
  selectProfileState, state => state.saveProfileState === 'pending');
