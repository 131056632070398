<safe-theme-emitter></safe-theme-emitter>
<form class="create-new-user-form wave" [formGroup]="addUserFormGroup" (ngSubmit)="createNewUser()">
  <mat-accordion>
    <mat-expansion-panel formGroupName="user" (opened)="userPanelOpened()" (closed)="userPanelClosed()">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'create-new-user.single-user' | translate }}</mat-panel-title>
        <mat-panel-description>{{ 'create-new-user.add-a-single-user' | translate }}</mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field class="user-email full-width" *ngIf="hasDomains$ | async">
        <safe-domain-bound-email formControlName="email" [domains]="domains$ | async"
          [placeholder]="'domain-bound-email.email-username' | translate" (ngModelChange)="onEmailChanged($event)">
        </safe-domain-bound-email>
        <mat-label>{{ 'create-new-user.email' | translate }}</mat-label>
        <mat-icon *ngIf="!addUserFormGroup?.get('user.email').pending" matSuffix color="primary">email</mat-icon>
        <div class="email-wave" matSuffix *ngIf="addUserFormGroup?.get('user.email').pending">
          <safe-wave scale="0.5"></safe-wave>
        </div>
        <mat-hint *ngIf="email">{{ email }}</mat-hint>
        <mat-error class="email-required" *ngIf="addUserFormGroup?.get('user.email').hasError('required')">
          {{ 'create-new-user.email-error.required' | translate }}
        </mat-error>
        <mat-error *ngIf="addUserFormGroup?.get('user.email').hasError('pattern')">
          {{ 'create-new-user.email-error.invalid' | translate }}
        </mat-error>
        <mat-error *ngIf="addUserFormGroup?.get('user.email').hasError('userAlreadyExists')">
          {{ 'create-new-user.email-error.already-exists' | translate: { value: addUserFormGroup?.get('user').controls.email?.errors?.userAlreadyExists } }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="full-width phone-number">
        <mat-label>{{ 'create-new-user.phone-number' | translate }}</mat-label>
        <input class="phone-number" matInput formControlName="phoneNumber" />
        <mat-icon matSuffix color="primary">dialpad</mat-icon>
        <mat-hint>
          {{ 'create-new-user.international-format' | translate }}
        </mat-hint>
        <mat-error class="phone-number-error"
          *ngIf="addUserFormGroup.get('user.phoneNumber').hasError('invalidNumber')">
          {{ 'create-new-user.phone-number-error.invalid-number' | translate }}
        </mat-error>
        <mat-error class="phone-number-required"
          *ngIf="addUserFormGroup.get('user.phoneNumber').hasError('required')">
          {{ 'create-new-user.phone-number-error.required' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="user-first-name full-width">
        <mat-label>{{ 'create-new-user.first-name' | translate }}</mat-label>
        <input matInput class="first-name" formControlName="firstName" />
        <mat-icon matSuffix>person_outline</mat-icon>
        <mat-error class="first-name-required" *ngIf="addUserFormGroup?.get('user.firstName').hasError('required')">
          {{ 'create-new-user.first-name-error.required' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="user-last-name full-width">
        <mat-label>{{ 'create-new-user.last-name' | translate }}</mat-label>
        <input matInput class="last-name" formControlName="lastName" />
        <mat-icon matSuffix>person</mat-icon>
        <mat-error class="last-name-required" *ngIf="addUserFormGroup?.get('user.lastName').hasError('required')">
          {{ 'create-new-user.last-name-error.required' | translate }}
        </mat-error>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel formGroupName="users" (opened)="csvPanelOpened()" (closed)="csvPanelClosed()">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'create-new-user.csv-upload' | translate }}</mat-panel-title>
        <mat-panel-description>{{ 'create-new-user.upload-users-from-csv' | translate }}</mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field class="upload-csv-file full-width">
        <mat-label>{{ 'create-new-user.csv-file' | translate }}</mat-label>
        <safe-file-upload formControlName="storageFile" [validation]="isCsv" [placeholder]="'create-new-user.drop-csv-file-here' | translate"></safe-file-upload>
      </mat-form-field>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="create-new-user mat-line space-between">
    <div class="submit-wave" *ngIf="isCreatingNewUser$ | async else createButton">
      <safe-spinner class="wave-12 creating-new-user"></safe-spinner>
    </div>
    <ng-template #createButton>
      <button mat-raised-button class="create-new-user" *ngIf="(isCreatingNewUser$ | async) === false" color="primary"
        [disabled]="!canCreate()">
        <span>{{ action | translate }}</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </ng-template>
    <button mat-stroked-button type="button" tabindex="-1" class="cancel-create-user"
      *ngIf="(isCreatingNewUser$ | async) === false" (click)="cancelCreateNewUser()">
      {{ 'create-new-user.cancel' | translate }}
    </button>
  </div>
</form>
