<div matLine fxLayoutAlign="space-between center" fxLayout="row">
  <div fxFlex="1 1 20em">
    <input class="input" [class.floating]="shouldLabelFloat" matInput autocorrect="off" autocapitalize="none" (blur)="onBlur()" [value]="username" (input)="onUsernameTyped($event)" (change)="onUsernameChanged($event)"
      type="text" [placeholder]="placeholder" [disabled]="disabled" autocomplete="none" />
  </div>
  <div>&#64;</div>
  <div class="choose-domain" fxFlex="1 1 100%">
    <mat-select (selectionChange)="onDomainChanged($event)" [placeholder]="'domain-bound-email.choose-a-domain' | translate" [required]="required">
      <mat-option *ngFor="let domain of domains" [value]="domain" class="sign-up-email-domain">
        {{ domain }}
      </mat-option>
    </mat-select>
  </div>
</div>
