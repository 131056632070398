import { Action, createReducer, on } from '@ngrx/store';
import * as AccountActions from './account.actions';
import { Account } from 'src/app/models/accounts';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import { Facility, OrganisationHeader } from 'src/app/models/organisation';

export const accountFeatureKey = 'account';

export interface State {
  signOutState: RequestState;
  getAccountState: RequestState;
  getAccountByIdState: RequestState;
  getOrganisationsState: RequestState;
  account: Account;
  accounts: { [accountId: string]: Account };
  organisations: OrganisationHeader[];
  currentOrganisationId: string;
  getHeadOfficeState: RequestState;
  headOffice: Facility;
}

export const initialState: State = {
  signOutState: initial,
  getAccountState: initial,
  getAccountByIdState: initial,
  getOrganisationsState: initial,
  account: null,
  accounts: {},
  organisations: [],
  currentOrganisationId: null,
  getHeadOfficeState: initial,
  headOffice: null,
};

const accountReducer = createReducer(
  initialState,
  on(AccountActions.signOutRequest, state => ({
    ...state,
    signOutState: pending })),
  on(AccountActions.signOutSuccess, (state, action) => ({
    ...state,
    signOutState: ok
  })),
  on(AccountActions.signOutFailure, (state, action) => ({
    ...state,
    signOutState: error
  })),
  on(AccountActions.getAccountByUidRequest, state => ({
    ...state,
    getAccountState: pending
  })),
  on(AccountActions.getAccountByUidSuccess, (state, action) => ({
    ...state,
    account: action.account,
    getAccountState: ok
  })),
  on(AccountActions.getAccountByUidFailure, (state, action) => ({
    ...state,
    getAccountState: error
  })),
  on(AccountActions.getOrganisationsRequest, state => ({
    ...state,
    getOrganisationsState: pending
  })),
  on(AccountActions.getOrganisationsSuccess, (state, action) => ({
    ...state,
    organisations: action.organisations,
    getOrganisationsState: ok
  })),
  on(AccountActions.getOrganisationsFailure, (state, action) => ({
    ...state,
    getOrganisationsState: error
  })),
  on(AccountActions.setCurrentOrganisationId, (state, action) => ({
    ...state,
    currentOrganisationId: action.organisationId,
  })),
  on(AccountActions.getHeadOfficeRequest, (state, action) => ({
    ...state,
    getHeadOfficeState: pending
  })),
  on(AccountActions.getHeadOfficeSuccess, (state, action) => ({
    ...state,
    headOffice: action.headOffice,
    getHeadOfficeState: ok
  })),
  on(AccountActions.getHeadOfficeFailure, (state, action) => ({
    ...state,
    headOffice: null,
    getHeadOfficeState: error
  })),
  on(AccountActions.getAccountByIdRequest, (state, action) => ({
    ...state,
    getAccountByIdState: pending
  })),
  on(AccountActions.getAccountByIdSuccess, (state, action) => ({
    ...state,
    getAccountByIdState: ok,
    accounts: { ...state.accounts, [action.account.accountId]: action.account }
  })),
  on(AccountActions.getAccountByIdFailure, (state, action) => ({
    ...state,
    getAccountByIdState: error
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return accountReducer(state, action);
}
