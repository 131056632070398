import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import * as fromAccount from './account.reducer';

const passwordResetEmailCounts: { [accountId: string]: MemoizedSelector<object, number, DefaultProjectorFn<number>> } = {};
const pushNotificationEventCounts: { [accountId: string]: MemoizedSelector<object, number, DefaultProjectorFn<number>> } = {};

export const selectAccountState = createFeatureSelector<fromAccount.State>(fromAccount.accountFeatureKey);
export const isSigningOut = createSelector(selectAccountState, state => state.signOutState === 'pending');
export const selectAccountId = createSelector(selectAccountState, state => {
  return state.account?.accountId || '';
});
export const accountDomain = createSelector(selectAccountState, state => {
  return state.account?.domain || '';
});
export const localTenantId = createSelector(selectAccountState, state => {
  return state.account?.localTenantId || null;
});
export const selectCurrentOrganisationId = createSelector(selectAccountState, state => state.currentOrganisationId);
export const organisations = createSelector(selectAccountState, state => state.organisations);
export const organisationName = createSelector(selectAccountState, state => {
  const organisation = (state.organisations || []).find(o => o.organisationId === state.currentOrganisationId);
  return organisation ? organisation.name : '';
});
export const membershipId = createSelector(selectAccountState, state => {
  const organisation = (state.organisations || []).find(o => o.organisationId === state.currentOrganisationId);
  return organisation ? organisation.organisationMembershipId : '';
});
export const hasHeadOffice = createSelector(selectAccountState, state => !!state.headOffice);
export const headOfficeAddress = createSelector(selectAccountState, state =>
  state.headOffice ? state.headOffice.place.address : null);
export const headOfficeLocation = createSelector(selectAccountState, state =>
  state.headOffice ? state.headOffice.place.location : null);
export const headOfficeId = createSelector(selectAccountState, state =>
  state.headOffice ? state.headOffice.id : null);
export function getPasswordResetEmailCount(userAccountId: string) {
  function addSelector() {
    const selector = createSelector(selectAccountState, state => state?.accounts[userAccountId]?.passwordResetEmailCount);
    passwordResetEmailCounts[userAccountId] = selector;
    return selector;
  }
  return passwordResetEmailCounts[userAccountId] || addSelector();
}
export function getPushNotificationEventCount(userAccountId: string) {
  function addSelector() {
    const selector = createSelector(selectAccountState, state => state?.accounts[userAccountId]?.pushNotificationEventCount);
    pushNotificationEventCounts[userAccountId] = selector;
    return selector;
  }
  return pushNotificationEventCounts[userAccountId] || addSelector();
}
