import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ScheduleDto } from '../data/schedule-dto';
import { SystemHealthAggregateDto } from '../data/system-health-aggregate-dto';
import { NewSchedule, HealthCheckSchedule } from '../models/schedule';
import { SystemHealthAggregate } from '../models/system-health-aggregate';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { Auth } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { AuthService } from 'src/app/shared/custom/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SystemHealthService {
  private executeSystemHealthCommandFunction: HttpsCallable<any, any>;
  private executeSystemHealthRepairScriptFunction: HttpsCallable<any, any>;
  private createSystemHealthScheduleFunction: HttpsCallable<any, any>;
  private updateSystemHealthScheduleFunction: HttpsCallable<any, any>;
  private deleteSystemHealthScheduleFunction: HttpsCallable<any, any>;

  constructor(
    private http: HttpClient,
    functions: Functions,
    private auth: Auth,
    private firestore: Firestore,
    functionsService: FunctionsService,
    private authService: AuthService,
    private firestoreService: FirestoreService,
  ) {
    const { httpsCallable } = functionsService;
    this.executeSystemHealthCommandFunction = httpsCallable(functions, 'executeSystemHealthCommandGen2');
    this.executeSystemHealthRepairScriptFunction = httpsCallable(functions, 'executeSystemHealthRepairScriptGen2');
    this.createSystemHealthScheduleFunction = httpsCallable(functions, 'createSystemHealthScheduleGen2');
    this.updateSystemHealthScheduleFunction = httpsCallable(functions, 'updateSystemHealthScheduleGen2');
    this.deleteSystemHealthScheduleFunction = httpsCallable(functions, 'deleteSystemHealthScheduleGen2');
  }

  public getSystemHealthByDay(iso8601Date: string): Observable<SystemHealthAggregate[]> {
    const { collectionData, query, collection } = this.firestoreService;
    const aggregatesByDay = `healthDataByDay/${iso8601Date}/aggregates`;
    const aggregates$ = collectionData(query(
      collection(this.firestore, aggregatesByDay, )
    ), { idField: 'name' });
    const toSystemHealthAggregate = (dto: SystemHealthAggregateDto & { name: string }): SystemHealthAggregate => ({
      name: dto.name,
      createdUtc: dto.createdUtc,
      updatedUtc: dto.updatedUtc,
      status: dto.status,
      latestData: dto.latestData
    });

    return aggregates$.pipe(map(f => f.map(toSystemHealthAggregate)));
  }

  public listSystemHealthCommands(): Observable<string[]> {
    const { idToken } = this.authService;
    return idToken(this.auth).pipe(switchMap(token =>
      this.http.get(`${environment.appEngineUrl}system-health/healthChecks`, {
        responseType: 'text',
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    ).pipe(map(content => JSON.parse(content)))));
  }

  public listSystemHealthRepairScripts(): Observable<string[]> {
    const { idToken } = this.authService;
    return idToken(this.auth).pipe(switchMap(token =>
      this.http.get(`${environment.appEngineUrl}system-health/repairScripts`, {
        responseType: 'text',
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    ).pipe(map(content => JSON.parse(content)))));
  }

  public listSchedules(): Observable<HealthCheckSchedule[]> {
    const { collectionData, query, collection, orderBy } = this.firestoreService;
    const toSchedule = (dto: ScheduleDto): HealthCheckSchedule => ({
      scheduleId: dto.id,
      name: dto.name,
      description: dto.description,
      cronSchedule: dto.cronSchedule,
      commands: dto.commands
    });
    return collectionData(query(
      collection(this.firestore, 'healthSchedules'),
      orderBy('name', 'asc'))
    ).pipe(map((dtos: ScheduleDto[]) => dtos.map(toSchedule)));
  }

  public executeSystemHealthCommand(command: string): Observable<any> {
    return from(this.executeSystemHealthCommandFunction({ command })).pipe(map(x => x.data));
  }

  public executeSystemHealthRepairScript(name: string): Observable<any> {
    return from(this.executeSystemHealthRepairScriptFunction({ name })).pipe(map(x => x.data));
  }

  public createSystemHealthSchedule(schedule: NewSchedule): Observable<any> {
    return from(this.createSystemHealthScheduleFunction({
      name: schedule.name, cronSchedule: schedule.schedule, commands: [schedule.command]
    })).pipe(map(x => x.data));
  }

  public updateSystemHealthSchedule(schedule: NewSchedule): Observable<any> {
    return from(this.updateSystemHealthScheduleFunction({
      name: schedule.name, cronSchedule: schedule.schedule, commands: [schedule.command]
    })).pipe(map(x => x.data));
  }

  public deleteSystemHealthSchedule(scheduleId: string): Observable<any> {
    return from(this.deleteSystemHealthScheduleFunction({ scheduleId })).pipe(map(x => x.data));
  }
}
