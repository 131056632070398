import { createAction, props } from '@ngrx/store';
import { OrganisationUser, UserRoleUpdateResult } from 'src/app/models/accounts';
import { Device } from 'src/app/shared/custom/models/devices';

export const getOrganisationUserRequest = createAction(
  '[Profile] Get Organisation User Request',
  props<{ membershipId: string }>()
);

export const getOrganisationUserResponse = createAction(
  '[Profile] Get Organisation User Response',
  props<{ organisationUser: OrganisationUser }>()
);

export const getOrganisationUserFailure = createAction(
  '[Profile] Get Organisation User Failure',
  props<{ error: any }>()
);

export const saveProfileRequest = createAction(
  '[Profile] Save Profile Request',
  props<{ organisationUser: OrganisationUser }>()
);

export const saveProfileSuccess = createAction(
  '[Profile] Save Profile Success',
  props<{ organisationUser: OrganisationUser }>()
);

export const saveProfileFailure = createAction(
  '[Profile] Save Profile Failure',
  props<{ error: any }>()
);

export const setUserRoleRequest = createAction(
  '[Profile] Set User Role',
  props<{ membershipId: string, role: string, enabled: boolean }>()
);

export const setUserRoleSuccess = createAction(
  '[Profile] Set User Role Success',
  props<{ result: UserRoleUpdateResult }>()
);

export const setUserRoleFailure = createAction(
  '[Profile] Set User Role Failure',
  props<{ error: any }>()
);

export const sendPasswordResetEmailRequest = createAction(
  '[Profile] Send password reset email request',
  props<{email: string, organisationMembershipId: string, organisationId: string}>()
);

export const sendPasswordResetEmailSuccess = createAction(
  '[Profile] Send password reset email success',
  props<{email: string}>()
);

export const sendPasswordResetEmailFailure = createAction(
  '[Profile] Send password reset email failure',
  props<{ error: any }>()
);

export const removeUserDeviceReset = createAction(
  '[Profile] Remove User Device Reset'
);

export const removeUserDeviceRequest = createAction(
  '[Profile] Remove User Device Request',
  props<{ organisationId: string; accountId: string; deviceId: string; deviceName: string; }>()
);

export const removeUserDeviceSuccess = createAction(
  '[Profile] Remove User Device Success',
  props<{ deviceId: string; deviceName: string; }>()
);

export const removeUserDeviceFailure = createAction(
  '[Profile] Remove User Device Failure',
  props<{ deviceId: string; deviceName: string; error: any; }>()
);

export const getUserDevicesRequest = createAction(
  '[Profile] Get User Devices Request',
  props<{ accountId: string }>()
);

export const getUserDevicesSuccess = createAction(
  '[Profile] Get User Devices Success',
  props<{ devices: Device[] }>()
);

export const getUserDevicesFailure = createAction(
  '[Profile] Get User Devices Failure',
  props<{ error: any }>()
);
