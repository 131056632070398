import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type Observer<T, V> = (observable: T) => V;

export function subscribe<T, V>(
  observable: Observable<T>,
  destroy: Observable<any>,
  observer: Observer<T, V>) {
  return observable.pipe(takeUntil(destroy)).subscribe(observer);
}

export function groupBy<T>(xs: T[], key: string) {
  return xs.reduce((soFar, next) => {
    (soFar[next[key]] = soFar[next[key]] || []).push(next);
    return soFar;
  }, {});
}
