<table mat-table [dataSource]="devicesDataSource" class="mat-elevation-z4 devices-table" *ngIf="removingDevices$ | async as removingDevices">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.name' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.name }}
    </td>
  </ng-container>
  <ng-container matColumnDef="device">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.device' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.profile.model }} ({{ ('user-profile.user-devices.type-codes.' + element.typeCode) | translate }})
    </td>
  </ng-container>
  <ng-container matColumnDef="operatingSystem">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.operating-system' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.profile.operatingSystem }} {{ element.profile.operatingSystemVersion }}
    </td>
  </ng-container>
  <ng-container matColumnDef="appVersion">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.app-version' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="(!element.appVersion) else appVersion">
        {{ 'user-profile.user-devices.unknown' | translate }}
      </div>
      <ng-template #appVersion>
        {{ element.appVersion }}
      </ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="batteryState">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.battery-state' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="(!element.batteryInfo) else batteryState">
        {{ 'user-profile.user-devices.unknown' | translate }}
      </div>
      <ng-template #batteryState>
        {{ ('user-profile.user-devices.battery-state.' + element.batteryInfo.state) | translate }}
      </ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="batteryLevel">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.battery-level' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="(!element.batteryInfo) else batteryLevel">
        {{ 'user-profile.user-devices.unknown' | translate }}
      </div>
      <ng-template #batteryLevel>
        {{ element.batteryInfo.batteryLevel }}%
      </ng-template>
    </td>
  </ng-container>
  <ng-container matColumnDef="lastUpdate">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.last-update' | translate }}</th>
    <td mat-cell *matCellDef="let element">
      {{ element.serverUpdatedUtc | amLocale:currentLocale | amDateFormat: 'LLLL' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>{{ 'user-profile.user-devices.headers.actions' | translate }}</th>
    <td mat-cell *matCellDef="let element" class="actions">
      <div class="removing-device" *ngIf="removingDevices.deviceIds.includes(element.deviceId)">
        <safe-spinner type="ball-pulse" class="removing-device"></safe-spinner>
      </div>
      <button class="remove-device" *ngIf="!removingDevices.deviceIds.includes(element.deviceId)"
        mat-icon-button color="warn" (click)="removeDevice($event, element)">
        <mat-icon [matTooltip]="'user-profile.user-devices.remove-device' | translate">delete</mat-icon>
      </button>
    </td>
  </ng-container>
<ng-container matColumnDef="emptyTable" *ngIf="(totalCount$ | async) === 0">
    <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
      <div class="no-devices">{{ 'user-profile.user-devices.no-devices' | translate }}</div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="device"></tr>
  <tr mat-footer-row *matFooterRowDef="emptyTable$ | async" [ngClass]="hideIfPopulated$ | async"></tr>
</table>
