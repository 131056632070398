import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as fromConnect from 'src/app/features/connect/connect';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { TenantState } from 'src/app/shared/custom/models/domains';

@Component({
  selector: 'safe-send-account-setup-email',
  templateUrl: './send-account-setup-email.component.html',
  styleUrls: ['./send-account-setup-email.component.scss']
})
export class SendAccountSetupEmailComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  public accountSetUpCompleted$: Observable<boolean>;
  public workingState$: Observable<{ working: boolean }>;

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
  ) { }

  @Input() email: string;
  @Input() tenantState: TenantState;
  @Input() signInColor: string;
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();

  public sendAccountSetupEmailFormGroup: FormGroup = this.formBuilder.group({});

  ngOnInit(): void {
    this.workingState$ = this.store.pipe(select(fromConnect.selectWorkingState));
    this.accountSetUpCompleted$ = this.store.pipe(select(fromConnect.selectAccountSetupCompleted), filter(x => x));
    this.accountSetUpCompleted$.pipe(takeUntil(this.destroy)).subscribe(() => {
      return this.next.emit();
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  sendEmail() {
    this.store.dispatch(fromConnect.setUpAccountRequest({
      email: this.email,
      cloudTenantId: this.tenantState.cloudTenantId,
    }));
  }
}
