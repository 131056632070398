import { Injectable } from '@angular/core';
import * as storage from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public get ref() { return storage.ref; }
  public get uploadBytes() { return storage.uploadBytes; }
  public get getMetadata() { return storage.getMetadata; }
  public get getDownloadURL() { return storage.getDownloadURL; }
  public get fromTask() { return storage.fromTask; }
  public get uploadBytesResumable() { return storage.uploadBytesResumable; }
  public get percentage() { return storage.percentage; }
}
