<form class="update-roles" [formGroup]="updateRolesFormGroup" *ngIf="(isGettingOrganisationUser$ | async) === false">
  <ul class="role-states">
    <li *ngFor="let roleState of roleStates$ | async">
      <div class="usage">
        <span class="role-name">{{ roleState.roleName | translate }}</span>
        <span class="badge">
          <mat-slide-toggle
            [checked]="roleState.enabled"
            [disabled]="!roleState.canUpdate"
            (click)="toggleClicked(roleState.role, roleState.canUpdate)">
          </mat-slide-toggle>
        </span>
      </div>
    </li>
  </ul>
</form>
