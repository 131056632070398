import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, filter, mergeMap, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { of, from } from 'rxjs';
import * as AccountActions from './account.actions';
import { AccountService } from '../../service/account.service';
import { Router } from '@angular/router';
import { OrganisationService } from '../../service/organisation.service';

@Injectable()
export class AccountEffects {

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.signOutRequest),
      exhaustMap(x => this.accountService.signOut().pipe(
        map(_ => AccountActions.signOutSuccess()),
        catchError(error => of(AccountActions.signOutFailure({ error })))
      ))
    );
  });

  getAccountByAccountId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getAccountByIdRequest),
      distinctUntilChanged((x, y) => x.accountId === y.accountId),
      switchMap(x => this.accountService.getAccountFromAccountId(x.accountId).pipe(
        map(account => AccountActions.getAccountByIdSuccess({ account })),
        catchError(error => of(AccountActions.getAccountByIdFailure({ error })))
      ))
    );
  });

  getAccountByUid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getAccountByUidRequest),
      distinctUntilChanged((x, y) => x.uid === y.uid),
      switchMap(x => this.accountService.getAccountFromUid(x.uid).pipe(
        filter(accounts => accounts.length > 0),
        map(accounts => AccountActions.getAccountByUidSuccess({ account: accounts[0] })),
        catchError(error => of(AccountActions.getAccountByUidFailure({ error })))
      ))
    );
  });

  getOrganisations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getOrganisationsRequest),
      mergeMap(x => this.accountService.getOrganisations(x.accountId).pipe(
        map(organisations => AccountActions.getOrganisationsSuccess({ organisations })),
        catchError(error => of(AccountActions.getOrganisationsFailure({ error })))
      ))
    );
  });

  getHeadOffice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getHeadOfficeRequest),
      mergeMap(x => this.accountService.getHeadOffice(x.organisationId).pipe(
        map(headOffice => AccountActions.getHeadOfficeSuccess({ headOffice })),
        catchError(error => of(AccountActions.getHeadOfficeFailure({ error })))
      )
    ));
  });

  chooseCurrentOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.chooseCurrentOrganisationRequest),
      mergeMap(_ => this.organisationService.chooseCurrentOrganisation().pipe(
        map(organisationId => {
          return AccountActions.setCurrentOrganisationId({ organisationId });
        })
      ))
    );
  });

  signedOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.signOutSuccess),
      mergeMap(_ => from(this.router.navigate(['/connect'])))
    );
  }, { dispatch: false });

  constructor(
    private actions$: Actions,
    private router: Router,
    private accountService: AccountService,
    private organisationService: OrganisationService
  ) { }
}
