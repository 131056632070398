import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { NgForm, FormGroupDirective, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

export class CustomComponentBase {
  constructor(
      public _defaultErrorStateMatcher: ErrorStateMatcher,
      public _parentForm: NgForm,
      public _parentFormGroup: FormGroupDirective,
      public ngControl: NgControl
  ) { }

  stateChanges = new Subject<void>();
}
export const customInputComponentMixinBase = mixinErrorState(CustomComponentBase);
