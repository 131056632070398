import { Action, createReducer, on } from '@ngrx/store';
import { OrganisationMember, OrganisationUserCreation } from 'src/app/models/organisation';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import * as UserActions from './users.actions';
import { Domain } from 'src/app/shared/custom/models/domains';

export const usersFeatureKey = 'users';

export interface State {
  getOrganisationDomainsState: RequestState;
  createOrganisationUserState: RequestState;
  organisationDomains: Domain[];
  userCount: number;
  userCreation: OrganisationUserCreation,
  memberSuggestions: OrganisationMember[];
  members: { [memberId: string]: OrganisationMember };
  getMemberState: RequestState;
  archiveMemberState: RequestState;
  suspendMemberState: RequestState;
  resumeMemberState: RequestState;
}

export const initialState: State = {
  getOrganisationDomainsState: initial,
  createOrganisationUserState: initial,
  organisationDomains: [],
  userCount: 0,
  userCreation: null,
  memberSuggestions: [],
  members: {},
  getMemberState: initial,
  archiveMemberState: initial,
  suspendMemberState: initial,
  resumeMemberState: initial,
};

const usersReducer = createReducer(
  initialState,
  on(UserActions.getOrganisationUserCountResult, (state, action) => ({
    ...state,
    userCount: action.userCount
  })),
  on(UserActions.createOrganisationUserReset, state => ({
    ...state,
    userCreation: null,
    createOrganisationUserState: initial
  })),
  on(UserActions.createOrganisationUserRequest, state => ({
    ...state,
    createOrganisationUserState: pending
  })),
  on(UserActions.createOrganisationUserSuccess, (state, action) =>  ({
    ...state,
    userCreation: action,
    createOrganisationUserState: ok
  })),
  on(UserActions.createOrganisationUserFailure, state =>  ({
    ...state,
    createOrganisationUserState: error
  })),
  on(UserActions.getOrganisationDomainsRequest, state => ({
    ...state,
    getOrganisationDomainsState: pending
  })),
  on(UserActions.getOrganisationDomainsResult, (state, action) => ({
    ...state,
    getOrganisationDomainsState: ok,
    organisationDomains: action.domains
  })),
  on(UserActions.getMemberRequest, state => ({
    ...state,
    getMemberState: pending
  })),
  on(UserActions.getMemberResponse, (state, action) => ({
    ...state,
    getMemberState: ok,
    members: { ...state.members, [action.member.organisationMembershipId]: action.member }
  })),
  on(UserActions.getMemberFailure, state => ({
    ...state,
    getMemberState: error
  })),
  on(UserActions.archiveMemberReset, state => ({
    ...state,
    archiveMemberState: initial
  })),
  on(UserActions.archiveMemberRequest, state => ({
    ...state,
    archiveMemberState: pending
  })),
  on(UserActions.archiveMemberFailure, state => ({
    ...state,
    archiveMemberState: error
  })),
  on(UserActions.archiveMemberSuccess, state => ({
    ...state,
    archiveMemberState: ok
  })),
  on(UserActions.suspendMemberReset, state => ({
    ...state,
    suspendMemberState: initial
  })),
  on(UserActions.suspendMemberRequest, state => ({
    ...state,
    suspendMemberState: pending
  })),
  on(UserActions.suspendMemberFailure, state => ({
    ...state,
    suspendMemberState: error
  })),
  on(UserActions.suspendMemberSuccess, state => ({
    ...state,
    suspendMemberState: ok
  })),
  on(UserActions.resumeMemberReset, state => ({
    ...state,
    resumeMemberState: initial
  })),
  on(UserActions.resumeMemberRequest, state => ({
    ...state,
    resumeMemberState: pending
  })),
  on(UserActions.resumeMemberFailure, state => ({
    ...state,
    resumeMemberState: error
  })),
  on(UserActions.resumeMemberSuccess, state => ({
    ...state,
    resumeMemberState: ok
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return usersReducer(state, action);
}
