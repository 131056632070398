import {
  Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, Input, ChangeDetectorRef, NgZone
} from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as fromUsers from 'src/app/modules/console/features/users/users';
import * as fromAccount from 'src/app/modules/console/features/account/account';
import { Store, select } from '@ngrx/store';
import { filter, tap, map, take } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { ThemeEmitterComponent } from 'src/app/shared/custom/components/theme-emitter/theme-emitter.component';
import {
  DomainBoundEmailComponent
} from 'src/app/shared/custom/components/domain-bound-email/domain-bound-email.component';
import { NewOrganisationUserValidator } from 'src/app/modules/console/validators/new-organisation-user.validator';
import * as utilities from 'src/app/utilities';
import * as validation from 'src/app/constants/validation';
import { NgxSpinnerService } from 'ngx-spinner';
import { PhoneNumberValidator } from 'src/app/validators/phone-number-validator';
import { SnackBarService } from '../../service/snack-bar.service';
import { StorageFile, isCsv } from 'src/app/models/organisation';

const userFields = ['email', 'phoneNumber', 'firstName', 'lastName'];

@Component({
  selector: 'safe-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  public domains$: Observable<string[]>;
  public hasDomains$: Observable<boolean>;
  public isCreatingNewUser$: Observable<boolean>;
  public email: string;
  public addUserFormGroup = this.formBuilder.group({
    user: this.formBuilder.group({
      email: ['', { updateOn: 'blur' }],
      phoneNumber: [''],
      firstName: [''],
      lastName: ['']
    }),
    users: this.formBuilder.group({
      storageFile: [null]
    }),
  });
  public isCsv = isCsv;
  private domainIds: { [domain: string]: string; } = {};
  public files: File[] = [];
  public action = 'create-new-user.create-user';
  public userPanelIsOpen = false;
  public csvPanelIsOpen = false;

  @Input() organisationId: string;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @ViewChild(DomainBoundEmailComponent) public emailComponent: DomainBoundEmailComponent;
  @ViewChild(ThemeEmitterComponent) theme: ThemeEmitterComponent;

  constructor(
    private store: Store,
    private newOrganisationUserValidator: NewOrganisationUserValidator,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private phoneNumberValidator: PhoneNumberValidator,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.domains$ = this.store.pipe(
      select(fromUsers.organisationDomains),
      filter(x => x.length > 0),
      tap(domains => {
        for (const domain of domains) {
          this.domainIds[domain.domain] = domain.id;
        }
      }),
      map(domains => domains.map(x => x.domain)));
    this.isCreatingNewUser$ = this.store.pipe(
      select(fromUsers.isCreatingOrganisationUser),
      tap(() => this.ngZone.run(() => this.cdr.markForCheck())));
    utilities.subscribe(this.isCreatingNewUser$, this.destroy, (show) => {
      show ? this.spinner.show() : this.spinner.hide();
    });
    this.hasDomains$ = this.domains$.pipe(select(x => x.length > 0));
    this.newOrganisationUserValidator.organisationId = this.organisationId;
    this.store.dispatch(fromUsers.getOrganisationDomainsRequest({ organisationId: this.organisationId }));
    const uploadedCsv$ = this.store.pipe(select(fromUsers.uploadedCsv), filter(x => !!x));
    const createdSingleUser$ = this.store.pipe(select(fromUsers.createdSingleUser), filter(x => !!x));
    const userCreationFailed$ = this.store.pipe(select(fromUsers.organisationUserCreationFailed), filter(x => x));
    utilities.subscribe(uploadedCsv$, this.destroy, csv => {
      this.snackBarService.show('create-new-user.csv-uploaded', 'create-new-user.ok', {
        values: { value: csv.fileName }
      });
      this.store.dispatch(fromUsers.createOrganisationUserReset());
    });
    utilities.subscribe(createdSingleUser$, this.destroy, user => {
      this.snackBarService.show('create-new-user.single-user-created', 'create-new-user.ok', {
        values: { value: user.email }
      });
      this.store.dispatch(fromUsers.createOrganisationUserReset());
    });
    utilities.subscribe(userCreationFailed$, this.destroy, () => {
      this.snackBarService.show('create-new-user.user-creation-failed', 'create-new-user.ok');
      this.store.dispatch(fromUsers.createOrganisationUserReset());
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  createNewUser() {
    const [email, givenName, familyName, phoneNumber]: string[] = [
      this.addUserFormGroup.get('user.email').value,
      this.addUserFormGroup.get('user.firstName').value,
      this.addUserFormGroup.get('user.lastName').value,
      this.addUserFormGroup.get('user.phoneNumber').value,
    ];
    const [username, domain] = email.split('@');
    const domainId = this.domainIds[domain];
    const organisationId = this.organisationId;
    const csvUpload: StorageFile = this.addUserFormGroup.get('users.storageFile').value;
    const newUser = csvUpload ? null : { email, phoneNumber, givenName, familyName, username, domainId };
    this.store.pipe(select(fromAccount.membershipId)).pipe(take(1)).subscribe(uploaderId => this.store.dispatch(
        fromUsers.createOrganisationUserRequest({ organisationId, newUser, csvUpload, uploaderId })
    ));
  }

  cancelCreateNewUser() {
    this.closed.emit();
  }

  onEmailChanged(email: string) {
    this.email = email;
  }

  public userPanelOpened() {
    this.action = 'create-new-user.create-user';
    this.userPanelIsOpen = true;
    this.addUserFormGroup.get('user.email')
      .setValidators([Validators.pattern(validation.patterns.email), Validators.required]);
    this.addUserFormGroup.get('user.email')
      .setAsyncValidators([this.newOrganisationUserValidator.validate.bind(this.newOrganisationUserValidator)]);
    this.addUserFormGroup.get('user.phoneNumber')
      .setValidators([this.phoneNumberValidator.validate.bind(this.phoneNumberValidator), Validators.required]);
    this.addUserFormGroup.get('user.firstName')
      .setValidators([Validators.pattern(validation.patterns.name), Validators.required]);
    this.addUserFormGroup.get('user.lastName')
      .setValidators([Validators.pattern(validation.patterns.name), Validators.required]);
    for (const field of userFields) {
      this.addUserFormGroup.get(`user.${field}`).updateValueAndValidity();
    }
  }

  public userPanelClosed() {
    this.userPanelIsOpen = false;
    for (const field of userFields) {
      const control = this.addUserFormGroup.get(`user.${field}`);
      control.clearValidators();
      control.clearAsyncValidators();
      control.updateValueAndValidity();
    }
  }

  public csvPanelOpened() {
    this.csvPanelIsOpen = true;
    this.action = 'create-new-user.add-users';
    const control = this.addUserFormGroup.get('users.storageFile');
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
  }

  public csvPanelClosed() {
    this.csvPanelIsOpen = false;
    const control = this.addUserFormGroup.get('users.storageFile');
    control.clearValidators();
    control.updateValueAndValidity();
  }

  public canCreate() {
    return this.addUserFormGroup.valid && (this.userPanelIsOpen || this.csvPanelIsOpen);
  }
}
