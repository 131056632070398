import { Component, OnInit, Input, OnDestroy, ElementRef, Optional, Self, HostBinding, ViewChild, DoCheck } from '@angular/core';
import { Subject } from 'rxjs';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ControlValueAccessor, NgControl, NgForm, FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { FocusMonitor } from '@angular/cdk/a11y';
import { takeUntil } from 'rxjs/operators';
import { customInputComponentMixinBase } from '../custom-input-component';

@Component({
  selector: 'safe-domain-bound-email',
  templateUrl: './domain-bound-email.component.html',
  styleUrls: ['./domain-bound-email.component.scss'],
  providers: [
    { provide: MatFormFieldControl, useExisting: DomainBoundEmailComponent }]
})
export class DomainBoundEmailComponent
extends customInputComponentMixinBase
implements OnInit, OnDestroy, DoCheck,
  ControlValueAccessor, MatFormFieldControl<string> {

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  errorState: boolean;
  controlType?: string;
  autofilled?: boolean;
  userAriaDescribedBy?: string;

  @Input()
  get domains(): string[] { return this.domainsValue; }
  set domains(value: string[]) {
    const that = this;
    function chooseDomain(domain) {
      that.select.value = domain;
      that.chosenDomain = domain;
    }
    const oldValue = this.select.value;
    this.domainsValue = value;
    if (!oldValue && value && value.length > 0) {
      chooseDomain(value[0]);
    } else if (oldValue && value && value.length > 0 && value.every(v => v !== oldValue)) {
      chooseDomain(value[0]);
    }
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this.disabledValue; }
  set disabled(value: boolean) {
    this.disabledValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get required() { return this.requiredValue; }
  set required(value) {
    this.requiredValue = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get value(): string | null { return this.constructedEmail; }
  set value(email: string | null) {
    this.constructedEmail = email;
    this.stateChanges.next();
  }

  @Input()
  get placeholder() { return this.placeholderValue; }
  set placeholder(value) {
    this.placeholderValue = value;
    this.stateChanges.next();
  }

  get empty() {
    return !(this.username) || !(this.username.trim());
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !!this.username;
  }

  static nextId = 0;
  @Input() errorStateMatcher: ErrorStateMatcher;
  @ViewChild(MatSelect, { static: true }) private select: MatSelect;
  private domainsValue: string[];
  private placeholderValue: string;
  private constructedEmail: string;
  private chosenDomain: string;
  private onTouched: any;
  private destroy = new Subject<void>();
  stateChanges = new Subject<void>();
  private requiredValue = false;
  private disabledValue = false;
  focused = false;
  username = '';

  @HostBinding() id = `safe-domain-bound-email-${DomainBoundEmailComponent.nextId++}`;

  @HostBinding('attr.aria-describedby') describedBy = '';

  onBlur() {
    if (this.onTouched) {
      this.onTouched();
      this.stateChanges.next();
    }
  }

  ngOnInit() {
    this.focusMonitor.monitor(this.elRef.nativeElement, true)
      .pipe(takeUntil(this.destroy)).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    this.elRef.nativeElement.querySelector('input').focus();
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elRef.nativeElement);
  }

  writeValue(obj: any): void {
    this.value = obj.toString();
  }

  registerOnChange(fn: any): void {
    this.stateChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
      fn(this.value);
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateValue() {
    const username = this.username;
    const domain = this.chosenDomain;
    const newValue = (username && domain) ? `${username}@${domain}` : null;
    if (this.value !== newValue) {
      this.value = newValue;
    }
  }

  onUsernameTyped(event: any) {
    this.username = event.target.value;
    this.updateValue();
  }

  onUsernameChanged(event: any) {
    this.username = event.target.value;
    this.updateValue();
    this.onBlur();
  }

  onDomainChanged(domain: any) {
    this.chosenDomain = domain.value;
    this.updateValue();
    this.onBlur();
  }

  onContainerClick(_: MouseEvent) {
    this.elRef.nativeElement.querySelector('input').focus();
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }
}
