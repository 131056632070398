import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromUsers from 'src/app/modules/console/features/users/users';
import { OrganisationMember } from 'src/app/models/organisation';
import { select, Store } from '@ngrx/store';

@Component({
  selector: 'safe-organisation-member',
  templateUrl: './organisation-member.component.html',
  styleUrls: ['./organisation-member.component.scss']
})
export class OrganisationMemberComponent implements OnInit {
  public member$: Observable<OrganisationMember>;

  @Input() organisationMembershipId: string;

  constructor(
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.member$ = this.store.pipe(select(fromUsers.getMember(this.organisationMembershipId)));
    this.store.dispatch(fromUsers.getMemberRequest({
      organisationMembershipId: this.organisationMembershipId
    }));
  }
}
