import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';
import { OrganisationUser, UserRoleUpdateResult } from 'src/app/models/accounts';
import { ElasticSearchPage, PageRequest } from 'src/app/models/page-result';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { Auth } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { Device } from '../models/devices';
import { CreateOrganisationUser, OrganisationUserCreation } from 'src/app/models/organisation';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OrderFields } from '../models/search';

export type OrganisationUsersRequest = PageRequest & {
  organisationId: string;
  order: OrderFields[];
  searchTerm: string;
};

export interface UpdateUserRoleRequest {
  membershipId: string;
  role: string;
  enabled: boolean;
}

export type EmailActionCode = 'web' | 'mobile';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private activeOrganisationUsersFunction: HttpsCallable<any, any>;
  private suspendedOrganisationUsersFunction: HttpsCallable<any, any>;
  private allOrganisationUsersFunction: HttpsCallable<any, any>;
  private createOrganisationUserFunction: HttpsCallable<any, any>;
  private sendPasswordResetEmailFunction: HttpsCallable<any, any>;
  private setUserRoleFunction: HttpsCallable<any, any>;
  private saveProfileFunction: HttpsCallable<any, any>;
  private suspendOrganisationMembershipFunction: HttpsCallable<any, void>;
  private resumeOrganisationMembershipFunction: HttpsCallable<any, void>;
  private removeUserDeviceFunction: HttpsCallable<any, any>;

  constructor(
    functions: Functions,
    private auth: Auth,
    private firestore: Firestore,
    functionsService: FunctionsService,
    private firestoreService: FirestoreService,
    private authService: AuthService,
    private http: HttpClient,
  ) {
    const { httpsCallable } = functionsService;
    this.activeOrganisationUsersFunction = httpsCallable(functions, 'activeOrganisationUsersGen2');
    this.suspendedOrganisationUsersFunction = httpsCallable(functions, 'suspendedOrganisationUsersGen2');
    this.allOrganisationUsersFunction = httpsCallable(functions, 'allOrganisationUsersGen2');
    this.createOrganisationUserFunction = httpsCallable(functions, 'createOrganisationUserGen2');
    this.sendPasswordResetEmailFunction = httpsCallable(functions, 'sendPasswordResetEmailGen2');
    this.setUserRoleFunction = httpsCallable(functions, 'setUserRoleGen2');
    this.saveProfileFunction = httpsCallable(functions, 'saveProfileGen2');
    this.suspendOrganisationMembershipFunction = httpsCallable(functions, 'suspendOrganisationMembershipGen2');
    this.resumeOrganisationMembershipFunction = httpsCallable(functions, 'resumeOrganisationMembershipGen2');
    this.removeUserDeviceFunction = httpsCallable(functions, 'removeUserDeviceGen2');
  }

  private toUser = (membershipDto: any, accountDto: any): OrganisationUser => {
    const user: OrganisationUser = {
      organisationMembershipId: membershipDto.id,
      organisationId: membershipDto.organisationId,
      givenName: membershipDto.givenName,
      familyName: membershipDto.familyName,
      email: membershipDto.email || null,
      phoneNumber: membershipDto.phoneNumber,
      accountId: membershipDto.accountId,
      passwordResetEmailCount: accountDto.passwordResetEmailCount || 0,
      pushNotificationEventCount: accountDto.pushNotificationEventCount || 0,
      roles: membershipDto.roles,
    };
    if (membershipDto.suspension) user.suspension = { ...membershipDto.suspension };
    return user;
  }

  activeOrganisationUsers(request: OrganisationUsersRequest): Observable<ElasticSearchPage> {
    return from(this.activeOrganisationUsersFunction(request)).pipe(map(x => x.data));
  }

  suspendedOrganisationUsers(request: OrganisationUsersRequest): Observable<ElasticSearchPage> {
    return from(this.suspendedOrganisationUsersFunction(request)).pipe(map(x => x.data));
  }

  allOrganisationUsers(request: OrganisationUsersRequest): Observable<ElasticSearchPage> {
    return from(this.allOrganisationUsersFunction(request)).pipe(map(x => x.data));
  }

  archiveOrganisationMembership(organisationId: string, organisationMembershipId: string): Observable<string> {
    const { idToken } = this.authService;
    return idToken(this.auth).pipe(switchMap(token =>
      this.http.post(`${environment.appEngineUrl}delete/organisation-member`, {
        organisationId,
        organisationMembershipId,
      }, {
        responseType: 'text',
        headers: { contentType: 'application/json', authorization: `Bearer ${token}` },
      }
    )));
  }

  suspendOrganisationMembership(organisationId: string, organisationMembershipId: string): Observable<void> {
    return from(this.suspendOrganisationMembershipFunction({ organisationId, organisationMembershipId })).pipe(map(x => x.data));
  }

  resumeOrganisationMembership(organisationId: string, organisationMembershipId: string) {
    return from(this.resumeOrganisationMembershipFunction({ organisationId, organisationMembershipId })).pipe(map(x => x.data));
  }

  createOrganisationUser(command: CreateOrganisationUser): Observable<OrganisationUserCreation> {
    return from(this.createOrganisationUserFunction(command)).pipe(map(x => x.data as OrganisationUserCreation));
  }

  removeUserDevice(organisationId: string, accountId: string, deviceId: string): Observable<Device> {
    return from(this.removeUserDeviceFunction({ organisationId, accountId, deviceId })).pipe(map(x => x.data));
  }

  public getUserDevices(accountId: string): Observable<Device[]> {
    const { query, collection, collectionData } = this.firestoreService;
    const toDevice = (deviceDto: any) => ({
      deviceId: deviceDto.id,
      name: deviceDto.name,
      typeCode: deviceDto.typeCode,
      deviceAddedUtc: deviceDto.deviceAddedUtc.toDate(),
      serverUpdatedUtc: deviceDto.serverUpdatedUtc.toDate(),
      appVersion: deviceDto.appVersion || null,
      appBuildNumber: deviceDto.appBuildNumber || null,
      batteryInfo: deviceDto.batteryInfo || null,
      profile: deviceDto.profile,
    });
    const devicesQuery = query(collection(this.firestore, `accounts/${accountId}/userDevices`));
    return collectionData(devicesQuery).pipe(map(d => d.map(toDevice)));
  }

  getOrganisationUserCount(organisationId: string): Observable<number> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `organisations/${organisationId}`)).pipe(
      map(x => x.userCount),
      distinctUntilChanged()
    );
  }

  getOrganisationUser(membershipId: string): Observable<OrganisationUser> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, `organisationMemberships/${membershipId}`)).pipe(
      switchMap((membershipDto) => docData(doc(this.firestore, `accounts/${membershipDto.accountId}`)).pipe(
        map((accountDto) => this.toUser(membershipDto, accountDto)))));
  }

  setUserRole(membershipId: string, role: string, enabled: boolean): Observable<UserRoleUpdateResult> {
    return from(this.setUserRoleFunction({ membershipId, role, enabled })).pipe(map(x => ({
      ...x.data,
      userRecord: {
        email: x.data.userRecord.email,
        hasPassword: x.data.userRecord.providerData.filter(
          (d: any) => d.providerId === 'password').length > 0
      }
    })));
  }

  saveProfile(organisationUser: OrganisationUser): Observable<any> {
    const { docData, doc } = this.firestoreService;
    return from(this.saveProfileFunction(organisationUser)).pipe(
      switchMap(x => docData(doc(this.firestore, `accounts/${x.data.accountId}`)).pipe(
        map((accountDto) => this.toUser(x.data, accountDto)))));
  }

  sendPasswordResetEmail(email: string, organisationMembershipId: string, organisationId: string): Observable<boolean> {
    return from(this.sendPasswordResetEmailFunction({ email, organisationMembershipId, organisationId })).pipe(map(_ => true));
  }

  async currentUserIsSuspended(organisationId: string): Promise<Observable<boolean>> {
    const { collectionData, query, collection, where } = this.firestoreService;
    const user = this.auth.currentUser;
    const accountDtos$ = collectionData(query(
      collection(this.firestore, 'accounts'),
      where('firebaseUid', '==', user.uid))
    );

    const activeUserQuery = (accountId: string) => collectionData(query(
      collection(this.firestore, 'organisationMemberships'),
      where('organisationId', '==', organisationId),
      where('accountId', '==', accountId),
      where('suspension', '==', null),
    ));

    return accountDtos$.pipe(
      map(accountDtos => accountDtos.length === 1 ? accountDtos[0].id : ''),
      mergeMap(accountId => activeUserQuery(accountId))
    ).pipe(map(users => users.length === 0));
  }

  async currentUsersActiveMemberships(): Promise<Observable<string[]>> {
    const { collectionData, query, collection, where } = this.firestoreService;
    const user = this.auth.currentUser;
    const accountDtos$ = collectionData(query(
      collection(this.firestore, 'accounts'),
      where('firebaseUid', '==', user.uid))
    );
    const activeMembershipsQuery = (accountId: string) => collectionData(query(
      collection(this.firestore, 'organisationMemberships'),
      where('accountId', '==', accountId),
      where('suspension', '==', null),
    ));
    return accountDtos$.pipe(
      map(accountDtos => accountDtos.length === 1 ? accountDtos[0].id : null),
      mergeMap(accountId => activeMembershipsQuery(accountId).pipe(map(members => members.map(m => m.organisationId))))
    );
  }
}
