import { Action, createReducer, on } from '@ngrx/store';
import * as ConnectActions from './connect.actions';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';

export const connectFeatureKey = 'connect';

export interface State {
  signInState: RequestState;
  passwordResetState: RequestState;
  accountSetupState: RequestState;
  accessTokenSignInState: RequestState;
  verifyEmailState: RequestState;
  accessTokenSignInResult: { signedIn: boolean } | null; 
  verifyEmailResult: { email: string, accountCreationRequired: boolean; passwordCreationRequired: boolean, redirectToDomain: string | null };
}

export const initialState: State = {
  signInState: initial,
  passwordResetState: initial,
  accountSetupState: initial,
  accessTokenSignInState: initial,
  verifyEmailState: initial,
  accessTokenSignInResult: null,
  verifyEmailResult: null,
};

const connectReducer = createReducer(
  initialState,
  on(ConnectActions.verifyEmailReset, state => ({
    ...state,
    verifyEmailState: initial,
    verifyEmailResult: null,
  })),
  on(ConnectActions.verifyEmailRequest, state => ({
    ...state,
    verifyEmailState: pending,
  })),
  on(ConnectActions.verifyEmailSuccess, (state, action) => ({
    ...state,
    verifyEmailState: ok,
    verifyEmailResult: {
      email: action.email,
      accountCreationRequired: action.accountCreationRequired,
      passwordCreationRequired: action.passwordCreationRequired,
      redirectToDomain: action.redirectToDomain,
    },
  })),
  on(ConnectActions.verifyEmailFailure, state => ({
    ...state,
    verifyEmailState: error,
  })),
  on(ConnectActions.firebaseSignInRequest, state => ({
    ...state,
    signInState: pending,
  })),
  on(ConnectActions.firebaseSignInSuccess, (state, action) => ({
    ...state,
    signInState: ok,
    passwordResetState: initial,
  })),
  on(ConnectActions.firebaseSignInFailure, (state, action) => ({
    ...state,
    signInState: error,
    passwordResetState: initial,
  })),
  on(ConnectActions.resetPasswordReset, state => ({
    ...state,
    passwordResetState: initial,
  })),
  on(ConnectActions.resetPasswordRequest, state => ({
    ...state,
    passwordResetState: pending,
    signInState: initial,
  })),
  on(ConnectActions.resetPasswordSuccess, (state, action) => ({
    ...state,
    passwordResetState: ok,
  })),
  on(ConnectActions.resetPasswordFailure, (state, action) => ({
    ...state,
    passwordResetState: error,
  })),
  on(ConnectActions.setUpAccountReset, state => ({
    ...state,
    accountSetupState: initial,
  })),
  on(ConnectActions.setUpAccountRequest, state => ({
    ...state,
    accountSetupState: pending,
  })),
  on(ConnectActions.setUpAccountSuccess, (state, action) => ({
    ...state,
    accountSetupState: ok,
  })),
  on(ConnectActions.setUpAccountFailure, (state, action) => ({
    ...state,
    accountSetupState: error,
  })),
  on(ConnectActions.accessTokenSignInReset, state => ({
    ...state,
    accessTokenSignInState: initial,
    accessTokenSignInResult: null,
  })),
  on(ConnectActions.accessTokenSignInRequest, state => ({
    ...state,
    accessTokenSignInState: pending,
  })),
  on(ConnectActions.accessTokenSignInResponse, (state, result) => ({
    ...state,
    accessTokenSignInState: ok,
    accessTokenSignInResult: { signedIn: result.signedIn },
  })),
  on(ConnectActions.accessTokenSignInFailure, state => ({
    ...state,
    accessTokenSignInState: error,
    accessTokenSignInResult: { signedIn: false },
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return connectReducer(state, action);
}
