import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../environments/environment';

export type RequestState = 'initial' | 'pending' | 'ok' | 'error';
export const initial: RequestState = 'initial';
export const pending: RequestState = 'pending';
export const ok: RequestState = 'ok';
export const error: RequestState = 'error';

export interface AppState {
  router: RouterReducerState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [storeFreeze] : [];
