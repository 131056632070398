import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import * as utilities from 'src/app/utilities';
import * as fromProfile from 'src/app/shared/custom/features/profile/profile';
import { environment } from 'src/environments/environment';
import { filter, map, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/shared/custom/components/confirmation-dialog/confirmation-dialog.component';
import { SnackBarService } from 'src/app/shared/custom/service/snack-bar.service';
import { Device } from '../../models/devices';

class UserDevicesDataSource implements DataSource<Device> {
  constructor(private devices: Observable<Device[]>) { }
  connect(_: CollectionViewer): Observable<Device[]> {
    return this.devices;
  }

  disconnect(_: CollectionViewer): void { }
}

@Component({
  selector: 'safe-user-devices-table',
  templateUrl: './user-devices-table.component.html',
  styleUrls: ['./user-devices-table.component.scss']
})
export class UserDevicesTableComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  public devicesDataSource: DataSource<Device>;
  public devices$: Observable<Device[]>;
  public currentLocale = environment.defaultLocale;
  public displayedColumns: string[] = [
    'name', 'device', 'operatingSystem', 'appVersion', 'batteryState', 'batteryLevel', 'lastUpdate', 'actions'
  ];
  public isLoadingDevices$: Observable<boolean>;
  public hideIfPopulated$: Observable<string>;
  public emptyTable$: Observable<string[]>;
  public totalCount$: Observable<number>;
  public removingDevices$: Observable<{ deviceIds: string[] }>;
  private accountId$: Observable<string>;
  @Input() organisationId: string;
  private detectChanges = () => this.ngZone.run(() => this.cdr.markForCheck());

  constructor(
    private store: Store,
    private snackBarService: SnackBarService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.accountId$ = this.store.pipe(select(fromProfile.accountId));
    utilities.subscribe(this.accountId$, this.destroy, accountId => {
      this.store.dispatch(fromProfile.getUserDevicesRequest({ accountId }));
    });
    this.devices$ = this.store.pipe(select(fromProfile.userDevices));
    this.isLoadingDevices$ = this.store.pipe(select(fromProfile.isGettingDevices));
    this.devicesDataSource = new UserDevicesDataSource(this.devices$);
    this.removingDevices$ = this.store.pipe(
      select(fromProfile.removingUserDevices), map(deviceIds => {
        return ({ deviceIds });
      }), tap(this.detectChanges)
    );
    this.emptyTable$ = this.devices$.pipe(map(d => d.length === 0 ? ['emptyTable'] : []));
    this.hideIfPopulated$ = this.devices$.pipe(map(d => d.length === 0 ? null : 'hide'));
    this.totalCount$ = this.devices$.pipe(map(d => d.length));
    const removedUserDevice$ = this.store.pipe(select(fromProfile.removedUserDevice), filter(x => !!x));
    const failedToRemoveUserDevice$ = this.store.pipe(select(fromProfile.failedToRemoveUserDevice), filter(x => !!x));
    utilities.subscribe(removedUserDevice$, this.destroy, (device) => this.snackBarService.show(
      'user-profile.user-devices.device-removal.removed', 'user-profile.user-devices.device-removal.ok',  { values: { device } }));
    utilities.subscribe(failedToRemoveUserDevice$, this.destroy, (device) => this.snackBarService.show(
      'user-profile.user-devices.device-removal.failed', 'user-profile.user-devices.device-removal.ok',  { values: { device } }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromProfile.removeUserDeviceReset());
    this.destroy.next();
    this.destroy.complete();
  }

  removeDevice(event: Event, device: Device) {
    event.stopPropagation();
    combineLatest([
      this.accountId$,
      this.translate.get('user-profile.user-devices.remove-confirmation').pipe(map(x => x.toString()))
    ]).pipe(take(1)).subscribe(([accountId, data]) => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });
      dialogRef.afterClosed().pipe(filter(x => x), take(1)).subscribe(() => {
        this.store.dispatch(fromProfile.removeUserDeviceRequest({
          organisationId: this.organisationId, accountId, deviceId: device.deviceId, deviceName: device.name
        }));
      });
    });
  }
}
