<form [formGroup]="searchFormGroup" (submit)="runSearch()">
  <mat-form-field class="full-width family-name">
    <mat-label>{{ 'personnel.find-users' | translate }}</mat-label>
    <input class="user-search" matInput formControlName="searchTerm" (input)="searchTermChanged($event)" />
    <div class="loading" matSuffix *ngIf="usersDataSource.loading$ | async else search">
      <safe-spinner type="ball-pulse" class="loading" name="loading"></safe-spinner>
    </div>
    <ng-template #search>
      <button class="search" mat-icon-button matSuffix>
        <mat-icon matIconSuffix color="primary">search</mat-icon>
      </button>
    </ng-template>
  </mat-form-field>
</form>
<div class="users">
  <div *ngIf="usersDataSource.initialLoad$ | async">
    <div fxLayout="column" fxLayoutAlign="flex-start center" class="spinner-container">
      <safe-spinner></safe-spinner>
    </div>
  </div>
  <table mat-table class="users-table mat-elevation-z8"
    [dataSource]="usersDataSource"
    matSort matSortActive="name" matSortDirection="asc"
    [hidden]="usersDataSource.initialLoad$ | async">
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'personnel.name-header' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="highlight" *ngIf="element.highlight?.name else noHighlight">
          <a [routerLink]="profileLink(element.fields.organisationMembershipId[0])"
            (click)="profileLinkClicked(element.fields.organisationMembershipId[0])"
            [innerHTML]="element.highlight.name[0]"></a>
        </div>
        <ng-template #noHighlight>
          <a [routerLink]="profileLink(element.fields.organisationMembershipId[0])"
            (click)="profileLinkClicked(element.fields.organisationMembershipId[0])">{{ element.fields.name[0] }}</a>
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ 'personnel.email-header' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="highlight" *ngIf="element.highlight?.email else noHighlight" [innerHtml]="element.highlight?.email[0]"></div>
        <ng-template #noHighlight>
          {{ element.fields.email[0] }}
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="phoneNumber">
      <th mat-header-cell *matHeaderCellDef>{{ 'personnel.phone-number-header' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="highlight" *ngIf="element.highlight?.phoneNumber else noHighlight" [innerHtml]="element.highlight?.phoneNumber[0]"></div>
        <ng-template #noHighlight>
          <div *ngIf="element.fields.phoneNumber else noPhoneNumber">
            {{ element.fields.phoneNumber[0] }}
          </div>
          <ng-template #noPhoneNumber>
            {{ 'personnel.none' | translate }}
          </ng-template>
        </ng-template>
      </td>
    </ng-container>
    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef>{{ 'personnel.roles-header' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="(element.fields.roles?.length || 0) === 0">{{ 'roles.no-roles' | translate }}</div>
        <div matLine *ngIf="element.fields.roles?.length > 0" fxLayoutAlign="start center">
          <mat-icon *ngIf="element.fields.roles.includes('userManager')" [matTooltip]="'roles.userManager' | translate"
            color="primary">supervisor_account</mat-icon>
          <mat-icon *ngIf="element.fields.roles.includes('operations')" [matTooltip]="'roles.operations' | translate"
            color="primary">computer</mat-icon>
          <mat-icon *ngIf="element.fields.roles.includes('appUser')" [matTooltip]="'roles.appUser' | translate"
            color="primary">smartphone</mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="emptyTable" *ngIf="(totalCount$ | async) === 0">
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
        <div class="no-users" *ngIf="!(searchFormGroup.controls.searchTerm.value) else queryNoResults">
          {{ 'personnel.no-users' | translate }}
        </div>
        <ng-template #queryNoResults>
          <div class="no-users">
            {{ 'personnel.no-query-results' | translate }}
          </div>
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="emptyTable$ | async" [ngClass]="hideIfPopulated$ | async"></tr>
  </table>
  <mat-paginator showFirstLastButtons="true" (page)="onPage($event)" [length]="totalCount$ | async"
    [pageSize]="pageSize$ | async" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
</div>
