import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FlexLayoutModule } from '../flex-layout';
import { MaterialModule } from '../material';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CreateNewUserComponent } from './components/create-new-user/create-new-user.component';
import { DomainBoundEmailComponent } from './components/domain-bound-email/domain-bound-email.component';
import { DomainNamesInputComponent } from './components/domain-names-input/domain-names-input.component';
import { EveryDayScheduleComponent } from './components/every-day-schedule/every-day-schedule.component';
import { GooglePlacesInputComponent } from './components/google-places-input/google-places-input.component';
import { MultipleTimesScheduleComponent } from './components/multiple-times-schedule/multiple-times-schedule.component';
import { OkDialogComponent } from './components/ok-dialog/ok-dialog.component';
import { OrganisationUsersTableComponent } from './components/organisation-users-table/organisation-users-table.component';
import { ScheduleInputComponent } from './components/schedule-input/schedule-input.component';
import { SpecificDaysScheduleComponent } from './components/specific-days-schedule/specific-days-schedule.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SubdomainInputComponent } from './components/subdomain-input/subdomain-input.component';
import { ThemeEmitterComponent } from './components/theme-emitter/theme-emitter.component';
import { UserRoleManagementComponent } from './components/user-role-management/user-role-management.component';
import { UserSuspensionComponent } from './components/user-suspension/user-suspension.component';
import { ScheduleTextPipe } from './pipes/schedule-text.pipe';
import {
  TextInputConfirmationDialogComponent
} from './components/text-input-confirmation-dialog/text-input-confirmation-dialog.component';
import { PasswordResetEmailsTableComponent } from './components/password-reset-emails-table/password-reset-emails-table.component';
import { MomentModule } from 'ngx-moment';
import { PluralisePipe } from './pipes/pluralise.pipe';
import {
  PushNotificationEventsTableComponent
} from './components/push-notification-events-table/push-notification-events-table.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UserDevicesTableComponent } from './components/user-devices-table/user-devices-table.component';
import { WaveComponent } from './components/wave/wave.component';
import { OrganisationMemberComponent } from './components/organisation-member/organisation-member.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { environment } from 'src/environments/environment';
import { HighlightListComponent } from './components/highlight-list/highlight-list.component';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { ClipboardTextComponent } from './components/clipboard-text/clipboard-text.component';
import { HighlightTextComponent } from './components/highlight-text/highlight-text.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { BooleanCellComponent } from './components/boolean-cell/boolean-cell.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from 'src/app/reducers';

export const SAFE_DATE_FORMATS: MatDateFormats = {
  parse: {
      dateInput: 'L',
  },
  display: {
      dateInput: 'L',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({ 
  declarations: [
    ThemeEmitterComponent,
    DomainBoundEmailComponent,
    ConfirmationDialogComponent,
    OkDialogComponent,
    CreateNewUserComponent,
    OrganisationUsersTableComponent,
    UserRoleManagementComponent,
    UserSuspensionComponent,
    DomainNamesInputComponent,
    GooglePlacesInputComponent,
    SpinnerComponent,
    SpinnerOverlayComponent,
    EveryDayScheduleComponent,
    MultipleTimesScheduleComponent,
    SpecificDaysScheduleComponent,
    ScheduleInputComponent,
    ScheduleTextPipe,
    SubdomainInputComponent,
    UserSuspensionComponent,
    TextInputConfirmationDialogComponent,
    PasswordResetEmailsTableComponent,
    PluralisePipe,
    PushNotificationEventsTableComponent,
    UploadTaskComponent,
    UserDevicesTableComponent,
    WaveComponent,
    OrganisationMemberComponent,
    HighlightListComponent,
    ClipboardTextComponent,
    HighlightTextComponent,
    FileUploadComponent,
    UploadTaskComponent,
    BooleanCellComponent,
  ],
  exports: [
    DomainBoundEmailComponent,
    CreateNewUserComponent,
    ThemeEmitterComponent,
    OrganisationUsersTableComponent,
    UserRoleManagementComponent,
    UserSuspensionComponent,
    DomainNamesInputComponent,
    GooglePlacesInputComponent,
    SpinnerComponent,
    PluralisePipe,
    EveryDayScheduleComponent,
    MultipleTimesScheduleComponent,
    SpecificDaysScheduleComponent,
    SubdomainInputComponent,
    ScheduleInputComponent,
    ScheduleTextPipe,
    PasswordResetEmailsTableComponent,
    PushNotificationEventsTableComponent,
    UploadTaskComponent,
    NgxFileDropModule,
    GoogleMapsModule,
    UserDevicesTableComponent,
    OrganisationMemberComponent,
    HighlightListComponent,
    ClipboardTextComponent,
    HighlightTextComponent,
    FileUploadComponent,
    UploadTaskComponent,
    BooleanCellComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MomentModule,
    FlexLayoutModule,
    MaterialModule,
    NgxSpinnerModule,
    RouterModule,
    TranslateModule,
    NgxFileDropModule,
    MatMomentDateModule,
    ClipboardModule,
  ], 
  providers: [
    { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_LOCALE, useValue: environment.defaultLocale },
    { provide: LOCALE_ID, useValue: environment.defaultLocale },
    { provide: MAT_DATE_FORMATS, useValue: SAFE_DATE_FORMATS },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ]
})
export class CustomModule { }
