import { Injectable } from '@angular/core';
import { Observable, from, combineLatest } from 'rxjs';
import { Account, PasswordResetEmail, PushNotificationEvent } from 'src/app/models/accounts';
import { map, switchMap } from 'rxjs/operators';
import { FacilityDto, OrganisationMembershipDto } from '../data/organisation.dto';
import { Facility, OrganisationHeader, OrganisationRole, SystemRole } from 'src/app/models/organisation';
import { Page, PageRequest } from 'src/app/models/page-result';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { Auth } from '@angular/fire/auth';
import { Firestore } from '@angular/fire/firestore';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';

export type PasswordResetEmailsRequest = PageRequest & {
  direction: 'asc' | 'desc';
  accountId: string;
  orderBy: 'sentUtc' | 'statusCode';
};

export type PushNotificationEventsRequest = PageRequest & {
  direction: 'asc' | 'desc';
  accountId: string;
  orderBy: 'deviceOpenedUtc' | 'opened';
};

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private passwordResetEmailsFunction: HttpsCallable<any, any>;
  private pushNotificationEventsFunction: HttpsCallable<any, any>;

  constructor(
    functions: Functions,
    private auth: Auth,
    private firestore: Firestore,
    functionsService: FunctionsService,
    private firestoreService: FirestoreService,
  ) {
    const { httpsCallable } = functionsService;
    this.passwordResetEmailsFunction = httpsCallable(functions, 'passwordResetEmailsGen2');
    this.pushNotificationEventsFunction = httpsCallable(functions, 'pushNotificationEventsGen2');
  }


  public getOrganisations(accountId: string): Observable<OrganisationHeader[]> {
    const { docData, doc, query, where, collection, collectionData } = this.firestoreService;
    const toOrganisation = (membershipDto: OrganisationMembershipDto) => ({
      organisationId: membershipDto.organisationId,
      name: membershipDto.organisationName,
      organisationMembershipId: membershipDto.id,
      isSuspended: !!membershipDto.suspension
    } as OrganisationHeader);
    const account$ = docData(doc(this.firestore, `accounts/${accountId}`));
    const membershipsQuery = query(
      collection(this.firestore, 'organisationMemberships'),
      where('accountId', '==', accountId),
    );
    const memberships$ = collectionData(membershipsQuery);
    return combineLatest([account$, memberships$]).pipe(
      map(([account, memberships]) => memberships.filter(m => {
        return m.roles.includes(OrganisationRole.supportManager)
          || m.roles.includes(OrganisationRole.support)
          || account.systemRoles.includes(SystemRole.systemAdmin);
      }).map(toOrganisation)));
  }

  public passwordResetEmails(page: PasswordResetEmailsRequest): Observable<Page<PasswordResetEmail>> {
    return from(this.passwordResetEmailsFunction(page)).pipe(map(x => x.data));
  }

  public pushNotificationEvents(page: PushNotificationEventsRequest): Observable<Page<PushNotificationEvent>> {
    return from(this.pushNotificationEventsFunction(page)).pipe(map(x => x.data));
  }

  private toAccount = (accountDto: any, internalDomainDtos: any[], externalDomainDtos: any[]) => {
    const domain = internalDomainDtos.length > 0 ? internalDomainDtos[0].domain : externalDomainDtos[0].domain;
    const localTenantId = internalDomainDtos.length > 0 ? internalDomainDtos[0].localTenantId : null;
    return ({
      accountId: accountDto.id,
      username: accountDto.username,
      domain,
      localTenantId,
      passwordResetEmailCount: accountDto.passwordResetEmailCount || 0,
      pushNotificationEventCount: accountDto.pushNotificationEventCount || 0,
      firstCheckInCreatedUtc: accountDto.firstCheckInCreatedUtc?.toDate() || null,
      lastCheckInCreatedUtc: accountDto.lastCheckInCreatedUtc?.toDate() || null
    } as Account);
  }

  public getAccountFromUid(uid: string): Observable<Account[]> {
    const { query, collection, where, collectionGroup, collectionData } = this.firestoreService;
    const accountDto$ = collectionData(query(
      collection(this.firestore, 'accounts'),
      where('firebaseUid', '==', uid))
    );
    return accountDto$.pipe(switchMap(accountDtos => {
      if (accountDtos.length === 0) { return []; }
      const internalDomains$ = collectionData(query(
        collectionGroup(this.firestore, 'internalDomains'),
        where('id', 'in', accountDtos.map(a => a.domainId)))
      );
      const externalDomains$ = collectionData(query(
        collectionGroup(this.firestore, 'externalDomains'),
        where('id', 'in', accountDtos.map(a => a.domainId)))
      );
      const domains$ = combineLatest([internalDomains$, externalDomains$]);
      return domains$.pipe(map(([internalDomains, externalDomains]) => accountDtos.flatMap(
        accountDto => internalDomains.map(domainDto => this.toAccount(accountDto, [domainDto], externalDomains))
      )));
    }));
  }

  public getAccountFromAccountId(accountId: string): Observable<Account> {
    const { query, doc, docData, where, collectionGroup, collectionData } = this.firestoreService;
    const accountDto$ = docData(doc(this.firestore, `accounts/${accountId}`));
    return accountDto$.pipe(switchMap(accountDto => {
      const internalDomains$ = collectionData(query(
        collectionGroup(this.firestore, 'internalDomains'),
        where('id', '==', accountDto.domainId))
      );
      const externalDomains$ = collectionData(query(
        collectionGroup(this.firestore, 'externalDomains'),
        where('id', '==', accountDto.domainId))
      );
      const domains$ = combineLatest([internalDomains$, externalDomains$]);
      return domains$.pipe(map(([internalDomainDtos, externalDomainDtos]) =>
        this.toAccount(accountDto, internalDomainDtos, externalDomainDtos)));
    }));
  }

  public getHeadOffice(organisationId: string): Observable<Facility> {
    const { doc, docData } = this.firestoreService;
    const toFacility = (dto: FacilityDto) => ({
      id: dto.id,
      name: dto.name,
      place: dto.place,
      activeSignInCount: dto.activeSignInCount || 0,
      totalSignInCount: dto.totalSignInCount || 0,
    });
    const organisation$ = docData(doc(this.firestore, `organisations/${organisationId}`));
    return organisation$.pipe(switchMap(o => {
      const headOfficeId = o.headOfficeId;
      const facility$ = docData(doc(this.firestore, `organisations/${organisationId}/facilities/${headOfficeId}`));
      return facility$.pipe(map(toFacility));
    }));
  }

  public signOut(): Observable<any> {
    return from(this.auth.signOut());
  }
}
