import { createAction, props } from '@ngrx/store';
import { NewSchedule, HealthCheckSchedule } from 'src/app/models/schedule';
import { SystemHealthAggregate } from 'src/app/models/system-health-aggregate';

export const getSystemHealthRequest = createAction(
  '[SystemHealth] Get system health request',
  props<{iso8601Date: string}>()
);

export const getSystemHealthSuccess = createAction(
  '[SystemHealth] Get system health success',
  props<{ systemHealth: SystemHealthAggregate[] }>()
);

export const getSystemHealthFailure = createAction(
  '[SystemHealth] Get system health failure',
  props<{ error: any }>()
);

export const listCommandsRequest = createAction(
  '[SystemHealth] List commands request'
);

export const listCommandsSuccess = createAction(
  '[SystemHealth] List commands success',
  props<{ commands: string[] }>()
);

export const listCommandsFailure = createAction(
  '[SystemHealth] List commands failure',
  props<{ error: any }>()
);

export const executeCommandRequest = createAction(
  '[SystemHealth] Execute command request',
  props<{ command: string }>()
);

export const executeCommandSuccess = createAction(
  '[SystemHealth] Execute command success',
);

export const executeCommandFailure = createAction(
  '[SystemHealth] Execute command failure',
  props<{ error: any }>()
);

export const listRepairScriptsRequest = createAction(
  '[SystemHealth] List repair scripts request'
);

export const listRepairScriptsSuccess = createAction(
  '[SystemHealth] List repair scripts success',
  props<{ repairScripts: string[] }>()
);

export const listRepairScriptsFailure = createAction(
  '[SystemHealth] List repair scripts failure',
  props<{ error: any }>()
);

export const executeRepairScriptRequest = createAction(
  '[SystemHealth] Execute repair script request',
  props<{ repairScript: string }>()
);

export const executeRepairScriptSuccess = createAction(
  '[SystemHealth] Execute repair script success',
);

export const executeRepairScriptFailure = createAction(
  '[SystemHealth] Execute repair script failure',
  props<{ error: any }>()
);

export const listSchedulesRequest = createAction(
  '[SystemHealth] List schedules request'
);

export const listSchedulesSuccess = createAction(
  '[SystemHealth] List schedules success',
  props<{ schedules: HealthCheckSchedule[] }>()
);

export const listSchedulesFailure = createAction(
  '[SystemHealth] List schedules failure',
  props<{ error: any }>()
);

export const createScheduleRequest = createAction(
  '[SystemHealth] Create schedule request',
  props<{ schedule: NewSchedule }>()
);

export const createScheduleSuccess = createAction(
  '[SystemHealth] Create schedule success'
);

export const createScheduleFailure = createAction(
  '[SystemHealth] Create schedule failure',
  props<{ error: any }>()
);

export const updateScheduleRequest = createAction(
  '[SystemHealth] Update schedule request',
  props<{ schedule: NewSchedule }>()
);

export const updateScheduleSuccess = createAction(
  '[SystemHealth] Update schedule success'
);

export const updateScheduleFailure = createAction(
  '[SystemHealth] Update schedule failure',
  props<{ error: any }>()
);

export const deleteScheduleRequest = createAction(
  '[SystemHealth] Delete schedule request',
  props<{ scheduleId: string; }>(),
);

export const deleteScheduleSuccess = createAction(
  '[SystemHealth] Delete schedule success',
  props<{ scheduleId: string }>(),
);

export const deleteScheduleFailure = createAction(
  '[SystemHealth] Delete schedule failure',
  props<{ error: any; scheduleId: string; }>(),
);
