import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as utilities from 'src/app/utilities';
import * as validation from 'src/app/constants/validation';
import * as fromConnect from 'src/app/features/connect/connect';
import { ActivatedRoute } from '@angular/router';
import { filter, map, startWith } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'safe-enter-email',
  templateUrl: './enter-email.component.html',
  styleUrls: ['./enter-email.component.scss']
})
export class EnterEmailComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  public appName$: Observable<{value: string}>;
  public isWorking$: Observable<boolean>;
  public canEnterEmail$: Observable<boolean>;

  @Input() signInColor: string;
  @Output() emailEntered = new EventEmitter<string>();
  @Output() emailChanged = new EventEmitter();

  public enterEmailFormGroup: UntypedFormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.pattern(validation.patterns.email)]],
  });

  constructor(
    private store: Store,
    translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
  ) {
    this.appName$ = translateService.get(environment.dashboardTitle).pipe(map((x: string) => ({ value: x })));
  }

  ngOnInit(): void {
    this.isWorking$ = this.store.pipe(select(fromConnect.selectIsWorking));
    this.canEnterEmail$ = combineLatest([this.isWorking$,
      this.enterEmailFormGroup.statusChanges.pipe(startWith(this.enterEmailFormGroup.status))]).pipe(
      map(([isWorking, status]) => !isWorking && status === 'VALID')
    );
    utilities.subscribe(this.route.queryParams.pipe(map(p => p.email), filter(x => !!x)), this.destroy, email => {
      this.enterEmailFormGroup.controls.email.setValue(email);
    });
  }

  public enterEmail() {
    const email = this.enterEmailFormGroup.controls.email.value;
    this.emailEntered.emit(email);
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
