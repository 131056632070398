import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
import { Functions, HttpsCallable } from '@angular/fire/functions';
import { Auth } from '@angular/fire/auth';
import { FunctionsService } from 'src/app/shared/custom/service/functions.service';
import { AuthService } from 'src/app/shared/custom/service/auth.service';
import { LocalTenantDomain } from '../shared/custom/models/domains';

@Injectable({
  providedIn: 'root'
})
export class ConnectService {
  private verifySignInFunction: HttpsCallable<any, { passwordCreationRequired: boolean; accountCreationRequired: boolean; }>;
  private sendConsolePasswordSetupEmailFunction: HttpsCallable<any, any>;
  private sendConsolePasswordResetEmailFunction: HttpsCallable<any, any>;

  constructor(
    functions: Functions,
    private auth: Auth,
    private authService: AuthService,
    functionsService: FunctionsService,
  ) {
    const { httpsCallable } = functionsService;
    this.verifySignInFunction = httpsCallable(functions, 'verifySignInGen2');
    this.sendConsolePasswordSetupEmailFunction = httpsCallable(functions, 'sendConsolePasswordSetupEmailGen2');
    this.sendConsolePasswordResetEmailFunction = httpsCallable(functions, 'sendConsolePasswordResetEmailGen2');
  }

  public verifyEmail(
    email: string,
    localTenantDomains: LocalTenantDomain[],
    localDomain: string,
  ): Observable<{ accountCreationRequired: boolean; passwordCreationRequired: boolean, redirectToDomain: string | null }> {
    const emailParts = email.split('@');
    const emailDomain = emailParts[1];
    const matchingLocalTenantDomain = localTenantDomains.find(localTenantDomain => localTenantDomain.emailDomain === emailDomain);
    if (!matchingLocalTenantDomain && localDomain !== environment.consoleDomain) {
      return of({ passwordCreationRequired: false, accountCreationRequired: false, redirectToDomain: environment.consoleDomain }).pipe(take(1));
    } else if (matchingLocalTenantDomain && localDomain !== matchingLocalTenantDomain.consoleDomain) {
      return of({ passwordCreationRequired: false, accountCreationRequired: false, redirectToDomain: matchingLocalTenantDomain.consoleDomain }).pipe(take(1));
    }

    const cloudTenantId = this.auth.tenantId;
    return from(this.verifySignInFunction({ email, cloudTenantId })).pipe(
      map(x => x.data),
      map(x => ({ ...x, redirectToDomain: null })), 
      take(1)
    );
  }

  public passwordSignIn(email: string, password: string): Observable<any> {
    const { signInWithEmailAndPassword } = this.authService;
    const promise = signInWithEmailAndPassword(this.auth, email, password);
    return from(promise);
  }

  public resetPassword(email: string, cloudTenantId: string | null): Observable<any> {
    return from(this.sendConsolePasswordResetEmailFunction({ email, cloudTenantId })).pipe(map(x => x.data), take(1));
  }

  public setUpAccount(email: string, cloudTenantId: string | null): Observable<any> {
    return from(this.sendConsolePasswordSetupEmailFunction({ email, cloudTenantId })).pipe(map(x => x.data), take(1));
  }
}
