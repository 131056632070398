import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { MomentModule } from 'ngx-moment';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { APP_ROUTING_MODULE } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { ConnectComponent } from './components/connect/connect.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SuspendedComponent } from './components/suspended/suspended.component';
import { AppEffects } from './features/app/app.effects';
import * as fromApp from './features/app/app';
import { ConnectEffects } from './features/connect/connect.effects';
import * as fromConnect from './features/connect/connect.reducer';
import { SystemHealthEffects } from './features/system-health/system-health.effects';
import * as fromSystemHealth from './features/system-health/system-health.reducer';
import { AccountEffects } from './modules/console/features/account/account.effects';
import * as fromAccount from './modules/console/features/account/account.reducer';
import { ProfileEffects } from './shared/custom/features/profile/profile.effects';
import * as fromProfile from './shared/custom/features/profile/profile.reducer';
import { metaReducers, reducers } from './reducers';
import { FlexLayoutModule, MaterialModule } from './shared';
import { CustomModule } from './shared/custom/custom.module';
import { WINDOW_PROVIDERS } from './window.providers';
import { EnterEmailComponent } from './components/enter-email/enter-email.component';
import { EnterPasswordComponent } from './components/enter-password/enter-password.component';
import { SendAccountSetupEmailComponent } from './components/send-account-setup-email/send-account-setup-email.component';
import { SignedInComponent } from './components/signed-in/signed-in.component';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { LocaleDropdownComponent } from './components/locale-dropdown/locale-dropdown.component';
import { ProfileDropdownComponent } from './components/profile-dropdown/profile-dropdown.component';
import * as msal from '@azure/msal-browser';
import { TenantConfigService } from './shared/custom/service/tenant-config.service';
import { ActiveDirectoryConfigService } from './shared/custom/service/active-directory-config.service';
import { AccountCreationRequiredComponent } from './components/account-creation-required/account-creation-required.component';
import { VersionHistoryComponent } from './components/version-history/version-history.component';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initialise(
  activeDirectoryConfigService: ActiveDirectoryConfigService, 
  tenantConfigService: TenantConfigService, 
  auth: Auth
) {
  return async () => {
    await tenantConfigService.initialise();
    const tenantState = tenantConfigService.tenantState;
    auth.tenantId = tenantState.cloudTenantId;
    const tenantDomain = tenantState.localTenantDomain;
    if (!tenantDomain) { return; }
    const redirectUri = `https://${tenantState.localTenantDomain.consoleDomain}`;
    const msalConfig = { auth: {
      clientId: tenantDomain.oidcClientId,
      authority: tenantDomain.oidcIssuerUrl.replace('/v2.0', ''),
      redirectUri,
    } };
    const msalApp = new msal.PublicClientApplication(msalConfig);
    msalApp.initialize();
    activeDirectoryConfigService.initialise(msalApp);
  };
}

@NgModule({ 
  declarations: [
    AppComponent,
    ConnectComponent,
    ResetPasswordComponent,
    AboutComponent,
    SuspendedComponent,
    EnterEmailComponent,
    EnterPasswordComponent,
    SendAccountSetupEmailComponent,
    SignedInComponent,
    LocaleDropdownComponent,
    ProfileDropdownComponent,
    AccountCreationRequiredComponent,
    VersionHistoryComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    OverlayModule,
    MomentModule,
    PortalModule,
    CustomModule,
    APP_ROUTING_MODULE,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forFeature(fromConnect.connectFeatureKey, fromConnect.reducer),
    StoreModule.forFeature(fromApp.appFeatureKey, fromApp.reducer),
    StoreModule.forFeature(fromSystemHealth.systemHealthFeatureKey, fromSystemHealth.reducer),
    StoreModule.forFeature(fromAccount.accountFeatureKey, fromAccount.reducer),
    StoreModule.forFeature(fromProfile.profileFeatureKey, fromProfile.reducer),
    EffectsModule.forRoot([ConnectEffects, AppEffects, SystemHealthEffects, AccountEffects, ProfileEffects]),
  ], 
  providers: [
    WINDOW_PROVIDERS,
    CookieService,
    {
        provide: APP_INITIALIZER,
        useFactory: initialise,
        deps: [ActiveDirectoryConfigService, TenantConfigService, Auth],
        multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFunctions(() => getFunctions(getApp(), 'europe-west2')),
    provideFirestore(() => getFirestore(getApp())),
    provideStorage(() => getStorage(getApp())),
    provideMessaging(() => getMessaging(getApp())),
    provideAuth(() => getAuth(getApp())),
  ]
})
export class AppModule { }
