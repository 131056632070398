<div class="push-notification-events" fxLayout="column">
  <ng-container *ngIf="eventsDataSource.loading$ | async; then loading else table"></ng-container>
  <ng-template #loading>
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 0 0">
      <safe-spinner></safe-spinner>
    </div>
  </ng-template>
  <ng-template #table>
    <table mat-table class="push-notification-events-table mat-elevation-z8" [dataSource]="eventsDataSource"
      [hidden]="eventsDataSource?.loading$ | async">
      <ng-container matColumnDef="deviceOpenedUtc">
        <th mat-header-cell *matHeaderCellDef>{{ 'push-notification-events.headers.opened' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.deviceOpenedUtc | amLocale:currentLocale | amDateFormat: 'LLLL' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>{{ 'push-notification-events.headers.title' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.title }}
        </td>
      </ng-container>
      <ng-container matColumnDef="opened">
        <th mat-header-cell *matHeaderCellDef>{{ 'push-notification-events.headers.app-state' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ ('push-notification-events.opened.' + element.opened) | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="messageId">
        <th mat-header-cell *matHeaderCellDef>{{ 'push-notification-events.headers.message-id' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.messageId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="emptyTable" *ngIf="(totalCount$ | async) === 0">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
          <div class="no-events">{{ 'push-notification-events.no-events' | translate }}</div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="emptyTable$ | async" [ngClass]="hideIfPopulated$ | async"></tr>
    </table>
    <mat-paginator showFirstLastButtons="true" (page)="onPage($event)" [length]="totalCount$ | async"
      [pageSize]="pageSize$ | async" [pageSizeOptions]="[10, 25, 50, 100]">
    </mat-paginator>
  </ng-template>
</div>
