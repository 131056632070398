<form class="enter-email-form" [formGroup]="enterEmailFormGroup" (ngSubmit)="enterEmail()">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>{{ 'sign-in.safe-sign-in' | translate:(appName$ | async) }}</mat-card-title>
      <mat-card-subtitle>{{ 'sign-in.enter-your-email' | translate }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div fxLayout="column" fxLayoutAlign="center">
        <mat-form-field>
          <mat-label>{{ 'sign-in.email' | translate }}</mat-label>
          <input matInput #signInEmail formControlName="email" class="email" required autocomplete="email" (input)="emailChanged.emit()" />
          <mat-icon matIconSuffix [color]="signInColor">email</mat-icon>
          <mat-error class="email-error"
            *ngIf="enterEmailFormGroup.controls.email?.hasError('required')">
            {{ 'sign-in.email-error.required' | translate }}
          </mat-error>
          <mat-error
            *ngIf="enterEmailFormGroup.controls.email?.hasError('pattern')">
            {{ 'sign-in.email-error.invalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-progress-bar *ngIf="isWorking$ | async" mode="indeterminate"></mat-progress-bar>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end">
      <button mat-raised-button type="submit" class="space-top sign-in" [color]="signInColor" [disabled]="(canEnterEmail$ | async) === false">
        <div class="mat-line">
          <div>{{ 'sign-in.next' | translate }}</div>
          <mat-icon>chevron_right</mat-icon>
        </div>
      </button>
    </mat-card-actions>
  </mat-card>
</form>
