import { createAction, props } from '@ngrx/store';
import { CreateOrganisationUser, OrganisationMember, OrganisationUserCreation } from 'src/app/models/organisation';
import { Domain } from 'src/app/shared/custom/models/domains';

export const createOrganisationUserReset = createAction(
  '[Users] Create organisation user reset',
);

export const createOrganisationUserRequest = createAction(
  '[Users] Create organisation user request',
  props<CreateOrganisationUser>()
);

export const createOrganisationUserSuccess = createAction(
  '[Users] Create organisation user success',
  props<OrganisationUserCreation>()
);

export const createOrganisationUserFailure = createAction(
  '[Users] Create organisation user failure',
  props<{ error: any }>()
);

export const archiveMemberReset = createAction(
  '[Users] Archive member reset',
);

export const archiveMemberRequest = createAction(
  '[Users] Archive member request',
  props<{ organisationId: string, organisationMembershipId: string }>()
);

export const archiveMemberSuccess = createAction(
  '[Users] Archive member success',
);

export const archiveMemberFailure = createAction(
  '[Users] Archive member failure',
  props<{ error: any }>()
);

export const getOrganisationUserCountRequest = createAction(
  '[Users] Get organisation user count request',
  props<{ organisationId: string }>()
);

export const getOrganisationUserCountResult = createAction(
  '[Users] Get organisation user count result',
  props<{ userCount: number }>()
);

export const getOrganisationUserCountFailure = createAction(
  '[Users] Get organisation user count failure',
  props<{ error: any }>()
);

export const getOrganisationDomainsRequest = createAction(
  '[Users] Get organisation domains request',
  props<{ organisationId: string }>()
);

export const getOrganisationDomainsResult = createAction(
  '[Users] Get organisation domains result',
  props<{ domains: Domain[] }>()
);

export const getOrganisationDomainsFailure = createAction(
  '[Users] Get organisation domains failure',
  props<{ error: any }>()
);

export const getMemberRequest = createAction(
  '[Users] Get member request',
  props<{ organisationMembershipId: string }>()
);

export const getMemberResponse = createAction(
  '[Users] Get member response',
  props<{ member: OrganisationMember }>()
);

export const getMemberFailure = createAction(
  '[Users] Get member failure',
  props<{ error: any }>()
);

export const suspendMemberReset = createAction(
  '[Users] Suspend member reset',
);

export const suspendMemberRequest = createAction(
  '[Users] Suspend member request',
  props<{ organisationId: string, organisationMembershipId: string }>()
);

export const suspendMemberSuccess = createAction(
  '[Users] Suspend member success',
);

export const suspendMemberFailure = createAction(
  '[Users] Suspend member failure',
  props<{ error: any }>()
);

export const resumeMemberReset = createAction(
  '[Users] Resume member reset',
);

export const resumeMemberRequest = createAction(
  '[Users] Resume member request',
  props<{ organisationId: string, organisationMembershipId: string }>()
);

export const resumeMemberSuccess = createAction(
  '[Users] Resume member success',
);

export const resumeMemberFailure = createAction(
  '[Users] Resume member failure',
  props<{ error: any }>()
);
