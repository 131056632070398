<div fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="1em">
  <div>{{ 'scheduling.new-schedule-form.every' | translate }}</div>
  <ng-container *ngIf="unitOfTime==='minutes'; then minutes else hours"></ng-container>
  <mat-form-field>
    <mat-label>{{ 'scheduling.new-schedule-form.time-unit' | translate }}</mat-label>
    <mat-select class="unitOfTime" [(value)]="unitOfTime" (selectionChange)="resetAmountOfTime(); validateInput()" required>
      <mat-option value="minutes">{{ 'scheduling.new-schedule-form.minutes' | translate }}</mat-option>
      <mat-option value="hours">{{ 'scheduling.new-schedule-form.hours' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ 'scheduling.new-schedule-form.scope' | translate }}</mat-label>
    <mat-select class="scope" [(value)]="scope" (selectionChange)="validateInput()" required>
      <mat-option value="all day">{{ 'scheduling.new-schedule-form.all-day' | translate }}</mat-option>
      <mat-option value="restricted">{{ 'scheduling.new-schedule-form.from' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <ng-container *ngIf="scope==='restricted'; then restricted"></ng-container>
  <button class="updateSelected" mat-stroked-button type="button" (click)="updateSelected()" [disabled]="!isValid">
      {{ 'scheduling.new-schedule-form.update' | translate }}
  </button>
</div>

<ng-template #minutes>
  <mat-form-field>
    <mat-label>{{ 'scheduling.new-schedule-form.number-of-minutes' | translate }}</mat-label>
    <mat-select class="minutes" [(value)]="amountOfTime" (selectionChange)="validateInput()" required>
      <mat-option class="minutes-05" value="5">5</mat-option>
      <mat-option class="minutes-10" value="10">10</mat-option>
      <mat-option class="minutes-15" value="15">15</mat-option>
      <mat-option class="minutes-20" value="20">20</mat-option>
      <mat-option class="minutes-30" value="30">30</mat-option>
      <mat-option class="minutes-45" value="45">45</mat-option>
      <mat-option class="minutes-60" value="60">60</mat-option>
      <mat-option class="minutes-90" value="90">90</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #hours>
  <mat-form-field>
    <mat-label>{{ 'scheduling.new-schedule-form.number-of-hours' | translate }}</mat-label>
    <mat-select class="hours" [(value)]="amountOfTime" (selectionChange)="validateInput()" required>
      <mat-option value="1">1</mat-option>
      <mat-option value="2">2</mat-option>
      <mat-option value="3">3</mat-option>
      <mat-option value="4">4</mat-option>
      <mat-option value="6">6</mat-option>
      <mat-option value="8">8</mat-option>
      <mat-option value="12">12</mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #restricted>
  <mat-form-field>
    <mat-label>{{ 'scheduling.new-schedule-form.from' | translate }}</mat-label>
    <input matInput type="time" placeholder="HH:mm" required [(ngModel)]="from" (input)="validateInput()" class="from" />
    <mat-hint>{{ 'scheduling.new-schedule-form.from-hint' | translate }}</mat-hint>
  </mat-form-field>
  <div>{{ 'scheduling.new-schedule-form.to' | translate }}</div>
  <mat-form-field>
    <mat-label>{{ 'scheduling.new-schedule-form.to' | translate }}</mat-label>
    <input matInput type="time" placeholder="HH:mm" required [(ngModel)]="to" (input)="validateInput()" class="to" />
    <mat-hint>{{ 'scheduling.new-schedule-form.to-hint' | translate }}</mat-hint>
  </mat-form-field>
</ng-template>
