import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, take, takeUntil, tap } from 'rxjs/operators';
import { PushNotificationEvent } from 'src/app/models/accounts';
import { Page } from 'src/app/models/page-result';
import * as utilities from 'src/app/utilities';
import * as fromApp from 'src/app/features/app/app';
import * as fromAccount from 'src/app/modules/console/features/account/account';
import { AccountService, PushNotificationEventsRequest } from 'src/app/modules/console/service/account.service';
import { environment } from 'src/environments/environment';
import { select, Store } from '@ngrx/store';

export class EventsDataSource implements DataSource<PushNotificationEvent> {
  private eventsSubject$ = new BehaviorSubject<PushNotificationEvent[]>([]);
  private loadingSubject$ = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean>;

  constructor(
    private accountService: AccountService,
    loaded: () => void,
    private totalCount$: Subject<number>,
    destroy$: Observable<void>,
    request$: Observable<PushNotificationEventsRequest>
  ) {
    request$.pipe(takeUntil(destroy$)).subscribe(this.loadEvents.bind(this));
    this.loading$ = this.loadingSubject$.asObservable().pipe(tap(loaded));
  }

  connect(_: CollectionViewer): Observable<PushNotificationEvent[]> {
    return this.eventsSubject$.asObservable();
  }

  disconnect(_: CollectionViewer): void { }

  complete() {
    this.eventsSubject$.complete();
    this.loadingSubject$.complete();
  }

  private loadEvents(request: PushNotificationEventsRequest) {
    this.loadingSubject$.next(true);
    this.accountService.pushNotificationEvents(request).pipe(
      take(1),
      catchError(() => of(Page.empty<PushNotificationEvent>())),
      finalize(() => this.loadingSubject$.next(false))
    ).subscribe(page => {
      this.eventsSubject$.next(page.page);
      this.totalCount$.next(page.totalCount);
    });
  }
}

@Component({
  selector: 'safe-push-notification-events-table',
  templateUrl: './push-notification-events-table.component.html',
  styleUrls: ['./push-notification-events-table.component.scss']
})
export class PushNotificationEventsTableComponent implements OnInit, OnDestroy {
  private destroy = new Subject<void>();
  @Input() accountId: string;
  private request: PushNotificationEventsRequest = {
    accountId: '',
    orderBy: 'deviceOpenedUtc',
    direction: 'desc',
    skip: 0,
    take: 10
  };
  private request$ = new BehaviorSubject<PushNotificationEventsRequest>(null);
  public totalCount$ = new BehaviorSubject<number>(0);
  public displayedColumns: string[] = ['deviceOpenedUtc', 'title', 'opened', 'messageId'];
  public eventsDataSource: EventsDataSource;
  public pageSize$: Observable<number>;
  public currentLocale = environment.defaultLocale;
  public hideIfPopulated$: Observable<string>;
  public emptyTable$: Observable<string[]>;

  @ViewChild(MatTable) table: MatTable<PushNotificationEvent>;

  constructor(
    private accountService: AccountService,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private store: Store,
  ) { }

  ngOnInit() {
    this.pageSize$ = this.request$.pipe(map(x => x.take));
    const pushNotificationEventCount$ = this.store.pipe(select(fromAccount.getPushNotificationEventCount(this.accountId)), tap(() => {
      this.ngZone.run(() => this.cdr.markForCheck());
    }));
    this.request = { ...this.request, accountId: this.accountId };
    this.request$.next(this.request);
    this.eventsDataSource = new EventsDataSource(
      this.accountService,
      () => this.ngZone.run(() => this.cdr.markForCheck()),
      this.totalCount$,
      this.destroy,
      this.request$.pipe(takeUntil(this.destroy)));
    const currentLocale$ = this.store.pipe(select(fromApp.selectCurrentLocale));
    utilities.subscribe(currentLocale$, this.destroy, locale => this.currentLocale = locale);
    utilities.subscribe(pushNotificationEventCount$, this.destroy, _ => this.request$.next(this.request));
    this.emptyTable$ = this.totalCount$.pipe(map(n => n === 0 ? ['emptyTable'] : []));
    this.hideIfPopulated$ = this.totalCount$.pipe(map(n => n === 0 ? null : 'hide'));
    this.store.dispatch(fromAccount.getAccountByIdRequest({ accountId: this.accountId }));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.request$.complete();
    this.totalCount$.complete();
    this.eventsDataSource.complete();
  }

  onPage(event: PageEvent) {
    this.request = {
      ...this.request,
      skip: event.pageIndex * event.pageSize,
      take: event.pageSize
    };
    this.request$.next(this.request);
  }
}
