import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import MessageFormat from 'messageformat';

@Pipe({
  name: 'pluralise'
})
export class PluralisePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
  ) { }

  transform(value: string, arg: any): string {
    const messageFormat = new MessageFormat(this.translate.currentLang);
    return messageFormat.compile(value)(arg);
  }
}
