<ng-container *ngIf="state$ | async as state">
    <button mat-mdc-button mat-button [matMenuTriggerFor]="locale"><span [ngClass]="flagCss(state.currentFlagCss)"></span></button>
    <mat-menu #locale="matMenu" class="locale-menu">
        <button mat-menu-item
            [class]="'locale ' + supportedLanguage.localeCode"
            (click)="chooseLocale(supportedLanguage.localeCode)"
            *ngFor="let supportedLanguage of state.languages">
            <mat-radio-group [value]="state.currentLocale">
                <mat-radio-button color="primary" [value]="supportedLanguage.localeCode">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
                        <span>{{ supportedLanguage.language | translate }}</span>
                        <span [ngClass]="flagCss(supportedLanguage.cssValue)"></span>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
        </button>
    </mat-menu>
</ng-container>
  