import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromConnect from 'src/app/features/connect/connect';
import * as fromAccount from 'src/app/modules/console/features/account/account';
import { Auth } from '@angular/fire/auth';
import { AuthService } from 'src/app/shared/custom/service/auth.service';
import { filter, from, map, of, switchMap, take, tap } from 'rxjs';
import { TenantConfigService } from '../shared/custom/service/tenant-config.service';

export const redirectPromiseGuard: CanActivateFn = () => {
  const auth = inject(Auth);
  const authService = inject(AuthService);
  const store = inject(Store);
  if (auth.currentUser) { return true; }
  const tenantState = inject(TenantConfigService).tenantState;
  if (!tenantState.localTenantDomain) { return of(true); }
  return from(authService.handleRedirectPromise()).pipe(switchMap(result => {
    if (!result) { return of(true); }
    store.dispatch(fromConnect.accessTokenSignInRequest({ accessToken: result.accessToken }));
    return store.pipe(
      select(fromConnect.selectAccessTokenSignInState),
      filter(x => x.complete), 
      map(() => true),
      tap(() => store.dispatch(fromAccount.chooseCurrentOrganisationRequest())),
      take(1));
  }));
};
