<form [action]="appUrl" method="POST" #refreshForm></form>
<ng-container *ngIf="mapsApiLoaded$ | async">
  <mat-toolbar color="primary" class="mat-elevation-z6">
    <mat-toolbar-row>
      <button mat-icon-button fxShow="true" fxHide.gt-xs="true">
        <mat-icon (click)="opened = !opened">menu</mat-icon>
      </button>
      <div class="mat-line space-between">
        <div class="seak-logo"></div>
        <a mat-mdc-button mat-button [routerLink]="'/'">
          <div class="mat-column">
            <div class="title">{{ title | translate }}</div>
            <div class="subtitle">{{ 'header.title.version-and-publisher' | translate:{ value: version } }}</div>
          </div>
        </a>
      </div>
      <span class="menu-spacer"></span>
      <div class="top-menu">
        <safe-locale-dropdown></safe-locale-dropdown>
        <safe-profile-dropdown *ngIf="isSignedIn$ | async"></safe-profile-dropdown>
        <a mat-button class="about-button" [routerLink]="'/about'"><mat-icon>info_outline</mat-icon></a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container class="main-sidenav-container">
    <mat-sidenav #sidenav
      [mode]="mode"
      [opened]="opened"
      fixedInViewport="true"
      fixedTopGap="56">
      <mat-nav-list>
        <a mat-list-item [routerLink]="'/about'">{{ 'header.about' | translate }}</a>
        <a mat-list-item (click)="opened = !opened" href="" mat-list-item>Close</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="app-content div-scrollable">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
