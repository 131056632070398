import { AbstractControl, AsyncValidator } from '@angular/forms';
import { Injectable } from '@angular/core';
import { map, catchError, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { OrganisationService } from '../service/organisation.service';

@Injectable({ providedIn: 'root' })
export class NewOrganisationUserValidator implements AsyncValidator {
  private _organisationId: string;
  public get organisationId(): string { return this._organisationId; }
  public set organisationId(value: string) { this._organisationId = value; }

  constructor(
    private organisationService: OrganisationService,
  ) { }

  validate(ctrl: AbstractControl) {
    const organisationService = this.organisationService;
    const organisationId = this.organisationId;
    return ctrl.valueChanges.pipe(
      switchMap(email =>
        organisationService.userAlreadyExists(email, organisationId).pipe(
          map(exists => {
            return (exists ? { userAlreadyExists: email } : null);
          }),
          catchError(() => of(null)),
      )),
      take(1),
    );
  }
}
