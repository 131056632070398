<div>
    <button mat-mdc-button mat-button [matMenuTriggerFor]="profile"><mat-icon>person_outline</mat-icon></button>
    <mat-menu #profile="matMenu" class="profile-menu">
        <button mat-menu-item (click)="signOut()" class="sign-out">
            <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
                <div><mat-icon>exit_to_app</mat-icon></div>     
                <div>{{ 'header.account.sign-out' | translate }}</div>
            </div>
        </button>
    </mat-menu>
</div>
  