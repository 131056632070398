import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TenantState } from 'src/app/shared/custom/models/domains';

@Component({
  selector: 'safe-account-creation-required',
  templateUrl: './account-creation-required.component.html',
  styleUrls: ['./account-creation-required.component.scss']
})
export class AccountCreationRequiredComponent {
  @Input() tenantState: TenantState;
  @Input() signInColor: string;
  @Output() previous = new EventEmitter();
}
