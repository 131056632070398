import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { of } from 'rxjs';
import { take, delay } from 'rxjs/operators';
import { ThemeEmitterComponent } from '../theme-emitter/theme-emitter.component';

@Component({
  selector: 'safe-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent implements AfterViewInit {
  public loadingColor = '';

  constructor() { }

  @ViewChild(ThemeEmitterComponent, { static: true }) theme: ThemeEmitterComponent;

  ngAfterViewInit(): void {
    of(this.theme.primaryColor).pipe(delay(0), take(1)).subscribe(c => this.loadingColor = c);
  }
}
