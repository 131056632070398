import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CronSchedule } from 'src/app/models/schedule';

@Component({
  selector: 'safe-every-day-schedule',
  templateUrl: './every-day-schedule.component.html',
  styleUrls: ['./every-day-schedule.component.scss']
})
export class EveryDayScheduleComponent {
  @Output() selected = new EventEmitter<CronSchedule>();
  public isValid = false;
  public timeOfDay: string;
  private hoursMinutesRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;

  public updateSelected() {
    const [hours, minutes] = this.timeOfDay.split(':');
    this.selected.emit({
      description: {
        type: 'daily',
        time: {
          hours: parseInt(hours, 10),
          minutes: parseInt(minutes, 10),
        }
      },
      value: `every day ${this.timeOfDay}`
    });
  }

  public validateInput = () => {
    this.isValid = !!this.timeOfDay && this.hoursMinutesRegex.test(this.timeOfDay);
  }
}
