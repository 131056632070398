import { Injectable } from '@angular/core';
import * as msal from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class ActiveDirectoryConfigService {
  private _app: msal.PublicClientApplication;

  public get app() { return this._app; }
  public initialise(value: msal.PublicClientApplication) { this._app = value; }
}
