import * as validation from '../constants/validation';
import { ArcIntegration } from './arc';
import { Place } from './geolocation';

export enum OrganisationRole {
  appUser = 'appUser',
  operations = 'operations',
  userManager = 'userManager',
  supportManager = 'supportManager',
  support = 'support',
}

export enum OrganisationRelationship {
  client = 'client',
  support = 'support'
}

export enum SystemRole {
  systemAdmin = 'systemAdmin',
}

export class RoleCounts {
  userManager: number;
  operations: number;
  appUser: number;
}

export class OrganisationMember {
  organisationMembershipId: string;
  email: string;
  givenName: string;
  familyName: string;
  suspension?: {
    createdByEmail: string | null,
    createdByMembershipId: string,
    serverCreatedUtc: Date
  };
}

export function sum(roleCountItems: RoleCounts[]): RoleCounts {
  return roleCountItems.reduce((acc, item) => ({
    userManager: acc.userManager + item.userManager,
    operations: acc.operations + item.operations,
    appUser: acc.appUser + item.appUser
  }), { userManager: 0, operations: 0, appUser: 0 });
}

export class UserDomain {
  id: string;
  domain: string;
}

export class RoleDescription {
  roleName: string;
}

export class RoleEnabled {
  role: string;
  enabled: boolean;
  canUpdate: boolean;
}

export type RoleState = RoleDescription & RoleEnabled;

export enum SubscriptionFrequency {
  monthly = 'monthly',
  quarterly = 'quarterly',
  annual = 'annual'
}

export class OrganisationSettings {
  purgeEmptyResolvedAlerts: boolean;
}

export class OrganisationConfiguration {
  locationPermissionNotifications: boolean;
  static default: OrganisationConfiguration = {
    locationPermissionNotifications: false,
  };
}

export enum OrganisationSize {
  micro = 'micro', // 1 - 9 employees
  small = 'small', // 10 - 49 employees
  medium = 'medium', // 50 - 250 employees
  enterprise = 'enterprise' // > 250 employees
}

export class Organisation {
  organisationId: string;
  name: string;
  size: OrganisationSize;
  configuration: OrganisationConfiguration;
  roleCounts: RoleCounts;
  activeUserCount: number;
  totalUserCount: number;
  arcIncidentCount: number;
  arcTestSignalCount: number;
  activeCheckInCount: number;
  totalCheckInCount: number;
  lastCheckInUtc: Date;
  sentMessageCount: number;
  liveAlertCount: number;
  liveIndividualAlertCount: number;
  liveAssetAlertCount: number;
  liveFacilityAlertCount: number;
  lastAlertUpdate: Date;
  liveLocationUserCount: number;
  liveLocationAssetCount: number;
  liveLocationFacilityCount: number;
  lastLiveLocationUpdateUtc: Date;
  ruleCount: number;
  userCsvUploadCount: number;
  licenseRoleLimits: RoleCounts;
  headOfficeId: string;
  startUtc: Date;
  lastDeviceUpdate: Date;
  membersUpdatedUtc: Date;
  arcIntegration: ArcIntegration | null;
  logo: StorageFile | null;
  settings: OrganisationSettings;
}

export class UserUploadError {
  id: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  roles: string;
  groups: string;
  error: string;
  failedUtc: Date;
}

export class UserCsvUpload {
  userCsvUploadId: string;
  uploadedUtc: Date;
  uploader: {
    organisationMembershipId: string;
    email: string;
    name: string;
  };
  storageFile: StorageFile;
  userUploadErrorCount: number;
}

export class Facility {
  id: string;
  name: string;
  place: Place;
  activeSignInCount: number;
  totalSignInCount: number;
}

export class OrganisationHeader {
  organisationId: string;
  name: string;
  organisationMembershipId: string;
  isSuspended: boolean;
}

export class InternalDomain {
  id: string;
  domain: string;
  localTenantId: string | null;
  oidcProviderId: string | null;
  samlProviderId: string | null;
  activeUserCount: number;
  totalUserCount: number;
}

export class OidcProvider {
  id: string;
  displayName: string;
  oidcProviderId: string;
  clientId: string;
  issuerUrl: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string | null;
  tokenEndpoint: string | null;
  autoCreateFieldUsers: boolean;
  autoCreateGroupIds: string[];
  serverCreatedUtc: Date;
  serverUpdatedUtc: Date;
}

export class SamlProvider {
  id: string;
  displayName: string;
  samlProviderId: string;
  idpEntityId: string;
  ssoUrl: string;
  x509CertificateSecretIds: string[];
  rpEntityId: string;
  serverCreatedUtc: Date;
  serverUpdatedUtc: Date;
}

export class ExternalDomain {
  id: string;
  domain: string;
  activeUserCount: number;
  totalUserCount: number;
}

export interface Domain {
  domain: string;
}

export class Domains {
  domains: Domain[];
  constructor(domains?: Domain[]) {
    this.domains = domains || [];
  }

  public add(domain: Domain): Domains | Error {
    if (validation.exactMatch(validation.patterns.domain, domain.domain)) {
      const augmentedDomains = [...this.domains.filter(x => x.domain !== domain.domain), domain];
      return new Domains(augmentedDomains);
    }

    return new Error(`${domain.domain} is not a valid domain.`);
  }

  public remove(domain: Domain) {
    const filteredDomains = this.domains.filter(d => d.domain !== domain.domain);
    return new Domains(filteredDomains);
  }

  get isEmpty() {
    return this.domains.length === 0;
  }
}

export class RoleUsage {
  roleName: string;
  count: number;
  limit: number;
}

export class NewManager {
  username: string;
  domain: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
}

export class NewOrganisationUser {
  email: string;
  phoneNumber: string;
  givenName: string;
  familyName: string;
  username: string;
  domainId: string;
}

export type  SingleMemberCreation = { email: string; organisationMembershipId: string; } & { kind: 'singleMember' };

export type CsvUploadCreation = StorageFile & { kind: 'csvUpload' };

export type OrganisationUserCreation = SingleMemberCreation | CsvUploadCreation;

export class CreateOperationsSubscription {
  organisationId: string;
  frequency: SubscriptionFrequency;
}

export class CreateOrganisationUser {
  organisationId: string;
  newUser: NewOrganisationUser | null;
  csvUpload: StorageFile | null;
  uploaderId: string;
}

export class NewOrganisation {
  name: string;
  relationship: OrganisationRelationship;
  roleCounts: RoleCounts;
  domains: string[];
  headOffice: Place;
}

export interface StorageFile {
  fileName: string;
  bucket: string;
  fullPath: string;
  downloadUrl: string;
  uploadedUtc: Date;
}

export class CreateOrganisation {
  csvUpload: StorageFile | null;
  manager: NewManager | null;
  organisation: NewOrganisation;
  uploaderId: string;
}

export class UploadOrganisationLogo {
  organisationId: string;
  logo: StorageFile;
}

export class CreateLocalTenant {
  organisationId: string;
  name: string;
  portalDomain: string;
  consoleDomain: string;
}

export class DeleteOidcProvider {
  id: string;
  organisationId: string;
  localTenantId: string;
}

export class DeleteSamlProvider {
  id: string;
  organisationId: string;
  localTenantId: string;
}

export class CreateOidcProvider {
  organisationId: string;
  localTenantId: string;
  displayName: string;
  oidcProviderId: string;
  clientId: string;
  issuerUrl: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string | null;
  tokenEndpoint: string | null;
  autoCreateFieldUsers: boolean;
}

export class UpdateOidcProvider {
  id: string;
  organisationId: string;
  localTenantId: string;
  displayName: string;
  oidcProviderId: string;
  clientId: string;
  issuerUrl: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string | null;
  tokenEndpoint: string | null;
  autoCreateFieldUsers: boolean;
}

export class CreateSamlProvider {
  organisationId: string;
  localTenantId: string;
  displayName: string;
  samlProviderId: string;
  idpEntityId: string;
  ssoUrl: string;
  x509CertificateSecretIds: string[];
  rpEntityId: string;
}

export class UpdateOidcProviderDomains {
  organisationId: string;
  localTenantId: string;
  oidcProviderId: string;
  domainIds: string[];
}

export class LocalTenant {
  tenantId: string;
  name: string;
  portalDomain: string;
  cloudTenantId: string | null;
  serverCreatedUtc: Date;
  serverUpdatedUtc: Date;
}

export class Provider {
  displayName: string;
  providerId: string;
}

export type OperationsSubscription = {
  subscriptionId: string;
  frequency: SubscriptionFrequency;
  cancelled: Date | null;
  start: Date;
};

export type FileValidationError = { type: 'image' | 'csv'; messageKey: string; };

export declare interface FileValidatorFn {
  (file: File): FileValidationError | null;
}

export const isImage: FileValidatorFn = (file: File) => {
  return validation.exactMatch(validation.patterns.imageType, file.type)
    ? null : { type: 'image', messageKey: 'file-upload.not-recognised-as-image' };
}

export const isCsv: FileValidatorFn = (file: File) => {
  const extension = file.name.split('.').pop();
  if (extension === 'csv') { return null; }
  return validation.exactMatch(validation.patterns.imageType, file.type)
    ? null : { type: 'csv', messageKey: 'file-upload.not-recognised-as-csv' };
}
