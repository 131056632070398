import { Inject, Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalTenantDomainDto } from '../data/system-dto';
import { PlatformVersion, ReleasePlatform } from '../models/system';
import { FirestoreService } from 'src/app/shared/custom/service/firestore.service';
import { PageRequest } from '../models/page-result';
import { OrderFields } from '../shared/custom/models/search';
import { WINDOW } from '../window.providers';
import { LocalTenantDomain } from '../shared/custom/models/domains';
import { PlatformVersionDto } from '../modules/console/data/system.dto';

export type VersionsRequest = PageRequest & {
  order: OrderFields[];
  search: {
    searchTerm: string;
    platforms: ReleasePlatform[];
    dateRange: { startDate: Date; endDate: Date } | null;
  }
};

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(
    @Inject(WINDOW) private window: Window,
    private firestore: Firestore,
    private firestoreService: FirestoreService,
  ) { }

  public urlHost(): Observable<string> {
    return of(this.window.location.host);
  }

  public getLocalTenantDomains(): Observable<LocalTenantDomain[]> {
    const { collectionData, query, collection } = this.firestoreService;
    const toLocalTenantDomain = (dto: LocalTenantDomainDto): LocalTenantDomain => {
      return ({
        localTenantDomainId: dto.id,
        emailDomainId: dto.emailDomainId,
        emailDomain: dto.emailDomain,
        cloudTenantId: dto.cloudTenantId,
        oidcIssuerUrl: dto.oidcIssuerUrl || null,
        oidcClientId: dto.oidcClientId || null,
        oidcProviderId: dto.oidcProviderId || null,
        samlProviderId: dto.samlProviderId || null,
        organisationName: dto.organisationName,
        localTenantId: dto.localTenantId,
        consoleDomain: dto.consoleDomain,
      });
    };
    return collectionData(query(
      collection(this.firestore, 'localTenantDomains'))
    ).pipe(map((dtos: LocalTenantDomainDto[]) => dtos.map(toLocalTenantDomain)));
  }

  public getVersionTimestamp(): Observable<Date> {
    const { docData, doc } = this.firestoreService;
    return docData(doc(this.firestore, 'system/0')).pipe(map(s => s.releasesUpdatedUtc?.toDate() || new Date(0)));
  }

  public getVersions(): Observable<PlatformVersion[]> {
    const { query, collection, collectionData, where, orderBy } = this.firestoreService;
    const toPlatformVersion = (releaseDto: PlatformVersionDto) => <PlatformVersion>{
      versionId: releaseDto.id,
      platform: releaseDto.platform,
      versionName: releaseDto.versionName,
      versionNumber: releaseDto.versionNumber,
      downloadUrl: releaseDto.downloadUrl,
      features: releaseDto.features,
      fixes: releaseDto.fixes,
      enhancements: releaseDto.enhancements,
      addedUtc: releaseDto.addedUtc.toDate(),
    };
    const versionsQuery = query(
      collection(this.firestore, 'releases'),
      where('platform', '==', 'console'),
      orderBy('versionNumber', 'desc'),
    );
    return collectionData(versionsQuery).pipe(map(modules => modules.map(toPlatformVersion)));
  }
}
