import { createReducer, on } from '@ngrx/store';
import { HealthCheckSchedule } from 'src/app/models/schedule';
import { SystemHealthAggregate } from 'src/app/models/system-health-aggregate';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import * as SystemHealthActions from './system-health.actions';

export const systemHealthFeatureKey = 'systemHealth';

export interface State {
  executeCommandRequestState: RequestState;
  executeRepairScriptRequestState: RequestState;
  listCommandsRequestState: RequestState;
  listRepairScriptsRequestState: RequestState;
  listSchedulesRequestState: RequestState;
  systemHealthRequestState: RequestState;
  createScheduleRequestState: RequestState;
  updateScheduleRequestState: RequestState;
  deletingSchedules: string[];
  listCommandsResponse: string[];
  listSchedulesResponse: HealthCheckSchedule[];
  listRepairScriptsResponse: string[];
  systemHealthResponse: SystemHealthAggregate[];
}

export const initialState: State = {
  executeCommandRequestState: initial,
  executeRepairScriptRequestState: initial,
  listCommandsRequestState: initial,
  listRepairScriptsRequestState: initial,
  listSchedulesRequestState: initial,
  systemHealthRequestState: initial,
  createScheduleRequestState: initial,
  updateScheduleRequestState: initial,
  deletingSchedules: [],
  listCommandsResponse: [],
  listRepairScriptsResponse: [],
  listSchedulesResponse: [],
  systemHealthResponse: []
};

export const reducer = createReducer(
  initialState,
  on(SystemHealthActions.getSystemHealthRequest, (state): State => ({
    ...state,
    systemHealthRequestState: pending,
    systemHealthResponse: initialState.systemHealthResponse
  })),
  on(SystemHealthActions.getSystemHealthSuccess, (state, result): State => ({
    ...state,
    systemHealthRequestState: ok,
    systemHealthResponse: result.systemHealth
  })),
  on(SystemHealthActions.getSystemHealthFailure, (state): State => ({
    ...state,
    systemHealthRequestState: error,
    systemHealthResponse: initialState.systemHealthResponse
  })),

  on(SystemHealthActions.listCommandsRequest, (state): State => ({
    ...state,
    listCommandsRequestState: pending,
    listCommandsResponse: initialState.listCommandsResponse
  })),
  on(SystemHealthActions.listCommandsFailure, (state): State => ({
    ...state,
    listCommandsRequestState: error,
    listCommandsResponse: initialState.listCommandsResponse
  })),
  on(SystemHealthActions.listCommandsSuccess, (state, result): State => ({
    ...state,
    listCommandsRequestState: ok,
    listCommandsResponse: result.commands
  })),

  on(SystemHealthActions.listRepairScriptsRequest, (state): State => ({
    ...state,
    listRepairScriptsRequestState: pending,
    listRepairScriptsResponse: initialState.listRepairScriptsResponse
  })),
  on(SystemHealthActions.listRepairScriptsFailure, (state): State => ({
    ...state,
    listRepairScriptsRequestState: error,
    listRepairScriptsResponse: initialState.listRepairScriptsResponse
  })),
  on(SystemHealthActions.listRepairScriptsSuccess, (state, result): State => ({
    ...state,
    listRepairScriptsRequestState: ok,
    listRepairScriptsResponse: result.repairScripts
  })),

  on(SystemHealthActions.executeCommandRequest, (state): State => ({
    ...state,
    executeCommandRequestState: pending,
  })),
  on(SystemHealthActions.executeCommandFailure, (state): State => ({
    ...state,
    executeCommandRequestState: error,
  })),
  on(SystemHealthActions.executeCommandSuccess, (state, result): State => ({
    ...state,
    executeCommandRequestState: ok,
  })),

  on(SystemHealthActions.executeRepairScriptRequest, (state): State => ({
    ...state,
    executeRepairScriptRequestState: pending,
  })),
  on(SystemHealthActions.executeRepairScriptFailure, (state): State => ({
    ...state,
    executeRepairScriptRequestState: error,
  })),
  on(SystemHealthActions.executeRepairScriptSuccess, (state, result): State => ({
    ...state,
    executeRepairScriptRequestState: ok,
  })),

  on(SystemHealthActions.listSchedulesRequest, (state): State => ({
    ...state,
    listSchedulesRequestState: pending,
    listSchedulesResponse: initialState.listSchedulesResponse
  })),
  on(SystemHealthActions.listSchedulesFailure, (state): State => ({
    ...state,
    listSchedulesRequestState: error,
    listSchedulesResponse: initialState.listSchedulesResponse
  })),
  on(SystemHealthActions.listSchedulesSuccess, (state, result): State => ({
    ...state,
    listSchedulesRequestState: ok,
    listSchedulesResponse: result.schedules
  })),

  on(SystemHealthActions.createScheduleRequest, (state): State => ({
    ...state,
    createScheduleRequestState: pending,
  })),
  on(SystemHealthActions.createScheduleFailure, (state): State => ({
    ...state,
    createScheduleRequestState: error,
  })),
  on(SystemHealthActions.createScheduleSuccess, (state, result): State => ({
    ...state,
    createScheduleRequestState: ok,
  })),

  on(SystemHealthActions.updateScheduleRequest, (state): State => ({
    ...state,
    updateScheduleRequestState: pending,
  })),
  on(SystemHealthActions.updateScheduleFailure, (state): State => ({
    ...state,
    updateScheduleRequestState: error,
  })),
  on(SystemHealthActions.updateScheduleSuccess, (state, result): State => ({
    ...state,
    updateScheduleRequestState: ok,
  })),

  on(SystemHealthActions.deleteScheduleRequest, (state, result) => ({
    ...state,
    deletingSchedules: [...state.deletingSchedules, result.scheduleId],
  })),
  on(SystemHealthActions.deleteScheduleFailure, (state, result) => ({
    ...state,
    deletingSchedules: state.deletingSchedules.filter(s => s !== result.scheduleId),
  })),
  on(SystemHealthActions.deleteScheduleSuccess, (state, result) => ({
    ...state,
    deletingSchedules: state.deletingSchedules.filter(s => s !== result.scheduleId),
  })),
);
