import { Injectable } from '@angular/core';
import * as functions from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  constructor() { }

  public get httpsCallable() { return functions.httpsCallable; }
}
