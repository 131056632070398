import * as root from 'src/app/reducers';
import { State  } from './users.reducer';

export * from './users.actions';
export * from './users.effects';
export * from './users.reducer';
export * from './users.selectors';

export interface UsersState extends root.AppState {
  users: State;
}
