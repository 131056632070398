import { Injectable, OnDestroy } from '@angular/core';
import * as auth from '@angular/fire/auth';
import * as fromAccount from 'src/app/modules/console/features/account/account';
import { Auth } from '@angular/fire/auth';
import { Functions } from '@angular/fire/functions';
import { Observable, Subject, distinctUntilChanged, filter, from, map, take, takeUntil } from 'rxjs';
import { FunctionsService } from './functions.service';
import { TenantConfigService } from './tenant-config.service';
import { ActiveDirectoryConfigService } from './active-directory-config.service';
import { Store, select } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(
    private activeDirectoryConfigService: ActiveDirectoryConfigService,
    private tenantConfigService: TenantConfigService,
    private auth: Auth,
    private store: Store,
    private functions: Functions,
    private functionsService: FunctionsService,
  ) { }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public get user() { return auth.user; }
  public get signInWithEmailAndPassword() { return auth.signInWithEmailAndPassword; }
  public get linkWithCredential() { return auth.linkWithCredential; }
  public get signInWithCustomToken() { return auth.signInWithCustomToken; }
  public get idToken() { return auth.idToken; }
  public get fetchSignInMethodsForEmail() { return auth.fetchSignInMethodsForEmail; }
  public get getRedirectResult() { return auth.getRedirectResult; }
  public get getIdTokenResult() { return auth.getIdTokenResult; }

  public async handleRedirectPromise(): Promise<{ accessToken: string } | null> {
    return this.activeDirectoryConfigService.app.handleRedirectPromise();
  }

  public signInWithAccessToken = (accessToken: string) => {
    const { httpsCallable } = this.functionsService;
    const { auth, signInWithCustomToken } = this;
    const signInFunc = httpsCallable<{ accessToken: string; }, string>(this.functions, 'signInWithAadTokenGen2');
    async function signIn() {
      const customToken: string = (await signInFunc({ accessToken })).data;
      const signInResult = await signInWithCustomToken(auth, customToken);
      return !!signInResult.user;
    }
    return from(signIn()).pipe(take(1));
  }

  public oidcSignIn(email: string, path: string): Observable<void> {
    const tenantDomain = this.tenantConfigService.tenantState.localTenantDomain;
    const redirectUri = `https://${tenantDomain.consoleDomain}`;
    const msalApp = this.activeDirectoryConfigService.app;
    async function signInWithRedirect() {
      const rawNonce = crypto.randomUUID();
      sessionStorage.setItem('safe-nonce', rawNonce);
      await msalApp.handleRedirectPromise();
      const encoder = new TextEncoder();
      const data = encoder.encode(rawNonce);
      const hashBuffer = await crypto.subtle.digest('SHA-256', data);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const nonce = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
      return await msalApp.loginRedirect({
        scopes: ['openid','profile', 'email', 'offline_access'],
        redirectStartPage: `${redirectUri}${path || '/console'}`,
        loginHint: email,
        nonce,
      });
    }
    return from(signInWithRedirect());
  }

  public initialise = () => {
    const uid$ = from(this.user(this.auth)).pipe(filter(u => !!u), map(u => u.uid));
    uid$.pipe(take(1)).subscribe(uid => this.store.dispatch(fromAccount.getAccountByUidRequest({ uid })));
    const accountId$ = this.store.pipe(
      select(fromAccount.selectAccountId), distinctUntilChanged(), filter(accountId => !!accountId), takeUntil(this._destroy$)
    );
    accountId$.pipe(takeUntil(this._destroy$)).subscribe(accountId => {
      this.store.dispatch(fromAccount.getOrganisationsRequest({ accountId }));
    });
    this.store.dispatch(fromAccount.chooseCurrentOrganisationRequest());
  }
}
