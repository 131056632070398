import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from './app.actions';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { flagCssValues } from '../../constants/internationalisation';
import moment from 'moment';
import { SystemService } from 'src/app/service/system.service';

@Injectable()
export class AppEffects {

  setLocale$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setLocaleRequest),
      exhaustMap(action => this.translateService.use(action.locale).pipe(
        map(_ => AppActions.setLocaleSuccess({ locale: action.locale })),
        tap(_ => moment.locale(action.locale)),
        catchError(error => of(AppActions.setLocaleFailure({ error })))
      ))
    );
  });

  getLocale$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.getLocaleRequest),
      exhaustMap(action => this.translateService.get(action.supportedLocales.map(x => `header.languages.${x}`)).pipe(
        map(x => action.supportedLocales.map(locale => ({
          localeCode: locale,
          cssValue: flagCssValues[locale],
          language: x[`header.languages.${locale}`],
          isSelected: this.translateService.currentLang === locale
        }))),
        map(array => array.sort((a, b) => a.language.localeCompare(b.language))),
        map(array => AppActions.getLocaleResult({
          locale: this.translateService.currentLang || environment.defaultLocale,
          languages: array
        })),
        catchError(error => of(AppActions.getLocaleFailure({ error })))
      ))
    );
  });

  getLocalTenantDomains$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.getLocalTenantDomainsRequest),
      exhaustMap(_ => this.systemService.getLocalTenantDomains().pipe(
        map(localTenantDomains => AppActions.getLocalTenantDomainsResult({ localTenantDomains })),
        catchError(error => of(AppActions.getLocalTenantDomainsFailure({ error })))
      ))
    );
  });

  getUrlHost$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.getUrlHostRequest),
      exhaustMap(() => this.systemService.urlHost().pipe(
        map(urlHost => AppActions.getUrlHostResult({ urlHost })),
        catchError(error => of(AppActions.getUrlHostFailure({ error })))
      ))
    );
  });

  getVersionTimestamp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.getVersionTimestampRequest),
      exhaustMap(() => this.systemService.getVersionTimestamp().pipe(
        map(timestamp => AppActions.getVersionTimestampResponse({ timestamp })),
        catchError(error => of(AppActions.getVersionTimestampFailure({ error })))
      ))
    );
  });

  getVersions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.getVersionsRequest),
      exhaustMap(() => this.systemService.getVersions().pipe(
        map(versions => AppActions.getVersionsResponse({ versions })),
        catchError(error => of(AppActions.getVersionsFailure({ error })))
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private systemService: SystemService,
  ) {
    translateService.addLangs(environment.locales);
    translateService.setDefaultLang(environment.defaultLocale);
  }
}
