import { createAction, props } from '@ngrx/store';
import { Facility, OrganisationHeader } from 'src/app/models/organisation';
import { Account } from 'src/app/models/accounts';

export enum ActionTypes {
  getAccountFailure = '[Account] Get account failure',
}

export const getAccountByUidRequest = createAction(
  '[Account] Get account by UID request',
  props<{ uid: string }>()
);

export const getAccountByUidSuccess = createAction(
  '[Account] Get account by UID success',
  props<{ account: Account }>()
);

export const getAccountByUidFailure = createAction(
  '[Account] Get account by UID failure',
  props<{ error: any }>()
);

export const getAccountByIdRequest = createAction(
  '[Account] Get account by ID request',
  props<{ accountId: string }>()
);

export const getAccountByIdSuccess = createAction(
  '[Account] Get account by ID success',
  props<{ account: Account }>()
);

export const getAccountByIdFailure = createAction(
  '[Account] Get account by ID failure',
  props<{ error: any }>()
);

export const getOrganisationsRequest = createAction(
  '[Account] Get organisations request',
  props<{ accountId: string }>()
);

export const getOrganisationsSuccess = createAction(
  '[Account] Get organisations success',
  props<{ organisations: OrganisationHeader[] }>()
);

export const getOrganisationsFailure = createAction(
  '[Account] Get organisations failure',
  props<{ error: any }>()
);

export const signOutRequest = createAction(
  '[Account] Sign out request'
);

export const signOutSuccess = createAction(
  '[Account] Sign out success'
);

export const signOutFailure = createAction(
  '[Account] Sign out failure',
  props<{ error: any }>()
);

export const setCurrentOrganisationId = createAction(
  '[Account] Set current organisation id',
  props<{ organisationId: string }>()
);

export const chooseCurrentOrganisationRequest = createAction(
  '[Account] Choose current organisation request'
);

export const getHeadOfficeRequest = createAction(
  '[Account] Get head office request',
  props<{ organisationId: string }>()
);

export const getHeadOfficeSuccess = createAction(
  '[Account] Get head office success',
  props<{ headOffice: Facility }>()
);

export const getHeadOfficeFailure = createAction(
  '[Account] Get head office failure',
  props<{ error: any }>()
);
