export class Module {
  moduleId: string;
  name: string;
  code: string;
  active: boolean;
  startUtc: Date;
  serverUpdatedUtc: Date;
}

export class WebAppDomains {
  portalDomain: string;
  consoleDomain: string;
}

export interface LocaleOption {
  localeCode: string;
  cssValue: string;
  language: string;
  isSelected: boolean;
}

export enum ReleasePlatform {
  console = 'console',
  portal = 'portal',
  ios = 'ios',
  android = 'android',
}

export class AddPlatformVersions {
  platforms: ReleasePlatform[];
  downloadUrl: string | null;
  versionName: string;
  versionNumber: number;
  features: string[];
  fixes: string[];
  enhancements: string[];
}

export class PlatformVersion {
  versionId: string;
  platform: ReleasePlatform;
  downloadUrl: string | null;
  versionName: string;
  versionNumber: number;
  features: string[];
  fixes: string[];
  enhancements: string[];
  addedUtc: Date;
}

export class ReleaseValidation {
  versionNameTaken?: ReleasePlatform;
  versionNumberTaken?: ReleasePlatform;
  minVersionName?: { platform: ReleasePlatform; currentVersionName: string; };
  minVersionNumber?: { platform: ReleasePlatform; currentVersionNumber: number; };
}

export class AppRegistration {
  appRegistrationId: string;
  displayName: string;
  oidcClientId: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string;
}

export class CreateAppRegistration {
  displayName: string;
  oidcClientId: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string;
}

export class UpdateAppRegistration {
  appRegistrationId: string;
  displayName: string;
  oidcClientId: string;
  androidRedirectUri: string;
  iosRedirectUri: string;
  credentialId: string;
}

export type CurrentReleaseNumbers = { [platform in ReleasePlatform] : number };

export const minReleaseVersion = 100;

export function compareVersions(x: string, y: string) {
  const [xMajorText, xMinorText, xBuildText] = x.split('.');
  const [yMajorText, yMinorText, yBuildText] = y.split('.');
  const xMajor = Number(xMajorText);
  const xMinor = Number(xMinorText);
  const xBuild = Number(xBuildText);
  const yMajor = Number(yMajorText);
  const yMinor = Number(yMinorText);
  const yBuild = Number(yBuildText);
  return Math.sign(xMajor - yMajor) || Math.sign(xMinor - yMinor) || Math.sign(xBuild - yBuild);
}
