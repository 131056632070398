import { Action, createReducer, on } from '@ngrx/store';
import * as ProfileActions from './profile.actions';
import { RequestState, initial, pending, ok, error } from 'src/app/reducers';
import { OrganisationUser, UserRoleUpdateResult } from 'src/app/models/accounts';
import { Device } from '../../models/devices';

export const profileFeatureKey = 'profile';

export interface State {
  setUserRoleState: RequestState;
  getOrganisationUserState: RequestState;
  sendPasswordResetEmailState: RequestState;
  saveProfileState: RequestState;
  getDevicesState: RequestState;
  removingUserDevices: string[];
  removedUserDevice: string;
  failedToRemoveUserDevice: string;
  userDevices: Device[];
  roleUpdateResult: UserRoleUpdateResult;
  profile: OrganisationUser;
}

export const initialState: State = {
  setUserRoleState: initial,
  getOrganisationUserState: initial,
  sendPasswordResetEmailState: initial,
  saveProfileState: initial,
  getDevicesState: initial,
  removingUserDevices: [],
  removedUserDevice: null,
  failedToRemoveUserDevice: null,
  userDevices: [],
  roleUpdateResult: null,
  profile: null
};

const profileReducer = createReducer(
  initialState,

  on(ProfileActions.getOrganisationUserRequest, (state, action) => ({
    ...state,
    getOrganisationUserState: pending,
    profile: null
  })),
  on(ProfileActions.getOrganisationUserResponse, (state, action) => ({
    ...state,
    getOrganisationUserState: ok,
    profile: action.organisationUser
  })),
  on(ProfileActions.getOrganisationUserFailure, (state, action) =>  ({
    ...state,
    getOrganisationUserState: error
  })),
  on(ProfileActions.saveProfileRequest, (state, action) => ({
    ...state,
    saveProfileState: pending,
    profile: { ...state.profile, phoneNumber: null }
  })),
  on(ProfileActions.saveProfileSuccess, (state, action) => ({
    ...state,
    saveProfileState: ok,
    profile: action.organisationUser
  })),
  on(ProfileActions.saveProfileFailure, (state, action) =>  ({
    ...state,
    saveProfileState: error
  })),
  on(ProfileActions.setUserRoleRequest, (state, action) =>  ({
    ...state,
    setUserRoleState: pending,
    roleUpdateResult: null
  })),
  on(ProfileActions.setUserRoleSuccess, (state, action) =>  ({
    ...state,
    setUserRoleState: ok,
    roleUpdateResult: action.result
  })),
  on(ProfileActions.setUserRoleFailure, (state, action) =>  ({
    ...state,
    setUserRoleState: error
  })),
  on(ProfileActions.sendPasswordResetEmailRequest, (state, action) => ({
    ...state,
    roleUpdateResult: null,
    sendPasswordResetEmailState: pending
  })),
  on(ProfileActions.sendPasswordResetEmailSuccess, (state, action) => ({
    ...state,
    sendPasswordResetEmailState: ok
  })),
  on(ProfileActions.sendPasswordResetEmailFailure, (state, action) => ({
    ...state,
    sendPasswordResetEmailState: error
  })),
  on(ProfileActions.getUserDevicesRequest, (state) => ({
    ...state,
    userDevices: [],
    getDevicesState: pending,
  })),
  on(ProfileActions.getUserDevicesFailure, (state) => ({
    ...state,
    getDevicesState: error,
  })),
  on(ProfileActions.getUserDevicesSuccess, (state, action) => ({
    ...state,
    userDevices: action.devices,
    getDevicesState: ok,
  })),
  on(ProfileActions.removeUserDeviceRequest,
    (state, action) => ({
      ...state,
      removingUserDevices: [...state.removingUserDevices.filter(x => x !== action.deviceId), action.deviceId],
    })
  ),
  on(ProfileActions.removeUserDeviceSuccess,
    (state, action) => ({
      ...state,
      runCheckInScheduleState: ok,
      removingUserDevices: [...state.removingUserDevices.filter(x => x !== action.deviceId)],
      removedUserDevice: action.deviceName,
    })
  ),
  on(ProfileActions.removeUserDeviceFailure,
    (state, action) => ({
      ...state, runCheckInScheduleState: error,
      removingUserDevices: [...state.removingUserDevices.filter(x => x !== action.deviceId)],
      failedToRemoveUserDevice: action.deviceName,
    })
  ),
  on(ProfileActions.removeUserDeviceReset,
    (state) => ({
      ...state,
      removedUserDevice: null,
      failedToRemoveUserDevice: null,
    })
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return profileReducer(state, action);
}
