
export const passwordLength = {
  min: 8,
  max: 64
};

export const patterns = {
  email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ipAddress: /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  domain: /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
  kebabCase: /^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/,
  name: /^[^\s]+(\s+[^\s]+)*$/,
  imageType: /^(^image)(\/)[a-zA-Z0-9_]*$/,
  uuid: /^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}$/,
  uri: /^([a-zA-Z][a-zA-Z0-9+.-]*):\/\/([\w_-]+(?:(?:\.[\w_-]+)?))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/,
  https: /^https:\/\/[^\s/$.?#].[^\s]*$/,
  oidcProviderId: /^[a-z]((?!.*--)[-a-z0-9]*)[a-z0-9]$/,
  version: /^(\d+\.)(\d+\.)(\d+)$/,
};

export function exactMatch(pattern: RegExp, value: string) {
  const match = value.match(pattern);
  return match && match.length > 0 && match[0] === value;
}
