import * as root from 'src/app/reducers';
import { State  } from './profile.reducer';

export * from './profile.actions';
export * from './profile.effects';
export * from './profile.reducer';
export * from './profile.selectors';

export interface ProfileState extends root.AppState {
  profile: State;
}
