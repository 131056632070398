import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap, mergeMap, take } from 'rxjs/operators';
import {of, from } from 'rxjs';
import * as ConnectActions from './connect.actions';
import { Router } from '@angular/router';
import { ConnectService } from 'src/app/service/connect.service';
import { AuthService } from 'src/app/shared/custom/service/auth.service';

@Injectable()
export class ConnectEffects {

  setUpAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.setUpAccountRequest),
      exhaustMap(x => this.connectService.setUpAccount(x.email, x.cloudTenantId).pipe(
        map(_ => ConnectActions.setUpAccountSuccess()),
        catchError(error => of(ConnectActions.setUpAccountFailure({ error })))
      ))
    );
  });

  verifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.verifyEmailRequest),
      exhaustMap(x => this.connectService.verifyEmail(x.email, x.localTenantDomains, x.localDomain).pipe(
        map(result => ConnectActions.verifyEmailSuccess({
          email: x.email,
          accountCreationRequired: result.accountCreationRequired,
          passwordCreationRequired: result.passwordCreationRequired, 
          redirectToDomain: result.redirectToDomain,
        })),
        catchError(error => of(ConnectActions.verifyEmailFailure({ error })).pipe(take(1)))
      ))
    );
  });

  passwordSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.firebaseSignInRequest),
      exhaustMap(x => this.connectService.passwordSignIn(x.email, x.password).pipe(
        map(_ => ConnectActions.firebaseSignInSuccess({ path: x.path })),
        catchError(error => of(ConnectActions.firebaseSignInFailure({ error })))
      ))
    );
  });

  oidcSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.oidcSignInRequest),
      exhaustMap(x => this.authService.oidcSignIn(x.email, x.path).pipe(
        map(() => ConnectActions.oidcSignInSuccess()),
        catchError(error => of(ConnectActions.oidcSignInFailure({ error })))
      ))
    );
  });

  accessTokenSignIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.accessTokenSignInRequest),
      exhaustMap(x => this.authService.signInWithAccessToken(x.accessToken).pipe(
        map(signedIn => ConnectActions.accessTokenSignInResponse({ signedIn })),
        catchError(error => of(ConnectActions.accessTokenSignInFailure({ error })))
      ))
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.resetPasswordRequest),
      exhaustMap(x => this.connectService.resetPassword(x.email, x.cloudTenantId).pipe(
        map(_ => ConnectActions.resetPasswordSuccess()),
        catchError(error => of(ConnectActions.resetPasswordFailure({ error })))
      ))
    );
  });

  signedIn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.firebaseSignInSuccess),
      mergeMap(_ => from(this.router.navigate(['/console']))
    ));
  }, { dispatch: false });

  accessTokenSignInComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.accessTokenSignInResponse, ConnectActions.accessTokenSignInFailure),
      map(() => ConnectActions.accessTokenSignInReset()),
    );
  });

  setUpAccountComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.setUpAccountSuccess, ConnectActions.setUpAccountFailure),
      map(() => ConnectActions.setUpAccountReset()),
    );
  });

  resetPasswordComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.resetPasswordSuccess, ConnectActions.resetPasswordFailure),
      map(() => ConnectActions.resetPasswordReset()),
    );
  });

  firebaseSignInComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.firebaseSignInSuccess, ConnectActions.firebaseSignInFailure),
      map(() => ConnectActions.firebaseSignInReset()),
    );
  });

  verifyEmailComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ConnectActions.verifyEmailSuccess, ConnectActions.verifyEmailFailure),
      map(() => ConnectActions.verifyEmailReset()),
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private connectService: ConnectService,
  ) { }
}
